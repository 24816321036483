import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5, tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add2 from "../../Assets/img/feature6.png";
import add3 from "../../Assets/img/feature2.png";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import {
	useDeleteVehicleMutation,
	useMyAdsQuery,
} from "../../store/services/AdsService";
import {
	useFeturedPaymentSubcriptionQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import swal from "sweetalert";
import { ToastMessage } from "../../utils/ShowToastMessage";
import Spinner from "./../../utils/Loader";
import { useForm } from "../../hooks/Form";

const MyAdd = () => {
	const [setValue, SetValueChange] = useState();
	const [setVehicle, SetVehicleId] = useState();
	const [setBannerId, SetBannerId] = useState();
	const [banner_ad_setting_id, Setbanner_ad_setting_id] = useState();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const { data: PaymentUrl, isFetching: PaymentLoad } =
		useFeturedPaymentSubcriptionQuery();
	const { state, onChange } = useForm({
		cars: "",
	});
	let { data, isLoading } = useMyAdsQuery(state?.cars);
	let [deletePro, response] = useDeleteVehicleMutation();
	let MyAllAdd = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	let feturedPayment = PaymentUrl?.response?.data;
	const navigate = useNavigate();
	const OnChangerhandler = (e) => {
		SetValueChange(e.target.value);
	};


	const HandleId = (id) => {
		SetVehicleId(id);
	};
	const DeleteProduct = (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this Ads!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				swal("Poof! Your Ads has been deleted!", {
					icon: "success",
				});
				deletePro(id);
			} else {
				swal("Your Product is safe!");
			}
		});
	};

	const HandleFetured = () => {
		if (banner_ad_setting_id !== undefined) {
			navigate("/feturedpaymentpage", {
				state: {
					vehicle_id: setVehicle,
					banner_ad_setting_id: banner_ad_setting_id,
				},
			});
		} else {
			ToastMessage("Please Select Fetured Day Pakage");
		}
	};

	const EditProduct = (id) => {
		navigate(`/PostVehicleAds/${id}`);
	};

	return (
		<>

			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Header />
					<section className="profile_Sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<Sidebar />
								</div>
								<div className="col-lg-9">
									<div className="right-side">
										<div className="title">
											<h3>My Ads</h3>
											<div className="snap-chat">
												{/* <div className="dropdown">
											<button
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												Dropdown button
											</button>
											<div
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton"
											>
												<a className="dropdown-item" href="#">
													All
												</a>
												<a className="dropdown-item" href="#">
													Featured
												</a>
												<a className="dropdown-item" href="#">
													Go Featured
												</a>
											</div>
										</div> */}
												<div className="select-category">
													<select name="cars" id="cars" onChange={onChange}>
														<option value=""> All</option>
														<option value="0">Go Featured</option>
														<option value="1">Featured</option>
													</select>
												</div>
												<button onClick={() => navigate("/PostSnap")}>
													Post Snapshots
												</button>
											</div>
										</div>
										<div className="profile-detail"></div>
									</div>
									<div className="row mt-4">
										<div className="col-lg-4 mb-3">
											<button
												className="postAdd"
												type="button"
												onClick={() => navigate("/PostVehicleAds")}
											>
												<div className="post-add">
													<div className="inner-text">
														<h4>
															<i className="fa fa-plus" aria-hidden="true"></i> Post
															ad
														</h4>
														<p>Lorem Ipsum Dolor Sit Amet</p>
													</div>
												</div>
											</button>
										</div>
										{MyAllAdd?.map((item, index) => {
											return (
												<>
													<div className="col-lg-4 mb-3" key={item?.id}>
														<div className="featured-all">
															<div className="f-img">
																<img src={`${ImageUrls}${item?.image}`}></img>
															</div>

															<div className="feature-head">
																<h2>
																	{
																		currency
																			.format(item?.price, {
																				code: "USD",
																			})
																			.split(".00")[0]
																	}
																</h2>

																{item?.is_featured === 1 ? (
																	<div className="tag">
																		<button
																			type="button"
																			className="btn btn-primary"
																		>
																			<span>Featured</span>
																		</button>

																		<img src={tabsImg} className="img-fluid" />
																	</div>
																) : (
																	<div className="tag">
																		<button
																			type="button"
																			className="btn btn-primary"
																			data-bs-toggle="modal"
																			data-bs-target="#postAdd"
																			onClick={() => HandleId(item?.id)}
																		>
																			<span>Go Featured</span>
																		</button>

																		<img src={tabsImg} className="img-fluid" />
																	</div>
																)}
															</div>

															<div className="feature-normal-head">
																<h3>{item?.meta_title}</h3>
															</div>

															<div className="dropdownon">
																<div className="dropdown">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton"
																		data-toggle="dropdown"
																		aria-haspopup="true"
																		aria-expanded="false"
																	>
																		<i
																			className="fa fa-ellipsis-v"
																			aria-hidden="true"
																		></i>
																	</button>
																	<div
																		className="dropdown-menu"
																		aria-labelledby="dropdownMenuButton"
																	>
																		<button
																			className="dropdown-item"
																			onClick={() => EditProduct(item?.id)}
																		>
																			Edit
																		</button>
																		<button
																			className="dropdown-item"
																			onClick={() => DeleteProduct(item?.id)}
																		>
																			Delete
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="post-add-modal">
						<div
							className="modal fade"
							id="postAdd"
							tabindex="-1"
							aria-labelledby="exampleModalLabel"
							aria-hidden="true"
						>
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLabel">
											<img src={tabsImg} className="img-fluid" alt="" />
											<span>Go Feature Sale Faster</span>
											<img src={tabsImg} className="img-fluid" alt="" />
										</h5>
									</div>
									<div className="modal-body">
										<ul>
											{feturedPayment?.length > 0 &&
												feturedPayment?.map((item, index) => {
													return (
														<>
															<li key={index}>
																<span>
																	<label
																		className="price_container"
																		onClick={() =>
																			Setbanner_ad_setting_id(item?.id)
																		}
																	>
																		<input
																			type="radio"
																			hidden
																			name="setValue"
																			value={`${item?.id}`}
																			onChange={OnChangerhandler}
																		/>
																		<span className="checkmark">
																			<span className="dot"></span>
																			<ruby>Go Feature {item?.days} Days</ruby>
																		</span>
																	</label>
																</span>
																<span className="price">
																	{item?.price &&
																		currency
																			.format(item?.price, {
																				code: "USD",
																			})
																			.split(".")[0]}
																</span>
															</li>
														</>
													);
												})}
										</ul>
										<div className="button-group">
											<button
												className="btn"
												onClick={HandleFetured}
												data-dismiss="modal"
												data-bs-dismiss="modal"
											>
												Go Featured
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</>
			)}

		</>
	);
};

export default MyAdd;
