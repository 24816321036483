import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "../../hooks/Form";
import { useDispatch, useSelector } from "react-redux";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { setSuccess, clearMessage } from "../../store/reducers/globalReducers";
import { useAuthLoginMutation } from "../../store/services/AuthService";
import { setUserToken } from "../../store/reducers/authReducers";
// import { GoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";

let CLIENT_ID =
	"465146905825-auqsd5sbgqlseqoq90acja0j97k7mchd.apps.googleusercontent.com";
const Login = () => {
	const location = useLocation();
	let getLocation = location?.state?.siteLocation;
	// const onLogoutSuccess = () => {
	//     console.log('Logout successful');
	// };

	const [state, setState] = useState({
		email: "",
		password: "",
	});

	const { email, password } = state;
	const onFailGoogle = (response) => {
		// console.log(response, "unsucessfull google response");
	};

	const onSuccessGoogle = (response) => {
		// let data = {
		// 	email: response.profileObj.email,
		// 	password: 0,
		// 	// facebook_id: 0,
		// 	name: response.profileObj.name,
		// 	google_id: response.profileObj.googleId,
		// };
		// console.log(response.profileObj, "successfull google response");
	};

	const HandleChanger = (ip) => {
		window.open(ip, "_blank");
	};
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		getcookiedata();
	}, []);

	const getcookiedata = () => {
		let Eml = getCookies("email");
		let PaS = getCookies("password");
		if (Eml && PaS) {
			setState({
				email: Eml,
				password: PaS,
			});
		}
	};

	const getCookies = (cemail) => {
		let email = cemail + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(email) === 0) {
				return c.substring(email.length, c.length);
			}
		}
		return "";
	};

	const HandleRemember = () => {
		let emi = email;
		let pass = password;
		document.cookie = `email=${emi};path=http://localhost:3000/login`;
		document.cookie = `password=${pass};path=http://localhost:3000/login`;
	};

	const Changer = (e) => {
		setState((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	}

	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);
	const [userLoginData, response] = useAuthLoginMutation();
	const onSubmit = (e) => {
		e.preventDefault();
		if (!email || !password) {
			ToastMessage("Please Enter All Field");
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email
			)
		) {
			ToastMessage("Invalid Email");
			return;
		}
		const FormDatas = new FormData();
		FormDatas.append("email", email);
		FormDatas.append("password", password);
		userLoginData(FormDatas);
	};
	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (
			response.isError &&
			response?.error?.data?.message === "Your Email or Password Is Incorrect"
		) {
			ToastMessage(response?.error?.data?.message);
		} else {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			localStorage.setItem(
				"userData",
				JSON.stringify(response?.data?.response?.data),
			);
			dispatch(setUserToken(response?.data?.response?.data));
			dispatch(setSuccess(response?.data?.message));
			state.email = "";
			state.password = "";
			if (getLocation === "/profile-message") {
				navigate("/profile-message");
			} else {
				navigate("/");
			}
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Login</h2>
									<p>
										Does'nt have an account ?{" "}
										<Link to="/register">Sign Up</Link>
									</p>
								</div>
								<form onSubmit={onSubmit}>
									<div className="form-group">
										<input
											type="email"
											placeholder="Email Address"
											className={`${errors && errors?.email
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="email"
											value={email}
											onChange={Changer}
										/>
									</div>
									{errors && errors?.email && (
										<span className="errorMessage">
											<p>{errors && errors?.email}</p>
										</span>
									)}
									<div className="form-group">
										<input
											type="password"
											placeholder="Password"
											className={`${errors && errors?.password
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="password"
											value={password}
											onChange={Changer}
										/>
									</div>
									{errors && errors?.password && (
										<span className="errorMessage">
											<p>{errors && errors?.password}</p>
										</span>
									)}
									<div className="rememberDv">
										<div className="form-group">
											<input
												className="form-check-input"
												id="checkk"
												type="checkbox"
												onClick={HandleRemember}
											/>
											<label htmlFor="checkk">Remember me</label>
										</div>
										<div className="forgot">
											<Link to="/enter-email">Forget Password?</Link>
										</div>
									</div>
									<div className="button-group mt-3">
										<button type="submit">
											{" "}
											{response?.isLoading ? "Loading..." : " Login"}
										</button>
									</div>
								</form>
								<div className="login_socials_dv">
									{/* <Link to="#">
												<i className="fa fa-facebook" aria-hidden="true"></i>
											</Link> */}
									<div className="row">
										<div className="col-12 mt-3">

											<LoginSocialFacebook
												appId="1139903056659364"
												onResolve={(response) => {
													// console.log(response);
												}}
												onReject={(error) => {
													// console.log(error);
												}}
											>
												<FacebookLoginButton />
											</LoginSocialFacebook>
										</div>
										{/* <GoogleLogin
												clientId={CLIENT_ID}
												onSuccess={onSuccessGoogle}
												onFailure={onFailGoogle}
											></GoogleLogin> */}
										{/* <GoogleLogin
												onSuccess={onSuccessGoogle}
												onError={onFailGoogle}
											/> */}
										<div className="col-12 mt-3">

											<GoogleLogin
												className="google_button_custom"
												clientId={CLIENT_ID}
												buttonText="Sign in with Google"
												onSuccess={onSuccessGoogle}
												onFailure={onFailGoogle}
												cookiePolicy={"single_host_origin"}
												isSignedIn={true}
											/>
										</div>
										<div className="col-sm-12 mt-3">
											<div className="apple-login-btn">
												<AppleLogin
													clientId="razalathiya@gmail.com"
													redirectURI="http://localhost:3000/"
												/>
											</div>
										</div>
										{/* <Link to="#">
												<i className="fa fa-apple" aria-hidden="true"></i>
											</Link> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default Login;
