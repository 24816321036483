import { createSlice } from '@reduxjs/toolkit';
const customerToken = JSON.parse(localStorage.getItem('userData'));

const AuthReducer = createSlice({
    name: 'authReducer',
    initialState: {
        userToken: customerToken ? customerToken?.token : null,
        user: customerToken ? customerToken?.user : null,
    },
    reducers: {
        setUserToken: (state, action) => {
            state.userToken = action?.payload?.token;
            state.user = action?.payload?.user;
        },
        logout: (state) => {
            localStorage.removeItem('userData');
            state.user = null;
            state.userToken = null;
        },
    },
});
export const { setUserToken, logout } = AuthReducer.actions;
export default AuthReducer.reducer;
