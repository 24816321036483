import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { MdOutlineCancel } from "react-icons/md"
import "../../Assets/Css/bannerads.css";
import "../../Assets/Css/orderDetails.css";
import Sidebar from "./Sidebar";
import feature2 from "../../Assets/img/feature2.png";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import Spinner from "../../utils/Loader";
import { useGetBannersQuery } from "../../store/services/BannerAdsService";
import { useGetOrderDetailsQuery, useGetOrderQuery } from "../../store/services/CheckoutService";
import currency from "currency-formatter";
import { format } from 'date-fns'


const MyOrders = () => {
    const [detailId, setDetailId] = useState()
    const { data: ImageUrl } = useSiteSettingConfigQuery();
    const { data, isLoading } = useGetOrderQuery();
    const { data: myOrderDetails, isLoading: load } = useGetOrderDetailsQuery(detailId);
    // const { data: myOrder, isLoading } = useGetBannersQuery();
    let My_Orders = data?.response?.data;
    let My_Orders_Details = myOrderDetails?.response?.data;
    let ImageUrls = ImageUrl?.uploads;

    console.log(myOrderDetails, "ppp")





    const [value, setValue] = useState("Lorem Ipsum");
    const [showModal, setShowModal] = useState("");
    // const [copied, setCopied] = useState(false);
    // const onChange = useCallback(({ target: { value } }) => {
    //     setValue(value);
    //     setCopied(true);
    // }, []);
    // const onClick = useCallback(({ target: { innerText } }) => {
    //     // console.log(`Clicked on "${innerText}"!`);
    // }, []);
    // const onCopy = useCallback(() => {
    //     setCopied(true);
    // }, []);
    // function MyButton(props) {
    //     return <button {...props} />;
    // }
    // const navigate = useNavigate();

    const HandleOpenModal = (id) => {
        setDetailId(id)
        setShowModal("fade show d-block");
    }

    const HandleCancelModal = () => {
        setShowModal("fade");
    }


    return (
        <>
            {!isLoading ? (
                <>
                    <Header />

                    <section className="profile_Sec bannerads_sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3">
                                    <Sidebar />
                                </div>
                                <div className="col-lg-9">
                                    <div className="right-side">
                                        <div className="title">
                                            <h3>My Orders</h3>
                                            <div className="snap-chat">
                                                <span>
                                                    (
                                                    {My_Orders?.length > 10
                                                        ? My_Orders?.length
                                                        : `0${My_Orders?.length}`}
                                                    )
                                                </span>
                                            </div>
                                        </div>
                                        <div className="profile-detail"></div>

                                        {
                                            My_Orders?.length >= 1 ? (
                                                <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-12 p-0">
                                                                <div className="table-wrapper">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Order ID</th>
                                                                                <th scope="col">Price </th>
                                                                                <th scope="col">Date</th>
                                                                                <th scope="col">Order Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {
                                                                                My_Orders?.map((item, index) => (
                                                                                    <>
                                                                                        <tr className="SetAll_btn">

                                                                                            <td><img src="./img/tablecookies.webp" className="img-fluid"
                                                                                                alt="" /> <span> <span> ID #{item?.id}</span></span>
                                                                                            </td>
                                                                                            <td>{item?.order_amount &&
                                                                                                currency
                                                                                                    .format(item?.order_amount, {
                                                                                                        code: "USD",
                                                                                                    })
                                                                                                    .split(".")[0]}</td>
                                                                                            {/* <td>{new Date(item?.created_at)?.toISOString()?.split("T")[0]}</td> */}

                                                                                            <td>{format(new Date(item?.created_at), 'dd/MM/yyyy')}</td>
                                                                                            <td>{item?.order_status}
                                                                                            </td>
                                                                                            <td><button className="btn2" onClick={() => HandleOpenModal(item?.id)}>View
                                                                                                Detail</button></td>


                                                                                        </tr>
                                                                                    </>
                                                                                ))
                                                                            }


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : <h4 className="Head-order">No Order Found</h4>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <div className="col-md-12">
                        <div
                            className={`modal fade ${showModal}`}
                            id="exampleModalToggle"
                            aria-hidden="true"
                            aria-labelledby="exampleModalToggleLabel"
                            tabindex="-1"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <section className="order-detail-sec">
                                            <button className="order-detail-cancel" onClick={HandleCancelModal}>
                                                <MdOutlineCancel />
                                            </button>
                                            <div className="detail-order">
                                                <h4>ID #{(My_Orders_Details?.id)}</h4>
                                                <div className="order-detail-wrapper">
                                                    <div className="Card_height">
                                                        {
                                                            My_Orders_Details?.order_details?.map((item, index) => {
                                                                return (

                                                                    <>
                                                                        <div className="row mt-4">
                                                                            <div className="col-md-8 col-8">
                                                                                <div className="cokkies-order">
                                                                                    <img src={`${ImageUrls}${item?.shareable?.image}`} alt="" className="img-fluid" />
                                                                                    <div className="quantity-order">
                                                                                        <h6>{item?.shareable?.meta_title}</h6>
                                                                                        <h5> Quantity: {item?.qty} </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-4">
                                                                                <div className="cokkies-order1">
                                                                                    <div className="quantity-order">
                                                                                        <h6>{
                                                                                            currency
                                                                                                .format(item?.price, {
                                                                                                    code: "USD",
                                                                                                })
                                                                                                .split(".")[0]}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                )
                                                            })

                                                        }


                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="orderdetail">
                                                                <h5>Order Detail</h5>
                                                            </div>
                                                            <div className="order-detail-complete">
                                                                <div className="details-child">
                                                                    <ul>
                                                                        {/* <li>Date : <span>{new Date(My_Orders_Details?.created_at)?.toISOString()?.split("T")[0]}
                                                                        </span>
                                                                        </li> */}
                                                                        <li>Subtotal : <span>{
                                                                            currency
                                                                                .format(My_Orders_Details?.order_amount, {
                                                                                    code: "USD",
                                                                                })
                                                                                .split(".")[0]}
                                                                        </span>
                                                                        </li>
                                                                        {/* <li>Shipping Fee : <span>$5.00
                                                                                    </span>
                                                                                    </li> */}

                                                                        <li>Order Status <span>{My_Orders_Details?.order_status}</span> </li>


                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>


                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            ) : (
                <Spinner />
            )
            }
        </>
    );
};




export default MyOrders;
