import { configureStore } from "@reduxjs/toolkit";
import AllSiteApisService from "./services/AllSiteApisService";
import BlogService from "./services/BlogService";
import ProductsService from "./services/ProductsService";
import AllVehiclesService from "./services/AllVehiclesService";
import AuthService from "./services/AuthService";
import globalReducer from "./reducers/globalReducers";
import AuthReducer from "./reducers/authReducers";
import HomeService from "./services/Pages/HomeService";
import AboutService from "./services/Pages/AboutService";
import TermsCondition from "./services/Pages/TermsCondition";
import ProfileService from "./services/Pages/ProfileService";
import MessagesService from "./services/Pages/MessagesService";
import CompaniesService from "./services/CompaniesService";
import NewsService from "./services/NewsService";
import AdsService from "./services/AdsService";
import VechiclesCategoryService from "./services/VechiclesCategoryService";
import BannerAdsService from "./services/BannerAdsService";
import cartReducers from "./reducers/cartReducers";
import AddressService from "./services/AddressService";
import CheckoutService from "./services/CheckoutService";

const Store = configureStore({
	reducer: {
		[AllSiteApisService.reducerPath]: AllSiteApisService.reducer,
		[BlogService.reducerPath]: BlogService.reducer,
		[ProductsService.reducerPath]: ProductsService.reducer,
		[AllVehiclesService.reducerPath]: AllVehiclesService.reducer,
		[AuthService.reducerPath]: AuthService.reducer,
		[HomeService.reducerPath]: HomeService.reducer,
		[AboutService.reducerPath]: AboutService.reducer,
		[TermsCondition.reducerPath]: TermsCondition.reducer,
		[ProfileService.reducerPath]: ProfileService.reducer,
		[MessagesService.reducerPath]: MessagesService.reducer,
		[CompaniesService.reducerPath]: CompaniesService.reducer,
		[AdsService.reducerPath]: AdsService.reducer,
		[NewsService.reducerPath]: NewsService.reducer,
		[CheckoutService.reducerPath]: CheckoutService.reducer,
		[BannerAdsService.reducerPath]: BannerAdsService.reducer,
		[VechiclesCategoryService.reducerPath]: VechiclesCategoryService.reducer,
		[AddressService.reducerPath]: AddressService.reducer,
		globalReducer: globalReducer,
		AuthReducer: AuthReducer,
		cartReducers: cartReducers,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			AllSiteApisService.middleware,
			BlogService.middleware,
			CheckoutService.middleware,
			ProductsService.middleware,
			AllVehiclesService.middleware,
			AuthService.middleware,
			HomeService.middleware,
			AboutService.middleware,
			TermsCondition.middleware,
			ProfileService.middleware,
			MessagesService.middleware,
			CompaniesService.middleware,
			NewsService.middleware,
			AdsService.middleware,
			VechiclesCategoryService.middleware,
			BannerAdsService.middleware,
			AddressService.middleware,
		]),
});
export default Store;
