import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { checkdouble } from '../../constant';
import { Link } from 'react-router-dom';
import { useBannersStoreMutation } from '../../store/services/BannerAdsService';
import { useProfileApiQuery } from '../../store/services/Pages/ProfileService';
import { useCheckoutMutation } from '../../store/services/CheckoutService';
import { useSelector } from 'react-redux';
import { ToastMessage } from './../../utils/ShowToastMessage';

const stripePromise = loadStripe(
    'pk_test_51Ln1dCFJJmnlZOnNXtsqSkIDa03oWjE5YMHEyBRHrFs3rlCgPBLH2vNZ9aHqsNFazd9fN2rPVebmWXcbDrZEFNFg00cgM98wJu'
);

const CheckoutForm = () => {
    let [paymentCheckout, response] = useCheckoutMutation();
    let { data, isFetching } = useProfileApiQuery();
    let LogingUserData = data?.response?.data;
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [paybutton, setPayButton] = useState(true);
    const { cart, total } = useSelector((state) => state.cartReducers);
    const [subTotal, setSubTotal] = useState(0);

    useEffect(() => {
        if (cart.length <= 0) {
            navigate('/');
        } else {
            TotalAmount();
        }
    }, [cart]);

    const TotalAmount = () => {
        let i;
        let total = 0;
        for (i = 0; i < cart?.length; i++) {
            total = total + cart[i]?.price * cart[i]?.quantity;
        }
        setSubTotal(total);
    };

    let Demand = cart?.map((item) => {
        return {
            id: item?.id,
            qty: item?.quantity,
            price: item?.price,
        };
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (elements == null) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const payload = await stripe.createToken(cardElement);
        if (payload?.token?.id) {
            setPayButton(false);
            const formData = new FormData();
            formData.append('total', subTotal);
            formData.append('products', JSON.stringify(Demand));
            formData.append('stripe_token', payload.token.id);
            paymentCheckout(formData);
            return;
        } else {
            ToastMessage('Something Went Wrong');
            return;
        }
    };

    useEffect(() => {
        if (response?.data?.message === 'Order placed successfully!') {
            ToastMessage(response?.data?.message);
            navigate('/thankyou', {
                state: { Paydata: response?.data?.response?.data },
            });
        } else {
            ToastMessage(response?.data?.message);
        }
    }, [response?.data?.message]);

    return (
        <>
            <form>
                <CardElement
                    onChange={(e) => {
                        if (e.complete) {
                            setPayButton(false);
                        } else {
                            setPayButton(true);
                        }
                    }}
                />
                <br />

                <ul className="list-inline button-group">
                    <li className="button-group">
                        <button
                            type="submit"
                            className="btn "
                            disabled={!stripe || !elements || paybutton}
                            // data-bs-toggle="modal"
                            // href="#exampleModalToggle"
                            onClick={handleSubmit}>
                            Pay Now
                        </button>
                    </li>
                </ul>
            </form>
        </>
    );
};

const CheckoutPayment = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

<div className="col-md-12">
    <div className="button-group">
        <div
            className="modal fade"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5
                            className="modal-title"
                            id="exampleModalToggleLabel"></h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <img src={checkdouble} className="img-fluid"></img>
                        <h4>Your Subscription Payment Was Successful Paid</h4>
                        <p>
                            your subscription payment has been successful done
                        </p>
                        <Link to="/" className="btn">
                            Go To Home Page
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>;
export default CheckoutPayment;
