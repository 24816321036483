import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../src/Assets/img/logo.png";
import facebook from "../Assets/img/face.png";
import twitter from "../Assets/img/twitter.png";
import insta from "../Assets/img/insta.png";
import link from "../Assets/img/link.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import chat from "../Assets/img/chat.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import phone2 from "../Assets/img/phone2.png";
import message from "../Assets/img/message.png";
import map2 from "../Assets/img/map2.png";
import ChatBox, { ChatFrame } from "react-chat-plugin";
import { useSiteSettingApiQuery } from "../store/services/AllSiteApisService";

const Footer = () => {
	let { data, isLoading } = useSiteSettingApiQuery();
	let FooterData = data?.response?.data;
	const [attr, setAttr] = useState({
		showChatbox: false,
		showIcon: true,
		messages: [
			{
				text: "",
				timestamp: 1578366389250,
				type: "notification",
			},
			{
				author: {
					username: "user1",
					id: 1,
					//   avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
				},
				text: "Hi",
				type: "text",
				timestamp: 1578366393250,
			},
			{
				author: { username: "user2", id: 2, avatarUrl: null },
				text: "Hi",
				type: "text",
				timestamp: 1578366425250,
			},
			{
				author: {
					username: "user1",
					id: 1,
					//   avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
				},
				text: "What's up?",
				type: "text",
				timestamp: 1578366425250,
				// hasError: true,
			},
		],
	});
	const handleClickIcon = () => {
		// toggle showChatbox and showIcon
		setAttr({
			...attr,
			showChatbox: !attr.showChatbox,
			showIcon: !attr.showIcon,
		});
	};
	const handleOnSendMessage = (message) => {
		setAttr({
			...attr,
			messages: attr.messages.concat({
				author: {
					username: "user1",
					id: 1,
					avatarUrl: "https://image.flaticon.com/icons/svg/2446/2446032.svg",
				},
				text: message,
				type: "text",
				timestamp: +new Date(),
			}),
		});
	};
	return (
		<>
			{/* <Link to="#">
				<div className="chat-now">
					<div>
						<ChatFrame
							chatbox={
								<ChatBox
									onSendMessage={handleOnSendMessage}
									userId={1}
									messages={attr.messages}
									width={"300px"}
									showTypingIndicator={true}
									activeAuthor={{ username: "user2", id: 2, avatarUrl: null }}
								/>
							}
							icon={
								<div className="chat-now">
									<label>Chat now</label>
									<img src={chat} />
								</div>
							}
							clickIcon={handleClickIcon}
							showChatbox={attr.showChatbox}
							showIcon={attr.showIcon}
							iconStyle={{ background: "transparent", fill: "white" }}
						></ChatFrame>
					</div>
				</div>
			</Link> */}
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="footer-logo">
								<img src={logo}></img>
								<p
									dangerouslySetInnerHTML={{
										__html: FooterData && FooterData[0]?.footer_sentence,
									}}
								></p>
								<ul></ul>
							</div>

							<div className="footer-social-item">
								<h3>FOLLOW US</h3>
								<ul>
									<li>
										<a
											href={FooterData && FooterData[0]?.youtube}
											target="_blank"
										>
											<i className="fa fa-youtube-play" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.linkedin}
											target="_blank"
										>
											<i className="fa fa-linkedin" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.twitter}
											target="_blank"
										>
											<i className="fa fa-twitter" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.facebook}
											target="_blank"
										>
											<i className="fa fa-facebook" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/" target="_blank">
											<i className="fa fa-instagram" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.tiktok}
											target="_blank"
										>
											<svg
												id="icons"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
											>
												<path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
											</svg>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col"></div>
						<div className="col-md-2">
							<div className="widget2">
								<div className="footer-head">
									<h3>Quick Links</h3>
									<ul>
										<li>
											<Link to="/about">About us</Link>
										</li>
										<li>
											<Link to="/listing">Products</Link>
										</li>
										<li>
											<Link to="/login">Login</Link>
										</li>
										<li>
											<Link to="/dealer">Become a Dealer</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col"></div>
						<div className="col-md-2">
							<div className="widget3">
								<div className="footer-head">
									<h3>SUPPORT</h3>
									<ul>
										<li>
											<Link to="/">Affiliates</Link>
										</li>
										<li>
											<Link to="/">Sitemap</Link>
										</li>
										<li>
											<Link to="/copy-policy">Copyright Policy</Link>
										</li>
										<li>
											<Link to="/privacy">Privacy Policy</Link>
										</li>
										<li>
											<Link to="/Terms">
												Auctions and Listing Service Terms of Use
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="widget4">
								<div className="footer-head">
									<h3>CONTACT US</h3>
								</div>

								<div className="footer-para"></div>
								<div className="footer-input">
									{/* <input
										type="text"
										placeholder="Enter your email here"
									></input>
									<div className="footer-btn">
										<button className="btn">Send</button>
									</div> */}
									{/* <div className="contactSection">
										<span>
											<PhoneInTalkIcon className="iconzz" />
											<img className="img-fluid" src={phone2}></img>
										</span>
										<p>(800) 0123-456-789</p>
									</div> */}
									<div className="contactSection2">
										<span>
											{/* <EmailIcon className="iconzz" /> */}
											<img className="img-fluid" src={message}></img>
										</span>
										<p>Email: {FooterData && FooterData[0]?.contact_email}</p>
									</div>
									<div className="contactSection3">
										<span>
											{/* <LocationOnIcon className="iconzz" /> */}
											<img className="img-fluid" src={map2}></img>
										</span>
										<p>{FooterData && FooterData[0]?.address}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right">
					<p>{FooterData && FooterData[0]?.copyright}</p>
				</div>
			</footer>
		</>
	);
};

export default Footer;
