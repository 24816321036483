import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const NewsLetterService = createApi({
	reducerPath: "NewsLetterService",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			newsLetter: builder.query({
				query: () => {
					return {
						url: "newsletter/store",
						method: "GET",
					};
				},
			}),
			faqs: builder.query({
				query: () => {
					return {
						url: "faqs",
						method: "GET",
					};
				},
			}),
			contactApi: builder.mutation({
				query: (Contactdata) => {
					return {
						url: "contact-us/store",
						method: "POST",
						body: Contactdata,
					};
				},
			}),
			testimonial: builder.query({
				query: () => {
					return {
						url: "testimonials",
						method: "GET",
					};
				},
			}),
			siteSettingConfig: builder.query({
				query: () => {
					return {
						url: "settings/config",
						method: "GET",
					};
				},
			}),
			buyerTools: builder.query({
				query: () => {
					return {
						url: "buyer_tools",
						method: "GET",
					};
				},
			}),
			buyerToolsDetails: builder.query({
				query: (id) => {
					return {
						url: `buyer_tools/${id}`,
						method: "GET",
					};
				},
			}),
			dealerApi: builder.mutation({
				query: (data) => {
					return {
						url: "dealers/store",
						method: "POST",
						body: data,
					};
				},
			}),
			dealerListApi: builder.query({
				query: () => {
					return {
						url: "dealers",
						method: "GET",
					};
				},
			}),
			dealerListByIdApi: builder.query({
				query: (id) => {
					return {
						url: `dealers/${id}`,
						method: "GET",
					};
				},
			}),
			siteSettingApi: builder.query({
				query: () => {
					return {
						url: "settings",
						method: "GET",
					};
				},
			}),
			feturedPaymentSubcription: builder.query({
				query: () => {
					return {
						url: "featured_ad_settings",
						method: "GET",
					};
				},
			}),
		};
	},
});
export const {
	useNewsLetterQuery,
	useFaqsQuery,
	useContactApiMutation,
	useTestimonialQuery,
	useSiteSettingConfigQuery,
	useBuyerToolsQuery,
	useBuyerToolsDetailsQuery,
	useDealerApiMutation,
	useDealerListApiQuery,
	useDealerListByIdApiQuery,
	useSiteSettingApiQuery,
	useFeturedPaymentSubcriptionQuery,
} = NewsLetterService;
export default NewsLetterService;
