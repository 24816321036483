import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const VechiclesCategoryService = createApi({
	reducerPath: "VechiclesCategoryService",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			vehicleCate: builder.query({
				query: () => {
					return {
						url: "vehicle_categories",
						method: "GET",
					};
				},
			}),
			vehicleType: builder.query({
				query: () => {
					return {
						url: "vehicle_types",
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useVehicleCateQuery, useVehicleTypeQuery } =
	VechiclesCategoryService;
export default VechiclesCategoryService;
