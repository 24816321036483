import React, { useState } from "react";
import ContactUs from "../../Components/Contactus/ContactUs";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { buyer1, buyer2, buyer3 } from "../../constant";
import HowItWorks from "../Home/HowItWorks";
import "../../Assets/Css/buyerdetail.css";
// import BuyerDetailData from "../buyertoolsdetail/Buydetaildata"
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";
// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/sapairpart1.webp";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/sapairpart2.webp";
import feature3 from "../../Assets/img/sapairpart3.webp";
import feature4 from "../../Assets/img/sapairpart4.webp";
import feature5 from "../../Assets/img/sapairpart5.webp";
import feature6 from "../../Assets/img/sapairpart6.webp";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "./../../utils/Loader";
import {
	useBuyerToolsDetailsQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";

const BuyersDetail = () => {
	let { id } = useParams();
	let { data, isFetching } = useBuyerToolsDetailsQuery(id);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let buyer_tool = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const navigate = useNavigate();
	const HandlePageChange = () => {
		navigate("/cardetailpage");
	};
	const categories = [
		{
			catImg: Cat1,
			catName: "New",
		},
		{
			catImg: Cat2,
			catName: "Used",
		},
		{
			catImg: Cat3,
			catName: "Electric",
		},
		{
			catImg: Cat4,
			catName: "Hybrid",
		},
		{
			catImg: Cat5,
			catName: "Price",
		},
		{
			catImg: Cat6,
			catName: "Year",
		},
		{
			catImg: Cat7,
			catName: "Range",
		},
		{
			catImg: Cat8,
			catName: "Mileage",
		},
		{
			catImg: Cat9,
			catName: "Battery",
		},
		{
			catImg: Cat10,
			catName: "color",
		},
		{
			catImg: Cat11,
			catName: "Speed",
		},
		{
			catImg: Cat12,
			catName: "Motor",
		},
		{
			catImg: Cat13,
			catName: "Drivetrain",
		},
	];
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	const BuyerDetailData = [
		{
			id: 1,
			img: buyer1,
			desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		},
		{
			id: 2,
			img: buyer2,
			desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		},
		{
			id: 3,
			img: buyer3,
			desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		},
	];
	return (
		<>
			{!isFetching ? (
				<>
					<section className="buyerstool_page">
						<Header />
						<div className="container ">
							{BuyerDetailData.filter((item) => item.id === 1).map(
								(items, index) => {
									return (
										<>
											<div key={index} className="mb-5">
												{/* <p>{items.desc}</p> */}
												<div className="buyer-card-wrapper">
													<div className="buyer-img-wrapper">
														<figure>
															<img
																src={`${ImageUrls}${buyer_tool?.image}`}
																className="img-fluid"
																alt=""
															/>
														</figure>
													</div>
												</div>
												<div className="container">
													<div className="row">
														<div className="col-md-12">
															<div className="buyerstool_desc">
																<h3>{buyer_tool?.name}</h3>
																<p
																	className="card-text"
																	dangerouslySetInnerHTML={{
																		__html: buyer_tool?.description,
																	}}
																></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									);
								},
							)}
							{/*  */}
						</div>
						<ContactUs />

						<Footer />
					</section>
					<div
						className="modal fade"
						id="exampleModalCenter"
						tabindex="-1"
						role="dialog"
						aria-labelledby="exampleModalCenterTitle"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">
										Booking Form
									</h5>
								</div>
								<div className="modal-body">
									<form action="">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label>Full Name</label>
													<input
														type="text"
														placeholder="Enter your FullName *"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Last Name</label>
													<input
														type="text"
														placeholder="Enter your LastName *"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Your Email Address</label>
													<input
														type="email"
														placeholder="Enter your Email Address *"
														className="form-control"
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>Phone Number</label>
													<input
														type="tel"
														placeholder="Enter your Phone Number*"
														className="form-control"
													/>
												</div>
											</div>
											<label>Booking Date</label>
											<div className="col-md-6">
												<div className="form-group">
													<label>Start Date</label>
													<DatePicker
														selected={startDate}
														onChange={(date) => setStartDate(date)}
														selectsStart
														startDate={startDate}
														endDate={endDate}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>End Date</label>
													<DatePicker
														selected={endDate}
														onChange={(date) => setEndDate(date)}
														selectsEnd
														startDate={startDate}
														endDate={endDate}
														minDate={startDate}
													/>
												</div>
											</div>
										</div>
									</form>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn" data-dismiss="modal">
										Close
									</button>
									<button type="button" className="btn ">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default BuyersDetail;
