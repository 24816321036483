import React from "react";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.png";
import meter3 from "../../Assets/img/meter3.png";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import TopSlider from "./TopSlider";
import { Form } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import HowItWorks from "../Home/HowItWorks";
import ContactUs from "../../Components/Contactus/ContactUs";
import currency from "currency-formatter";
import { buyer1, buyer2, buyer3 } from "../../constant";
import {
	useFeaturedVehicleQuery,
	useTrendingVehicleQuery,
} from "../../store/services/AllVehiclesService";
import {
	useBuyerToolsQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
import { useHomeApiQuery } from "../../store/services/Pages/HomeService";
import Spinner from "./../../utils/Loader";
import GetBanner from "./GetBanner";

// Banner section
console.error();
console.warn();

const Home = () => {
	let { data, isFetching } = useTrendingVehicleQuery();
	let { data: buyerTool, isFetching: buyerLoad } = useBuyerToolsQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const { data: HomeUrl, isFetching: HomeLoad } = useHomeApiQuery();
	const { data: FetureUrl, isFetching: FutureLoad } = useFeaturedVehicleQuery();
	const FetureUrlVehicle = FetureUrl?.response?.data;
	const TrendingVehicle = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	let buyer_tool = buyerTool?.response?.data;
	let Home_Api = HomeUrl?.response?.data;

	const navigate = useNavigate();
	const HandlePageChange = (id) => {
		navigate(`/cardetailpage/${id}`);
	};
	const HandleSeeAll = () => {
		navigate("/listing");
	};
	const Handlesellyourcar = () => {
		navigate("/PostVehicleAds");
	};
	return (
		<>

			{!isFetching ? (
				<>
					<Header />
					<TopSlider />

					{/* Car section  */}
					<section className="car-sec ">
						<div className="container">
							<div className="car-head">
								<h3>Trending</h3>
							</div>
							<div className="row">
								{TrendingVehicle?.map((item, index) => {
									return (
										<>
											<div
												className="col-md-3"
												key={index}
												onClick={() => HandlePageChange(item?.id)}
											>
												<div
													className={`car-dv for-car${index + 1
														} wow animate__animated animate__flipInY`}
												>
													<img
														className="img-fluid"
														src={`${ImageUrls}${item?.image}`}
													></img>
												</div>
												<div className="tesla-model">
													<h5>{item?.title}</h5>
												</div>
											</div>
										</>
									);
								})}
							</div>
						</div>
					</section>
					{/* End of Car section  */}

					{/* Featured section  */}
					<section className="feature-sec  ">
						<div className="container">
							<div className="car-head">
								<h3>Featured</h3>
							</div>
							<div className="row wow animate__animated animate__slideInLeft">
								{FetureUrlVehicle?.length > 1 &&
									FetureUrlVehicle?.map((item, index) => {
										return (
											<>
												<div className="col-md-3" key={item?.id}>
													<div className="featured-all">
														<div className="f-img">
															<img src={`${ImageUrls}${item?.image}`}></img>
														</div>
														<div className="feature-head">
															<h2>
																{
																	currency
																		.format(item?.price, {
																			code: "USD",
																		})
																		.split(".00")[0]
																}
															</h2>
														</div>

														<div className="feature-normal-head">
															<h3>{item?.meta_title}</h3>
														</div>

														<div className="feature-icon-head">
															<span>
																<img src={meter1}></img> {item?.mileage}
															</span>
															<span>
																<img src={meter2}></img>
																{item?.range} max range
															</span>
														</div>
														<div className="feature-iiimmgg">
															<span>
																<img src={meter3}></img> {item?.city},
																{item?.state}
															</span>
														</div>

														<div className="checkup">
															<button
																onClick={() => HandlePageChange(item?.id)}
																className="btn "
															>
																View more
															</button>
														</div>

														{/* <div className="new-listing">
															<h5>New listing</h5>
														</div> */}
													</div>
												</div>
											</>
										);
									})}
							</div>

							{/* Second row  */}

							<div className="see-all ">
								<button className="subBtn" onClick={HandleSeeAll}>
									See All{" "}
									<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</section>
					{/* End of Featured section  */}

					{/* Buyer's Tools starts here */}
					<section className="BuyerTools">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 pb-4">
									<div className="content-wrapper">
										<h4>Buyer's Tools</h4>
									</div>
								</div>
								{buyer_tool?.map((items, index) => {
									return (
										<div className="col-lg-4" key={index}>
											<Link to={`/buyerdetail/${index + 1}`}>
												<div className="buyer-card-wrapper">
													<div className="buyer-img-wrapper">
														<figure>
															<img
																src={ImageUrls + items?.image}
																className="img-fluid"
																alt=""
															/>
														</figure>
													</div>
													<div className="buyer-content-wrapper">
														<h5>{items?.name}</h5>
													</div>
												</div>
											</Link>
										</div>
									);
								})}
							</div>
						</div>
					</section>
					{/* Buyer's Tools ends here */}

					{/* Sell Now section  */}
					<section
						className="sell-sec "
						style={{
							backgroundImage: `url(${ImageUrls}${Home_Api?.section_1_image})`,
							backgroundRepeat: "no-repeat",
						}}
					>
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="sell-head wow animate__animated animate__lightSpeedInRight">
										{/* <h3>Sell Your Car</h3>
								<h2>Now</h2> */}
										<p
											className="card-text"
											dangerouslySetInnerHTML={{
												__html: Home_Api?.section_1_content,
											}}
										></p>

										<div className="checkup">
											<button
												className="btn sellbtn"
												onClick={Handlesellyourcar}
											>
												Sell Now
											</button>
										</div>
									</div>
								</div>
								<div className="col-md-6"></div>
							</div>
						</div>
					</section>
					{/* End of Sell Now section   */}

					<GetBanner />
					<HowItWorks />
					<ContactUs />

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Home;

// End of Banner section
