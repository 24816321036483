import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/blog-detail.css";
import { author, blog_img, admin } from "../../constant";
import { useBlogDetailsQuery } from "../../store/services/BlogService";
import { useParams } from "react-router-dom";
import { useSiteSettingApiQuery } from "../../store/services/AllSiteApisService";
import Spinner from "../../utils/Loader";
const Blog_detail = () => {
	let { id } = useParams();
	let { data, isFetching } = useBlogDetailsQuery(id);
	let { data: footerData, isLoading: load } = useSiteSettingApiQuery();
	let FooterData = footerData?.response?.data;
	let BlogsData = data?.response?.data;
	return (
		<>
			{!isFetching ? (
				<>
					<Header />
					<section className="blog-banner">
						<div className="container">
							<div className="row">
								<div className="col-sm-12">{/* <!-- empty --> */}</div>
							</div>
						</div>
					</section>
					<section className="blog">
						<div className="container">
							{/* <!-- blog title and short-description-row --> */}
							<div className="row py-3">
								<div className="col-sm-12">
									<div className="blog-title">
										<h3 className="blog-title-content">{BlogsData?.title}</h3>
										<p className="blog-description">{BlogsData?.sub_title}</p>
									</div>
								</div>
							</div>
							<div className="row into-row py-3">
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="author">
										<div className="author-img">
											<img src={admin} className="img-fluid" alt="" />
										</div>
										<div className="author-nam">
											<h6 className="author-name">
												{BlogsData?.admin?.full_name}
											</h6>
											<p className="publish-date">{BlogsData?.admin?.email}</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 pt-xs-4">
									<div className="social-icons">
										<ul className="icons">
											<li className="icon-list-tag">
												{" "}
												<a href="@">Share:</a>
											</li>
											<li>
												<a
													href={FooterData && FooterData[0]?.facebook}
													target="_blank"
													className="ico"
												>
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a
													href={FooterData && FooterData[0]?.twitter}
													target="_blank"
													className="ico"
												>
													<i className="fa fa-twitter " aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a
													href="https://www.instagram.com/"
													target="_blank"
													className="ico"
												>
													<i className="fa fa-instagram" aria-hidden="true"></i>
												</a>
											</li>
											{/* <li>
										<a href="#" className="ico">
											<i className="fa fa-github-square" aria-hidden="true"></i>
										</a>
									</li> */}
										</ul>
									</div>
								</div>
							</div>
							{/* <!-- blog title and short-description-row --> */}
							{/* <!-- author and social-icon row --> */}
							<p
								className="card-text"
								dangerouslySetInnerHTML={{
									__html: BlogsData?.description,
								}}
							></p>
						</div>
					</section>
					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Blog_detail;
