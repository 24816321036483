import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const MessagesService = createApi({
	reducerPath: "MessagesService",
	tagTypes: "messangerdta",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/user/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			// getMessageApi: builder.query({
			// 	query: (searchData) => {
			// 		return {
			// 			url: `chats?serachuser=${searchData ? searchData : null}`,
			// 			method: "GET",
			// 		};
			// 	},
			// 	providesTags: ["messangerdta"],
			// }),

			getMessageApiById: builder.query({
				query: (id) => {
					return {
						url: `chats/${id ? id : ""}`,
						method: "GET",
					};
				},
				providesTags: ["messangerdta"],
			}),
			sendMessageApi: builder.mutation({
				query: (data) => {
					return {
						url: "messages/store",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["messangerdta"],
			}),
			getMessageApi: builder.query({
				query: (keyword) => {
					return {
						url: `chats?search=${keyword ? keyword : ''}`,
						method: "GET",
					};
				},
				providesTags: ["messangerdta"],
			}),
		};
	},
});
export const {
	useGetMessageApiQuery,
	useSendMessageApiMutation,
	useGetMessageApiByIdQuery,
} = MessagesService;
export default MessagesService;
