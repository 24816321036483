import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5, Noimage } from "../../constant";
import {
	useGetMessageApiByIdMutation,
	useGetMessageApiByIdQuery,
	useGetMessageApiQuery,
	useSendMessageApiMutation,
} from "../../store/services/Pages/MessagesService";
import { useProfileApiQuery } from "../../store/services/Pages/ProfileService";
import Sidebar from "./Sidebar";
import Moment from "moment";
import { useForm } from "./../../hooks/Form";
import BgImage from "../../Assets/img/ChatBg.jpg";
import { useLocation } from "react-router-dom";
import { pusher } from "../../index";
import { useSelector } from "react-redux";
import Spinner from "../../utils/Loader";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { ToastMessage } from "../../utils/ShowToastMessage";

const Chat = () => {
	const [search, setSearch] = useState(false);
	const [messagelengthss, setMessageLengthss] = useState(0)
	const [validateData, setValidateData] = useState()
	const [searchValue, setSearchvalue] = useState("");
	const { user } = useSelector((item) => item.AuthReducer);
	const location = useLocation();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	const [activeChat, setActiveChat] = useState(null);
	const [secondaryId, SecondaryId] = useState();
	useEffect(() => {
		if (location?.state) {
			setActiveChat(location?.state);
		}
	}, [location?.state]);


	const { state, onChange } = useForm({
		message: "",
	});

	const [keyword, setKey] = useState(null);

	const wordKey = () => {
		if (searchValue.replace(/\s/g, "").length <= 0) {
			return;
		}
		setKey(searchValue);
	};

	useEffect(() => {
		wordKey();
	}, [searchValue]);


	const [getChatData, setGetChatData] = useState();
	let { data: userData } = useProfileApiQuery();
	// let { data, isLoading } = useGetMessageApiQuery(keyword);
	let { data, isLoading } = useGetMessageApiQuery(searchValue);
	let [sendMessage, response] = useSendMessageApiMutation();
	// let getChatData = data?.response?.data;
	let [AllChat, setNewChat] = useState([]);
	let LogingUserData = userData?.response?.data;
	let vehicles_Suppler = JSON.parse(localStorage.getItem("vehicle_suppler"));
	let directUser = vehicles_Suppler?.userfield ?? null;
	let userId = vehicles_Suppler?.user_id ?? null;
	let vehicle_id = vehicles_Suppler?.vehicle_id ?? null;
	let locationGet = vehicles_Suppler?.location;


	const [Ids, setIds] = useState(vehicle_id);
	const [ids, setId] = useState();
	const [refreshids, setRefreshId] = useState();
	let ChatGet = getChatData?.find((item) => item.id === Ids && item);
	let RefreshPageData = data?.response?.data
		?.map((item) => item)
		.filter(
			(items) =>
				items.vehicle_id === activeChat?.vehicle_id ? items.messages : null,
			// items.vehicle_id === vehicle_id ? items.messages : null,
		);

	// let AlluserId = data?.response?.data?.map((item) => item?.user_primary?.id);
	// console.log(ids, "78787")

	useEffect(() => {
		// if (directUser !== null) {
		// 	let userChats = data?.response?.data;
		// 	let newSupplierChat = {
		// 		id: "",
		// 		vehicle_id: vehicle_id,
		// 		user_primary: null,
		// 		user_secondary: directUser.id,
		// 		chat_name: null,
		// 		chat_image: null,
		// 		status: 1,
		// 		messages: [],
		// 	};
		// 	if (userChats != undefined && userChats.length > 0) {
		// 		setGetChatData((prev) => {
		// 			return [newSupplierChat, ...userChats];
		// 		});
		// 	}
		// } else {
		// 	let userChats = data?.response?.data;
		// 	setGetChatData(userChats);
		// }
		let userChats = data?.response?.data;
		setGetChatData(userChats);
	}, [data?.response?.data, keyword]);

	useEffect(() => {
		return () => {
			localStorage.removeItem("vehicle_suppler");
		};
	}, []);


	let { data: getDataById } = useGetMessageApiByIdQuery(ids);
	let IdByMessage = getDataById?.response?.data;

	// console.log(IdByMessage, "ppppldssd")
	const handleActive = (item) => {
		setActiveChat(item);
		setIds(item?.vehicle_id);
		setId(item?.id);
		setMessageLengthss(0)
	};

	const channel = pusher.subscribe(`chat.${user?.id}`);
	useEffect(() => {
		channel.bind("App\\Events\\ChatEvent", (data) => {
			setMessageLengthss(messagelengthss + 1)
			// alert("new Message")
			if (data) {
				// alert(data.data.message)
				// // console.log(data?.data?.chat_id)
				// setId(data?.data?.chat_id)
				// setValidateData(data?.data?.chat_id)
				setValidateData(data?.data)
			}
		});
	}, []);


	// getChatData?.map((item, index) => item?.id).filter(items => items === validateData ? alert("true") : alert('flase'))

	// const pusher = new Pusher(process.env.key, {
	// 	cluster: process.env.cluster,
	// 	encrypted: true,
	// });

	// console.log(pusher, "pusher data");

	// useEffect(() => {
	// 	const channel = pusher.subscribe(
	// 		"chat." + IdByMessage?.id ? IdByMessage?.id : null,
	// 	);
	// 	channel.bind("App\\Events\\ChatEvents", (data) => {
	// 		console.log(data);
	// 	});
	// }, []);



	function CheckIds() {
		if (
			LogingUserData?.id !== RefreshPageData &&
			RefreshPageData?.[0]?.user_primary?.id
		) {
			SecondaryId(RefreshPageData && RefreshPageData?.[0]?.user_primary?.id);
		} else if (
			LogingUserData?.id !== RefreshPageData &&
			RefreshPageData?.[0]?.user_secondary?.id
		) {
			SecondaryId(RefreshPageData && RefreshPageData?.[0]?.user_secondary?.id);
		} else {
			SecondaryId();
		}
	}

	useEffect(() => {
		CheckIds();
	}, [RefreshPageData && !IdByMessage]);

	function CheckIdss() {
		if (LogingUserData?.id !== IdByMessage?.user_primary?.id) {
			SecondaryId(IdByMessage?.user_primary?.id);
		} else if (LogingUserData?.id !== IdByMessage?.user_secondary?.id) {
			SecondaryId(IdByMessage?.user_secondary?.id);
		} else {
			SecondaryId();
		}
	}
	useEffect(() => {
		CheckIdss();
	}, [IdByMessage && !RefreshPageData]);


	const HandleNewChat = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("chat_id", IdByMessage?.id ? IdByMessage?.id : null);
		// FormDatas.append(
		// 	"vehicle_id",
		// 	vehicle_id ? vehicle_id : IdByMessage?.vehicle_id,
		// );
		FormDatas.append("vehicle_id", activeChat?.vehicle_id);
		FormDatas.append(
			"secondary_user",
			secondaryId ? secondaryId : activeChat?.user_id,
		);
		FormDatas.append("message", state?.message);
		// FormDatas.append("file");
		sendMessage(FormDatas);
		setTimeout(() => {
			state.message = "";
		}, 1000);
	};

	// useEffect(() => {
	// 	return () => {
	// 		localStorage.removeItem("vehicle_suppler");
	// 	};
	// }, []);




	// const channel = pusher.subscribe(`chat.${user?.id}`);
	// useEffect(() => {
	// 	channel.bind("App\Events\ChatEvent", (data) => {
	// 		alert("sdsw");
	// 		console.log(data, "Ahmed");
	// 		if (data?.msg) {
	// 			console.log("event called", data?.msg);
	// 		}
	// 	});
	// }, []);


	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<section className="profile_Sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<Sidebar />
								</div>
								<div className="col-lg-9">
									<div className="right-side">
										<div className="title">
											<h3>Messages</h3>
										</div>
										<div className="profile-detail">
											<div className="row">
												<div className="col-md-4">
													<div className="peoples">
														<div
															className={
																search ? "search-box show" : "search-box"
															}
														>
															<input
																className="form-control"
																type="text"
																placeholder="Search..."
																value={searchValue}
																onChange={(e) => setSearchvalue(e.target.value)}
															/>
															<button
																onClick={() => setSearch(false)}
																className="search-btn"
															>
																x
															</button>
														</div>
														<div className="header">
															<h3>Peoples</h3>
															<i
																onClick={() => setSearch(true)}
																className="fa fa-search"
																aria-hidden="true"
															></i>
														</div>

														{/* {vehicles_Suppler && (
													<div className="chat-box active">
														<figure>
															<img className=""img-fluid" src={Chat1} />
														</figure>
														<div className="message">
															<h4 className="name">{directUser}</h4>
														</div>
													</div>
												)} */}
														<div className="all-chats">
															{getChatData?.length > 0 ?
																getChatData?.map((item, index) => {
																	return (
																		<div
																			key={index}
																			className={` ${item?.vehicle_id === Ids
																				? "chat-box active"
																				: "chat-box "
																				}`}
																			onClick={() => handleActive(item)}
																		>
																			{
																				item?.vehicle?.image == null || item?.vehicle?.image == undefined ? (
																					<figure>
																						<img
																							className="img-fluid"
																							src={Noimage}
																						/>
																					</figure>
																				) : (
																					<figure>
																						<img
																							className="img-fluid "
																							src={ImageUrls + item?.vehicle?.image}
																						/>
																					</figure>
																				)
																			}

																			<div className="message">
																				<h4 className="name">
																					{item?.vehicle?.title}
																				</h4>
																				<p>
																					{
																						item.id === validateData?.chat_id && messagelengthss >= 1 ? (
																							validateData?.message
																						) : (item?.messages[
																							item?.messages?.length - 1
																						]?.message)
																					}

																				</p>
																			</div>

																			{
																				item.id === validateData?.chat_id && messagelengthss >= 1 ? (
																					<span className="total-cart-no">{messagelengthss}</span>
																				) : (null)
																			}
																		</div>
																	);
																}) : <p className="messageCenter px-5">Message Not Found!</p>}
														</div>
													</div>
												</div>
												<div className="col-lg-8">
													{!vehicles_Suppler && IdByMessage === undefined ? (
														<>
															<div className="bg_Images">
																<figure>
																	<img
																		src={BgImage}
																		alt=""
																		className="img-fluid"
																	/>
																</figure>
															</div>
														</>
													) : (
														<>
															<div className="chatting-box">
																<div className="header">
																	{
																		activeChat?.vehicle?.image == null || activeChat?.vehicle?.image == undefined ? (
																			<figure>
																				<img
																					className="img-fluid messanger_Img"
																					src={Noimage}
																				/>
																			</figure>
																		) : (
																			<figure>
																				<img
																					className="img-fluid messanger_Img"
																					src={ImageUrls + activeChat?.vehicle?.image}
																				/>
																			</figure>
																		)
																	}
																	<div className="name">
																		<h4>
																			{activeChat?.vehicle?.title}
																		</h4>
																		<p>Vin : {activeChat?.vehicle?.vin}</p>
																	</div>


																</div>

																<div className="messages">
																	{IdByMessage === undefined
																		? RefreshPageData &&
																		RefreshPageData?.[0]?.messages?.map(
																			(item, index) => {
																				const formatDate = Moment(
																					item?.created_at,
																				).format("LT");

																				return (
																					<>
																						{item?.user_from !==
																							LogingUserData?.id ? (
																							<div
																								key={index + 1}
																								className="messenger frst-side"
																							>
																								{
																									RefreshPageData?.[0]?.user_secondary?.image === null ? (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={Noimage}
																											/>
																										</figure>
																									) : (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={ImageUrls + RefreshPageData?.[0]?.user_secondary?.image}
																											/>
																										</figure>
																									)
																								}

																								<div className="meesage">
																									<p>{item?.message}</p>
																								</div>
																								<div className="time">
																									<p>{formatDate}</p>
																								</div>
																							</div>
																						) : (
																							<div className="messenger scnd-side">
																								<div className="time">
																									<p>{formatDate}</p>
																								</div>
																								<div className="meesage">
																									<p>{item?.message}</p>
																								</div>
																								{
																									RefreshPageData?.[0]?.user_primary?.image === null ? (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={Noimage}
																											/>
																										</figure>
																									) : (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={ImageUrls + RefreshPageData?.[0]?.user_primary?.image}
																											/>
																										</figure>
																									)
																								}
																							</div>
																						)}
																					</>
																				);
																			},
																		)
																		: IdByMessage?.messages?.map(
																			(item, index) => {
																				const formatDate = Moment(
																					item?.created_at,
																				).format("LT");

																				return (
																					<>
																						{item?.user_from !==
																							LogingUserData?.id ? (
																							<div
																								key={index + 1}
																								className="messenger frst-side"
																							>
																								{
																									IdByMessage?.user_secondary?.image == null || IdByMessage?.user_secondary?.image == undefined ? (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={Noimage}
																											/>
																										</figure>
																									) : (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={ImageUrls + IdByMessage?.user_secondary?.image}
																											/>
																										</figure>
																									)
																								}
																								<div className="meesage">
																									<p>{item?.message}</p>
																								</div>
																								<div className="time">
																									<p>{formatDate}</p>
																								</div>
																							</div>
																						) : (
																							<div className="messenger scnd-side">
																								<div className="time">
																									<p>{formatDate}</p>
																								</div>
																								<div className="meesage">
																									<p>{item?.message}</p>
																								</div>
																								{
																									IdByMessage?.user_primary?.image == null || IdByMessage?.user_primary?.image == undefined ? (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={Noimage}
																											/>
																										</figure>
																									) : (
																										<figure>
																											<img
																												className="img-fluid messanger_Img"
																												src={ImageUrls + IdByMessage?.user_primary?.image}
																											/>
																										</figure>
																									)
																								}
																							</div>
																						)}
																					</>
																				);
																			},
																		)}

																	{/* <div className="messenger scnd-side">
														<div className="time">
															<p>3:15 am</p>
														</div>
														<div className="meesage">
															<p>
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit.
															</p>
														</div>
														<figure>
															<img src={Chat1} className="img-fluid" />
														</figure>
													</div> */}
																</div>
																<div className="send-box">
																	<input
																		type="text"
																		placeholder="Type message"
																		className="form-control"
																		name="message"
																		value={state.message}
																		onChange={onChange}
																	/>
																	<button
																		className="btn"
																		onClick={HandleNewChat}
																	>
																		Send
																	</button>
																</div>
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Chat;
