import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";

import "../../Assets/Css/bannerads.css";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import { useCallback } from "react";
import { useGetBannersQuery } from "../../store/services/BannerAdsService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import Spinner from "../../utils/Loader";

const BannerAd = () => {
	const { data, isFetching } = useGetBannersQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let Banner_Ads = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	const [value, setValue] = useState("Lorem Ipsum");
	const [copied, setCopied] = useState(false);
	const onChange = useCallback(({ target: { value } }) => {
		setValue(value);
		setCopied(true);
	}, []);
	const onClick = useCallback(({ target: { innerText } }) => {
		// console.log(`Clicked on "${innerText}"!`);
	}, []);
	const onCopy = useCallback(() => {
		setCopied(true);
	}, []);
	function MyButton(props) {
		return <button {...props} />;
	}
	const navigate = useNavigate();
	return (
		<>
			{!isFetching ? (
				<>
					<Header />

					<section className="profile_Sec bannerads_sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<Sidebar />
								</div>
								<div className="col-lg-9">
									<div className="right-side">
										<div className="title">
											<h3>Banner Ads</h3>
											<div className="snap-chat">
												<span>
													(
													{Banner_Ads?.length > 10
														? Banner_Ads?.length
														: `0${Banner_Ads?.length}`}
													)
												</span>
											</div>
										</div>
										<div className="profile-detail"></div>
										{Banner_Ads?.length > 0 &&
											Banner_Ads?.map((item, index) => {
												return (
													<>
														<div className="bannerbg" key={index}>
															<div className="row">
																<div className="col align-self-center">
																	<div className="ads_date_dv">
																		<p>Start Date</p>
																		<p>{item?.start_date}</p>
																	</div>
																</div>
																<div className="col align-self-center">
																	<div className="ads_date_dv">
																		<p>End Date</p>
																		<p>{item?.end_date}</p>
																	</div>
																</div>
																<div className="col align-self-center">
																	<div className="ads_date_dv">
																		<p>Banner Price</p>
																		<p>$40.00</p>
																	</div>
																</div>
																<div className="col-md-4">
																	<div>
																		<img
																			src={`${ImageUrls}${item?.banner_image}`}
																			className="img-fluid"
																			alt=""
																		/>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-8">
																	<div className="adsbannername">
																		<h6>Your Brand Link</h6>
																		<div className="form-group">
																			<input
																				type="email"
																				className="form-control"
																				id="exampleInputEmail1"
																				value={item.link}
																				disabled
																				placeholder="Enter email"
																			/>
																			<CopyToClipboard
																				onCopy={onCopy}
																				text={item.link}
																			>
																				<MyButton className="btn copylink">
																					Copy Link
																				</MyButton>
																			</CopyToClipboard>
																		</div>
																		{copied ? (
																			<span style={{ color: "red" }}>
																				Copied.
																			</span>
																		) : null}
																	</div>
																</div>
																<div className="col-md-4 align-self-center">
																	<div className="redaleart">
																		<span>Expire Date:</span>
																		<span>{item?.end_date}</span>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}

										<div className="button-group">
											<Link className="btn" to="/advertiseyourbanner">
												Upload New Banner
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* <div className="post-add-modal">
                <div
                    className="modal fade"
                    id="postAdd"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <img src={tabsImg} className="img-fluid" alt="" />
                                    <span>Go Feature Sale Faster</span>
                                    <img src={tabsImg} className="img-fluid" alt="" />
                                </h5>
                            </div>
                            <div className="modal-body">
                                <ul>
                                    <li>
                                        <span>
                                            <label className="price_container">
                                                <input
                                                    type="radio"
                                                    hidden
                                                    checked="checked"
                                                    name="Addradio"
                                                />
                                                <span className="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 5 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$20.00</span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="price_container">
                                                <input type="radio" hidden name="Addradio" />
                                                <span className="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 10 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$30.00</span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="price_container">
                                                <input type="radio" hidden name="Addradio" />
                                                <span className="checkmark">
                                                    <span className="dot"></span>
                                                    <ruby>Go Feature 15 Days</ruby>
                                                </span>
                                            </label>
                                        </span>
                                        <span className="price">$40.00</span>
                                    </li>
                                </ul>
                                <div className="button-group">
                                    <button className="btn">Go Featured</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default BannerAd;
