import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import OTPInput from "otp-input-react";
import { useForm } from "./../../hooks/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useVerifyOtpMutation } from "../../store/services/AuthService";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";

const Verification = () => {
	let GetEmail = sessionStorage.getItem("email");
	const [errors, setErrors] = useState([]);
	let [OTP, SetOtp] = useState();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);
	const [userVerifyOtpData, response] = useVerifyOtpMutation();

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("email", GetEmail);
		FormDatas.append("otp", OTP);
		userVerifyOtpData(FormDatas);
	};
	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			ToastMessage(
				response?.error?.data?.errors?.otp &&
				response?.error?.data?.errors?.otp[0],
			);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			SetOtp = "";
			navigate("/change-pass");
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Verification</h2>
									<p>We sent you an OTP on your email. {GetEmail}</p>
								</div>
								<form onSubmit={onSubmit} className="verifyied">
									<label className="title" for="">
										Please enter code{" "}
									</label>
									<OTPInput
										autoFocus
										value={OTP}
										onChange={SetOtp}
										name="otp"
										OTPLength={4}
										otpType="number"
										secure
									/>

									<div className="button-group">
										<button type="submit">
											{response?.isLoading ? "Loading...." : "Next"}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default Verification;
