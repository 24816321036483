import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import States from "../../Assets/img/map.png";
import "../../Assets/Css/all-dealers.css";
import dealer1 from "../../Assets/img/dealer1.png";
import dealer2 from "../../Assets/img/dealer2.png";
import dealer3 from "../../Assets/img/dealer3.png";
import { Link } from "react-router-dom";
import Spinner from "./../../utils/Loader";
import {
	useDealerListApiQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";

const AllDealer = () => {
	let { data, isFetching } = useDealerListApiQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	let Dealer_data = data?.response?.data;
	return (
		<>
			{!isFetching ? (
				<>
					<Header />

					{/* Dealer Sec Start Here */}
					<section className="dealer_sec">
						<div className="container">
							<div className="heading">
								<h3>Dealer Listing</h3>
							</div>
							{/* <div className="filterDv">
								<figure>
									<img src={States} className="img-fluid" />
								</figure>
								<div className="form-group">
									<select className="form-control">
										<option>Filter by States</option>
										<option>California</option>
										<option>Texas</option>
										<option>Florida</option>
									</select>
								</div>
							</div> */}
							<div className="row">
								{Dealer_data?.map((item, index) => {
									return (
										<>
											<div className="col-lg-4" key={index + 1}>
												<div className="dealer-box">
													<div className="img-box">
														<figure>
															<img
																className="img-fluid"
																src={`${ImageUrls}${item?.image}`}
															/>
														</figure>
													</div>
													<div className="content">
														<h4>{item?.full_name}</h4>
														<p>{item?.dealership}</p>
														<div className="button-group">
															<Link to={`/showimg-list/${item?.id}`}>
																Inventory
															</Link>
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>
						</div>
					</section>

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default AllDealer;
