import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ProfileService = createApi({
	reducerPath: "ProfileService",
	tagTypes: "profiledta",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/user/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			profileApi: builder.query({
				query: () => {
					return {
						url: "profile",
						method: "GET",
					};
				},
				providesTags: ["profiledta"],
			}),
			editProfileApi: builder.mutation({
				query: (data) => {
					return {
						url: "profile/update",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["profiledta"],
			}),
			changePassApi: builder.mutation({
				query: (data) => {
					return {
						url: "profile/change-password",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["profiledta"],
			}),
			postSnapApi: builder.mutation({
				query: (data) => {
					return {
						url: "testimonials/store",
						method: "POST",
						body: data,
					};
				},
			}),
		};
	},
});
export const {
	useProfileApiQuery,
	useEditProfileApiMutation,
	useChangePassApiMutation,
	usePostSnapApiMutation,
} = ProfileService;
export default ProfileService;
