import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AdsService = createApi({
	reducerPath: "AdsService",
	tagTypes: "getAds",
	baseQuery: fetchBaseQuery({
		baseUrl:
			"https://allvehicles-backend.developer-ourbase-camp.com/api/user/vehicles/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			myAds: builder.query({
				query: (feturedId) => {
					return {
						url: `my-ads?featured=${feturedId}`,
						method: "GET",
					};
				},
				providesTags: ["getAds"],
			}),
			// getbuyIdAds: builder.query({
			// 	query: (id) => {
			// 		return {
			// 			url: `ad-detail/${id}`,
			// 			method: "GET",
			// 		};
			// 	},
			// 	providesTags: ["getAds"],
			// }),
			getbuyIdAds: builder.query({
				query: (id) => ({
					headers: {
						Accept: "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					url: `ad-detail/${id}`,
					method: "GET",
				}),
				providesTags: ["getAds"],
			}),
			addVehicle: builder.mutation({
				query: (data) => ({
					headers: {
						Accept: "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					url: "store",
					method: "POST",
					body: data,
				}),
				invalidatesTags: ["getAds"],
			}),
			deleteVehicle: builder.mutation({
				query: (id) => {
					return {
						url: `delete/${id}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["getAds"],
			}),

			editVehicle: builder.mutation({
				query: ({ file, id }) => ({
					headers: {
						Accept: "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					url: `update/${id}`,
					method: "POST",
					body: file,
				}),
				invalidatesTags: ["getAds"],
			}),
		};
	},
});
export const {
	useMyAdsQuery,
	useAddVehicleMutation,
	useDeleteVehicleMutation,
	useEditVehicleMutation,
	useGetbuyIdAdsQuery,
} = AdsService;
export default AdsService;
