import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function Recapture() {
	function onChange(value) {
	}
	return (
		<ReCAPTCHA
			sitekey="6LeCaCohAAAAAKRZFr_EaPQdGBsGuCCIhYTn2uwe"
			theme="dark"
			size="small"
			onChange={onChange}
		/>
	);
}

export default Recapture;
