import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { tabsImg } from "../../constant";
import { logout } from "../../store/reducers/authReducers";

const Sidebar = () => {
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const HandleClickLogout = (e) => {
		e.preventDefault();
		// window.location.href = "/"
		navigate("/");
		window.location.reload(true);
		dispatch(logout());
	};
	return (
		<>
			<div className="sidebar">
				<div className="heading">
					<h4>My Account</h4>
				</div>
				<ul className="navs">
					<li>
						<Link to="/profile">
							<span>Edit Profile</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					<li>
						<Link to="/profile-message">
							<span>Messages</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					<li>
						<Link to="/my-add">
							<span>My Ads</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					<li>
						<Link to="/myproducts">
							<span>My Products</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					<li>
						<Link to="/my-orders">
							<span>My Orders</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					<li>
						<Link to="/bannerads">
							<span>Banner Ads</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li>
					{/* <li>
						<Link to="/bidding">
							<span>View Bidding List</span>
							<img src={tabsImg} className="img-fluid" />
						</Link>
					</li> */}
				</ul>
				<div className="logout-btn">
					<button className="btn" onClick={HandleClickLogout}>
						Logout
					</button>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
