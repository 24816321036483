import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const NewsService = createApi({
    reducerPath: 'NewsService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://allvehicles-backend.developer-ourbase-camp.com/api/',
    }),
    endpoints: (builder) => {
        return {
            News: builder.query({
                query: () => {
                    return {
                        url: 'news',
                        method: 'GET',
                    };
                },
            }),
            NewsDetails: builder.query({
                query: (id) => {
                    return {
                        url: `news/${id}`,
                        method: 'GET',
                    };
                },
            }),
        };
    },
});
export const { useNewsQuery, useNewsDetailsQuery } = NewsService;
export default NewsService;
