import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useDeleteAddressMutation } from '../../store/services/AddressService';
import { editAddress, clearAddress, editAddressTrue } from '../../store/reducers/globalReducers';
import { useDispatch } from 'react-redux';
import { ToastMessage } from './../../utils/ShowToastMessage';

const SaveAddress = ({ GetMyAddress }) => {
    let dispatch = useDispatch();
    let [deleteData, response] = useDeleteAddressMutation();
    const addClear = {
        position: 'absolute',
        right: '22px',
        bottom: '20px',
        color: 'red',
        zIndex: '999',
    };
    const addEdit = {
        position: 'absolute',
        right: '50px',
        bottom: '20px',
        color: '#43f22c',
        zIndex: '999',
    };

    const DeleteFunc = (item) => {
        deleteData(item?.id);
        dispatch(clearAddress());
    };

    const EditFunc = (id) => {
        dispatch(editAddress(id));
        dispatch(editAddressTrue("false"));
    };

    useEffect(() => {
        if (response?.data?.message) {
            ToastMessage(response?.data?.message);
        }
    }, [response?.data?.message]);

    return (
        <>
            {GetMyAddress?.length > 0 &&
                GetMyAddress?.map((item, index) => {
                    return (
                        <>
                            <label

                                className="container"
                                style={{ position: 'relative' }}
                                key={index}>
                                <input type="radio" name="radio" />
                                <Tooltip title="Delete">
                                    <DeleteIcon
                                        style={addClear}
                                        onClick={() => DeleteFunc(item)}
                                    />
                                </Tooltip>
                                <Tooltip title="Edit">
                                    <EditIcon
                                        style={addEdit}
                                        onClick={() => EditFunc(item?.id)}
                                    />
                                </Tooltip>
                                <span className="checkmark">
                                    <div className="select-address">
                                        <div className="selection">
                                            <i
                                                className="fa fa-check"
                                                aria-hidden="true"></i>
                                        </div>
                                        <div className="radio-btn">
                                            <span></span>
                                        </div>
                                        <div className="address-detail">
                                            <h5>
                                                <span className="property">
                                                    Country:
                                                </span>
                                                <span className="value">
                                                    {item?.country}
                                                </span>
                                                <span className="property pl-4">
                                                    State:
                                                </span>
                                                <span className="value">
                                                    {item?.state}
                                                </span>
                                            </h5>
                                            <h5>
                                                <span className="property">
                                                    Address:
                                                </span>
                                                <span className="value">
                                                    {item?.address}
                                                </span>
                                            </h5>
                                            <h5>
                                                <span className="property">
                                                    Phone:
                                                </span>
                                                <span className="value">
                                                    {item?.phone}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </span>
                            </label>
                        </>
                    );
                })}
        </>
    );
};

export default SaveAddress;
