import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { checkdouble } from "../../constant";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSuccess, clearMessage } from "../../store/reducers/globalReducers";
import { ToastMessage } from "./../../utils/ShowToastMessage";
import "../../Assets/Css/strip.css";
import { useFeturedPayementMutation } from "../../store/services/AddressService";

const stripePromise = loadStripe(
	"pk_test_51Ln1dCFJJmnlZOnNXtsqSkIDa03oWjE5YMHEyBRHrFs3rlCgPBLH2vNZ9aHqsNFazd9fN2rPVebmWXcbDrZEFNFg00cgM98wJu",
);

const CheckoutForm = ({ vehicle_Id, banner_ad_setting_id }) => {
	const [showModal, setShowModal] = useState("");
	let { success } = useSelector((state) => state.globalReducer);
	let dispatch = useDispatch();
	// let { success } = useSelector((state) => state.globalReducer);
	// let { data, isFetching } = useProfileApiQuery();
	// let LogingUserData = data?.response?.data;
	let [sendFetureData, response] = useFeturedPayementMutation();
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const [paybutton, setPayButton] = useState(true);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (elements == null) {
			return;
		}
		const cardElement = elements.getElement(CardElement);
		const payload = await stripe.createToken(cardElement);
		if (payload?.token?.id) {
			setPayButton(false);
			const formData = new FormData();
			formData.append("vehicle_id", vehicle_Id);
			formData.append("banner_ad_setting_id", banner_ad_setting_id);
			formData.append("stripe_token", payload.token.id);
			sendFetureData(formData);
			cardElement.clear();
			return;
		} else {
			alert("Something Went Wrong");
			cardElement.clear();
			return;
		}
	};
	useEffect(() => {
		if (response.isError) {
			ToastMessage(response?.error?.data?.message);
			setTimeout(() => {
				navigate("/profile");
			}, 2000);
		}
	}, [response?.error?.status == 400]);

	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
		}
	}, [response.isSuccess]);

	useEffect(() => {
		if (success) {
			setShowModal("fade show d-block");
		}
		return () => {
			sessionStorage.removeItem("bannerData");
			dispatch(clearMessage());
		};
	}, [success]);

	return (
		<>
			<form>
				<CardElement
					onChange={(e) => {
						if (e.complete) {
							setPayButton(false);
						} else {
							setPayButton(true);
						}
					}}
				/>
				<br />

				<ul className="list-inline button-group">
					<li className="button-group">
						<button
							type="submit"
							className="btn "
							disabled={!stripe || !elements || paybutton}
							// data-bs-toggle="modal"
							// href="#exampleModalToggle"
							onClick={handleSubmit}
						>
							{response?.isLoading ? "Loading..." : "Pay Now"}
						</button>
					</li>
				</ul>
			</form>
			<div className="col-md-12">
				<div className="adbannerdetail"></div>
				<div className="button-group">
					<div
						className={`modal fade ${showModal}`}
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabindex="-1"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body">
									<img src={checkdouble} className="img-fluid"></img>
									<h4>Your Subscription Payment Was Successful Paid</h4>
									<p>your subscription payment has been successful done</p>
									<Link to="/bannerads" className="btn">
										Go To BannerAds Page
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const StripeFormPayment = ({ vehicle_Id, banner_ad_setting_id }) => {
	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm
				vehicle_Id={vehicle_Id}
				banner_ad_setting_id={banner_ad_setting_id}
			/>
		</Elements>
	);
};

export default StripeFormPayment;
