import { createSlice } from "@reduxjs/toolkit";
const globalReducer = createSlice({
	name: "global",
	initialState: {
		success: "",
		searchs: "",
		addressId: "",
		addressTrue: "true",
		banner: sessionStorage.getItem("bannerData")
			? sessionStorage.getItem("bannerData")
			: {},
	},
	reducers: {
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
		clearMessage: (state) => {
			state.success = "";
		},
		setSearch: (state, action) => {
			state.searchs = action.payload;
		},
		clearSearch: (state) => {
			state.searchs = "";
		},
		setBanner: (state, action) => {
			state.banner = action.payload;
		},
		clearBanner: (state) => {
			state.banner = {};
		},
		editAddress: (state, action) => {
			state.addressId = action.payload;
		},
		editAddressTrue: (state, action) => {
			state.addressTrue = action.payload;
		},
		clearAddress: (state) => {
			state.addressId = "";
		},
	},
});
export const {
	setSuccess,
	clearMessage,
	setSearch,
	clearSearch,
	setBanner,
	clearBanner,
	editAddress,
	clearAddress,
	editAddressTrue
} = globalReducer.actions;
export default globalReducer.reducer;
