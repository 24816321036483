import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/buyerdetail.css";
// import BuyerDetailData from "../buyertoolsdetail/Buydetaildata"
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";
// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";

import feature1 from "../../Assets/img/sapairpart1.webp";

import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";

import feature2 from "../../Assets/img/sapairpart2.webp";
import feature3 from "../../Assets/img/sapairpart3.webp";
import feature4 from "../../Assets/img/sapairpart4.webp";
import feature5 from "../../Assets/img/sapairpart5.webp";
import feature6 from "../../Assets/img/sapairpart6.webp";
import currency from "currency-formatter";
import { Link, useNavigate } from "react-router-dom";
import { useProductsQuery } from "../../store/services/ProductsService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { ToastMessage } from "./../../utils/ShowToastMessage";
import { addCart, addQuantity } from "../../store/reducers/cartReducers";
import { useDispatch } from "react-redux";
import Spinner from "./../../utils/Loader";
import ReactPaginate from "react-paginate";

function Aftermarket() {
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const [currentPageState, setcurrentPageState] = useState(1);
	const [pageurl, setPageurl] = useState(null);
	let { data, isFetching } = useProductsQuery(pageurl);
	let Product_Data = data?.response?.data?.data;
	let Product_Datas = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [links, setLink] = useState(null);
	let paginationData = data?.response?.data?.links;

	const handlePageClick = (data) => {
		let currentPage = data.selected + 1;
		setcurrentPageState(currentPage);
	};
	const pages = Math.ceil(Product_Datas?.total / Product_Datas?.per_page);

	const categories = [
		{
			catImg: Cat1,
			catName: "New",
		},
		{
			catImg: Cat2,
			catName: "Used",
		},
		{
			catImg: Cat3,
			catName: "Electric",
		},
		{
			catImg: Cat4,
			catName: "Hybrid",
		},
		{
			catImg: Cat5,
			catName: "Price",
		},
		{
			catImg: Cat6,
			catName: "Year",
		},
		{
			catImg: Cat7,
			catName: "Range",
		},
		{
			catImg: Cat8,
			catName: "Mileage",
		},
		{
			catImg: Cat9,
			catName: "Battery",
		},
		{
			catImg: Cat10,
			catName: "color",
		},
		{
			catImg: Cat11,
			catName: "Speed",
		},
		{
			catImg: Cat12,
			catName: "Motor",
		},
		{
			catImg: Cat13,
			catName: "Drivetrain",
		},
	];
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	const HandlePageChange = (id) => {
		navigate(`/aftermarketdetail/${id}`);
	};
	useEffect(() => {
		if (data?.status) {
			setLink(paginationData);
		}
	}, [data?.status]);
	const HandleCart = (item) => {
		const cart = localStorage.getItem("cart");
		const cartItems = cart ? JSON.parse(cart) : [];
		const checkItem = cartItems.find((items) => items.id === item.id);
		if (!checkItem) {
			let MyItem2 = Object.assign({}, item, { quantity: 1 });
			dispatch(addCart(MyItem2));
			cartItems.push(MyItem2);
			localStorage.setItem("cart", JSON.stringify(cartItems));
		} else {
			dispatch(addQuantity(item?.id));
			ToastMessage(`${item.title} is add in cart`);
			return;
		}
	};

	const PaginationNext = (url) => {
		setPageurl(url.split("?page=")[1]);
	};

	const AftermarketPage = (item) => {
	};
	// const addToCart = () => {
	// 	const cart = localStorage.getItem("cart");
	// 	const checkItem = cartItems.find((item) => item._id === newProduct._id);
	// 	if (!checkItem) {
	// 	  dispatch(addCart(newProduct));
	// 	  cartItems.push(newProduct);
	// 	  localStorage.setItem("cart", JSON.stringify(cartItems));
	// 	} else {
	// 	  toast.error(`${newProduct.title} is already in cart`);
	// 	  return;
	// 	}
	//   };
	return (
		<>
			{!isFetching ? (
				<>
					<section className="lisiting">
						<Header />
						<div className="container">
							<div className="row">
								{/* <div className="col-lg-3">
							<div className="filter">
								<div className="heading">
									<h4>FILTER BY:</h4>
									<p>
										<i className="fa fa-map-marker" aria-hidden="true"></i> Your
										Location
									</p>
								</div>
								<ul className="categories">
									{categories?.map((cat, index) => {
										return (
											<li key={index}>
												<span>{cat?.catName}</span>
												<img src={cat?.catImg} alt="" className="img-fluid" />
											</li>
										);
									})}
								</ul>
							</div>
						</div> */}
								<div className="col-lg-12">
									{/* <div className="view">
                                        <div className="form-group">
                                            <label>List view</label>
                                            <img
                                                src={Grid}
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div> */}
									{/* Featured section  */}

									<section className="feature-sec mt-4">
										<div className="container">
											<div className="row wow animate__animated animate__slideInLeft">
												{Product_Data?.map((item, index) => {
													return (
														<>
															<div className="col-md-3" key={index + 1}>
																<div className="featured-all">
																	<div
																		className="f-img"
																		onClick={() => HandlePageChange(item?.id)}
																	>
																		<figure>
																			<Slider {...listingImg}>
																				{item?.images?.map((items, key) => (
																					<div key={key}>
																						<img
																							src={`${ImageUrls}${items?.image_name}`}
																						></img>
																					</div>
																				))}
																			</Slider>
																		</figure>
																	</div>
																	<div className="feature-head">
																		<h2>
																			{
																				currency
																					.format(item?.price, {
																						code: "USD",
																					})
																					.split(".00")[0]
																			}
																		</h2>
																	</div>

																	<div className="feature-normal-headds">
																		<h3>{item?.meta_description}</h3>
																	</div>

																	<div className="feature-icon-headds">
																		<h6>{item?.description}</h6>
																	</div>

																	<div className="checkup">
																		<div className="row">
																			<div className="col-md-5">
																				<button
																					className="btn btn22"
																					onClick={() =>
																						HandlePageChange(item?.id)
																					}
																				>
																					View more
																				</button>
																			</div>
																			<div className="col-md-4">
																				<button
																					className="btn btn22 mr-4"
																					onClick={() => HandleCart(item)}
																				>
																					Add to cart
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													);
												})}
											</div>
										</div>
									</section>
									{/* <div className="pagination">
										<div className="pageCount">
											{links?.map((item, index) => {
												console.log(item);
												if (item?.label === "&laquo; Previous") {
													return (
														<button
															key={index + 100}
															onClick={() => {
																PaginationNext(item?.url);
															}}
															disabled={item?.url === null ? true : false}
															className={
																item?.active
																	? "btn btn-light bt-pagi atcvv_btnz"
																	: "btn btn-light bt-pagi"
															}
														>
															Previous
														</button>
													);
												} else if (item?.label === "Next &raquo;") {
													return (
														<button
															key={index + 100}
															onClick={() => {
																PaginationNext(item?.url);
															}}
															disabled={item?.url === null ? true : false}
															className={
																item?.active
																	? "btn btn-light bt-pagi atcvv_btnz"
																	: "btn btn-light bt-pagi"
															}
														>
															Next
														</button>
													);
												} else {
													return (
														<button
															key={index + 100}
															onClick={() => {
																PaginationNext(item?.url);
															}}
															disabled={item?.url === null ? true : false}
															className={
																item?.active
																	? "btn btn-light bt-pagi atcvv_btnz"
																	: "btn btn-light bt-pagi"
															}
														>
															{item?.label}
														</button>
													);
												}
											})}
										</div>
									</div> */}
									{/* <div className="pagination">
										<div className="pageCount">
											<ReactPaginate
												breakLabel="..."
												nextLabel=">"
												previousLabel="<"
												onPageChange={handlePageClick}
												marginPagesDisplayed={0}
												pageRangeDisplayed={4}
												pageCount={pages}
											/>
										</div>
									</div> */}
									{Product_Data?.length > 0 &&
										<div className="pagination">
											<div className="pageCount">
												{links?.map((item, index) => {
													if (item?.label === "&laquo; Previous") {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																Previous
															</button>
														);
													} else if (item?.label === "Next &raquo;") {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																Next
															</button>
														);
													} else {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																{item?.label}
															</button>
														);
													}
												})}
											</div>
										</div>
									}
									{/* End of Featured section  */}
								</div>
							</div>
						</div>
						<Footer />
					</section>
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}

export default Aftermarket;
