import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	cartimg,
	detail_img,
	meter_img,
	meter1,
	meter2,
	meter3,
	feature1,
	feature2,
	feature3,
	feature4,
	feature5,
	feature6,
	meter,
	buyer1,
	buyer2,
	buyer3,
	Noimage
} from "../../constant/index";
import Slider from "react-slick";
import "../../Assets/Css/car-detail.css";
import { useVehicleDetailsQuery } from "../../store/services/AllVehiclesService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import Spinner from "./../../utils/Loader";
import { useProfileApiQuery } from "../../store/services/Pages/ProfileService";

const Cardetailpage = () => {
	let { id } = useParams();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const { data, isFetching } = useVehicleDetailsQuery(id);
	let { data: userData } = useProfileApiQuery();
	let LogingUserData = userData?.response?.data;
	let categoriesData = data?.response?.data;
	const navigate = useNavigate();
	const HandlePageChange = (e, id) => {
		e.preventDefault();
		navigate(`/cardetailpage/${id}`);
	};
	let ImageUrls = ImageUrl?.uploads;
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	let equiplist = [
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
	];

	const Navigate = useNavigate();
	let allDatas = {
		vin: categoriesData?.vin,
		userfield: categoriesData?.user,
		vehicle_id: categoriesData?.id,
		user_id: categoriesData?.user_id,
		location: "/profile-message",
		vehicle: {
			title: categoriesData?.title,
			image: categoriesData?.image,
			vin: categoriesData?.vin,
		},
		user_secondary: {
			image: categoriesData?.user?.image
		}
	};
	const HandleClick = () => {
		if (JSON.parse(localStorage.getItem("userData"))?.token) {
			localStorage.setItem("vehicle_suppler", JSON.stringify(allDatas));
			Navigate(`/profile-message`, {
				state: {
					user_secondary: categoriesData?.user,
					...allDatas,
				},
			});
		} else {
			Navigate(`/login`, {
				state: {
					siteLocation: "/profile-message",
					user_secondary: categoriesData?.user,
					...allDatas,
				},
			});
			localStorage.setItem("vehicle_suppler", JSON.stringify(allDatas));
		}
	};
	return (
		<>
			{!isFetching ? (
				<>
					<Header />
					{/* car  detal sec starts here */}
					<section className="cardetail-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="img-and-btn-wrapper">
										<div className="slider-wrapper">
											{
												categoriesData?.images?.length <= 0 ? (<Slider {...settings}>
													<div className="detail-img-wrapper">
														<figure>
															<img src={Noimage} className="img-fluid" style={{ border: '1px solid #0000001c' }} />
															<span className="no">00</span>
														</figure>
													</div>

												</Slider>) : (
													<Slider {...settings}>
														{
															categoriesData?.images?.map((item, index) => {
																return (
																	<div className="detail-img-wrapper">
																		<figure>
																			<img src={ImageUrls + item?.image_name} className="img-fluid" />
																			<span className="no">{index + 1 >= 10 ? index + 1 : `0 ${index + 1}`}</span>
																		</figure>
																	</div>
																)
															})
														}

													</Slider>
												)
											}

										</div>
										<div className="btn-wrapper">
											<a href="/carView">
												<img src={cartimg} />
											</a>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="detail-content-wrapper">
										<div className="top-btn-wrapper">
											<h4>
												ASKING PRICE:{" "}
												<span className="price">
													{categoriesData?.price &&
														currency
															.format(categoriesData?.price, {
																code: "USD",
															})
															.split(".")[0]}
												</span>
											</h4>
											{categoriesData?.user?.user_type === "user" &&
												categoriesData?.user_id !== LogingUserData?.id && (
													<button className="btn" onClick={HandleClick}>
														Message Seller
													</button>
												)}

											<button className="btnBlack">Request CAR FAX</button>
											<button className="btn btnrws">
												Request Window Sticker
											</button>
											<button
												className="btngrey"
												data-toggle="modal"
												data-target="#battery_health"
											>
												Battery Health Report
											</button>

											<h4 className="vehicles_vin">Vin : {categoriesData?.vin}</h4>

											<div
												className="modal fade"
												id="battery_health"
												tabindex="-1"
												role="dialog"
												aria-labelledby="exampleModalCenterTitle"
												aria-hidden="true"
											>
												<div
													className="modal-dialog modal-dialog-centered"
													role="document"
												>
													<div className="modal-content">
														<div className="modal-header">
															<h5
																className="modal-title"
																id="exampleModalLongTitle"
															></h5>
															<button
																type="button"
																className="close"
																data-dismiss="modal"
																aria-label="Close"
															>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<div className="text-center">
																<h3>Battery Health Report</h3>
																<h6>Battery Health 78%</h6>
															</div>
															<div className="row">
																<div className="col-md-4">&nbsp;</div>
																<div className="col-md-8"></div>
															</div>
															<div className="row">
																<div className="col-md-4 ">
																	<div>
																		<p>High Temperatures</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="50"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{
																				width: "50%",
																			}}
																		></div>
																		<span>50%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Low Temperatures</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="70"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{
																				width: "70%",
																			}}
																		></div>
																		<span>70%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Fast Charging</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="40"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{
																				width: "40%",
																			}}
																		></div>
																		<span>40%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Slow Charging</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="90"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{
																				width: "55%",
																			}}
																		></div>
																		<span>55%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>More highway driving</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: "25%",
																			}}
																			aria-valuenow="25"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>25%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>More city driving</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: "50%",
																			}}
																			aria-valuenow="50"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>50%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p> Charging to 100%</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: "75%",
																			}}
																			aria-valuenow="75"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>75%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Letting the charge drop to 0%</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: "80%",
																			}}
																			aria-valuenow="100"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>80%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">&nbsp;</div>
																<div className="col-md-8"></div>
															</div>
															<div className="row">
																<div className="col-md-4 border-0">
																	<div></div>
																</div>
																<div className="col-md-8 ">
																	<div className="progress_values">
																		<p>20%</p>
																		<p>40%</p>
																		<p>60%</p>
																		<p>80%</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="stats-wrapper">
											<h5>
												{categoriesData?.mileage}
												<span className="statsname">Mileage:</span>
											</h5>
											<h5>
												{categoriesData?.range}
												<span className="statsname">Total Range:</span>
											</h5>
											<h5>
												5<span className="statsname">Seats:</span>
											</h5>
										</div>
										<div className="title-name-wrapper">
											<h2>{categoriesData?.meta_title}</h2>
											<h5>
												{" "}
												<i className="fa fa-map-marker"></i>{" "}
												{categoriesData?.city}, {categoriesData?.state}
											</h5>
										</div>
										<div className="highlights-wrapper">
											<h4>HIGHLIGHTS:</h4>
											<div className="highlights-list-wrapper">
												<ul>
													<li>
														{" "}
														{/* <i className="fa fa-check"></i>  */}
														<img src={meter_img} />
														Park Distance Control
													</li>
													<li>
														{" "}
														{/* <i className="fa fa-check"></i>  */}
														<img src={meter_img} />
														Comfort and Convenience Package
													</li>
													<li>
														{" "}
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i> */}
														Panoramic Sunroof
													</li>
													<li>
														{" "}
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														Back Up Camera
													</li>
													<li>
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														Park Distance Control
													</li>
													<li>
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														AWD
													</li>
												</ul>
											</div>
										</div>
										<div className="seller-snap-wrapper">
											<div className="seller-heading-wrapper">
												<h5>Seller Snapshot :</h5>
											</div>
											<div className="seller-des-wrapper">
												<p>{categoriesData?.seller_snapshots}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* car  detal sec ends here */}
					{/* car sspecification starts here */}
					<section className="car-specification">
						<div className="container">
							<div className="row">
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>EXTERIOR:</h6>
										<ul>
											<li>
												<span className="property">Body Style</span>
												<span className="value">Hatchback</span>
											</li>
											<li>
												<span className="property">Exterior Color</span>
												<span className="value">
													{categoriesData?.exterior_color}
												</span>
											</li>
											<li>
												<span className="property">Length</span>
												<span className="value">169.8"</span>
											</li>
											<li>
												<span className="property">Wheelbase</span>
												<span className="value">105.1"</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>INTERIOR:</h6>
										<ul>
											<li>
												<span className="property">Seating</span>
												<span className="value">5</span>
											</li>
											<li>
												<span className="property">Interior Color</span>
												<span className="value">N/A</span>
											</li>
											<li>
												<span className="property">Head Room Front</span>
												<span className="value">39.9"</span>
											</li>
											<li>
												<span className="property">Head Room Rear</span>
												<span className="value">36.3"</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>PERFORMANCE:</h6>
										<ul>
											<li>
												<span className="property">Total Range</span>
												<span className="value">{categoriesData?.range}</span>
											</li>
											<li>
												<span className="property">Electric Range</span>
												<span className="value">Blue</span>
											</li>
											<li>
												<span className="property">EPA/Combined MPG</span>
												<span className="value">169.8"</span>
											</li>
											<li>
												<span className="property">0-60</span>
												<span className="value">105.1"</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* car sspecification ends here */}
					{/* equipments sec starts here */}
					<section className="EQUIPMENT-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="equipment-wrapper">
										<div className="equipment-heading-wrapper">
											<h5>EQUIPMENT & OPTIONS:</h5>
										</div>
										<div className="equip-list-wrapper">
											<ul>
												{equiplist.map((data, index) => {
													return (
														<li key={index}>
															<img src={meter_img} /> {data.list_no}
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* equipments sec ends here */}

					{/* Buyer's Tools starts here */}
					<section className="BuyerTools">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 pb-4">
									<div className="content-wrapper">
										<div>
											<h4>Buyer's Tools</h4>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<Link to="/buyerdetail">
										<div className="buyer-card-wrapper">
											<div className="buyer-img-wrapper">
												<figure>
													<img src={buyer1} className="img-fluid" alt="" />
												</figure>
											</div>
											<div className="buyer-content-wrapper">
												<h5>Customize Your Ride</h5>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-lg-4">
									<Link to="/buyerdetail">
										<div className="buyer-card-wrapper">
											<div className="buyer-img-wrapper">
												<figure>
													<img src={buyer2} className="img-fluid" alt="" />
												</figure>
											</div>
											<div className="buyer-content-wrapper">
												<h5>Shipping Quotes</h5>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-lg-4">
									<Link to="/buyerdetail">
										<div className="buyer-card-wrapper">
											<div className="buyer-img-wrapper">
												<figure>
													<img src={buyer3} className="img-fluid" alt="" />
												</figure>
											</div>
											<div className="buyer-content-wrapper">
												<h5>Financing Options</h5>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</section>
					{/* Buyer's Tools ends here */}

					{/* Featured section  */}
					<section className="feature-sec  ">
						<div className="container">
							<div className="car-head">
								<h3>SIMILAR ELECTRIC VEHICLES FOR SALE:</h3>
							</div>
							<div className="row wow animate__animated animate__slideInLeft">
								{categoriesData?.related_vehciles?.length > 0 && categoriesData?.related_vehciles?.map((item, index) => {
									return index < 4 ? (
										<div className="col-md-3" key={index}>
											<div className="featured-all">
												<div className="f-img">
													<img src={feature1}></img>
												</div>
												<div className="feature-head">
													<h2>
														{item?.price &&
															currency
																.format(item?.price, {
																	code: "USD",
																})
																.split(".")[0]}
													</h2>
												</div>

												<div className="feature-normal-head">
													<h3>{item?.title}</h3>
												</div>

												<div className="feature-icon-head">
													<span>
														<img src={meter1}></img> {item?.mileage}{" "}
														miles
													</span>
													<span>
														<img src={meter2}></img> {item?.range}{" "}
														max range
													</span>
												</div>
												<div className="feature-iiimmgg">
													<span>
														<img src={meter3}></img> {item?.city},
														{item?.location}
													</span>
												</div>

												<div className="checkup">
													<button
														className="btn"
														onClick={(e) =>
															HandlePageChange(e, item?.id)
														}
													>
														View more
													</button>
												</div>


											</div>
										</div>
									) : null
								})}



							</div>
						</div>
					</section>
					{/* End of Featured section  */}
					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Cardetailpage;
