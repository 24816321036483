import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/blog-detail.css";
import { author, blog_img, blog_img2, admin } from "../../constant";
import { useResearchApiQuery } from "../../store/services/Pages/TermsCondition";
import {
	useSiteSettingApiQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
const OurResearch = () => {
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let { data: footerData, isLoading: load } = useSiteSettingApiQuery();
	let FooterData = footerData?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	let { data, isFetching } = useResearchApiQuery();
	let ResearchPage = data?.response?.data;
	return (
		<>
			<Header />
			<section
				className="blog-banner ourrearch"
				style={{
					backgroundImage: `linear-gradient(#acabab5e, #acabab5e),url(${ImageUrls}${ResearchPage?.banner_image})`,
					backgroundRepeat: "no-repeat",
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="wunversal_heading">
								<h1>Our Research</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="blog">
				<div className="container">
					{/* <!-- blog title and short-description-row --> */}
					<div className="row py-3">
						<div className="col-sm-12">
							<div className="blog-title">
								<h3 className="blog-title-content">Our Research</h3>
								<p className="blog-description">
									Orci varius natoque penatibus et magnis dis parturient montes,
									nascetur ridiculus mus. Etiam placerat tortor commodo lectus
									laoreet venenatis. Donec ultricies, metus vitae bibendum
									consequat, tortor neque euismod lectus
								</p>
							</div>
						</div>
					</div>
					{/* <!-- blog title and short-description-row --> */}
					{/* <!-- author and social-icon row --> */}
					<div className="row into-row py-3">
						<div className="col-lg-6 col-md-6 col-sm-6">
							<div className="author">
								<div className="author-img">
									<img src={admin} className="img-fluid" alt="" />
								</div>
								<div className="author-nam">
									<h6 className="author-name">
										{ResearchPage?.admin?.full_name}
									</h6>
									<p className="publish-date">{ResearchPage?.admin?.email}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 pt-xs-4">
							<div className="social-icons">
								<ul className="icons">
									<li className="icon-list-tag">
										{" "}
										<a href="@">Share:</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.facebook}
											target="_blank"
											className="ico"
										>
											<i className="fa fa-facebook" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href={FooterData && FooterData[0]?.twitter}
											target="_blank"
											className="ico"
										>
											<i className="fa fa-twitter " aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/"
											target="_blank"
											className="ico"
										>
											<i className="fa fa-instagram" aria-hidden="true"></i>
										</a>
									</li>
									{/* <li>
										<a href="#" className="ico">
											<i className="fa fa-github-square" aria-hidden="true"></i>
										</a>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
					{/* <!-- author and social-icon row --> */}
					<div className="row py-4">
						<div className="col-sm-12">
							<p
								dangerouslySetInnerHTML={{
									__html: ResearchPage?.content,
								}}
							></p>

							<p
								dangerouslySetInnerHTML={{
									__html: ResearchPage?.section_1_content,
								}}
							></p>

							{/* <div className="quote-wrapper">
								<blockquote>
									Suspendisse sagittis id lacus eget vulputate. Ut nec arcu ut
									sem molestie tincidunt luctus <br /> eget tellus.
								</blockquote>
								<p className="quotaion-name text-center">Parsley Montana</p>
							</div> */}

							{/* <h3 className="blog-heading">Big heading for a new topic</h3> */}
							{/* <p className="blog-description py-3">
								Morbi pellentesque finibus libero, in blandit lorem eleifend
								eget. Praesent egestas hendrerit augue a vestibulum. Nullam
								fringilla, eros malesuada eleifend placerat, lacus tellus
								egestas erat, nec varius sem lorem ut mauris. Morbi libero
								felis.
							</p> */}
							{/* <ul className="mb-5">
								<li>
									Morbi pellentesque finibus libero, in blandit lorem eleifend
									eget. Praesent egestas hendrerit augue a vestibulum. Nullam
									fringilla, eros malesuada eleifend placerat, lacus tellus
									egestas erat, nec varius sem lorem ut mauris. Morbi libero
									felis.
								</li>
								<li>
									Cras eget dolor accumsan, blandit risus vitae, faucibus erat.
									Aliquam scelerisque, diam ut feugiat scelerisque, diam felis
									venenatis purus, eget cursus enim turpis at sem. Fusce nec
									tristique dolor, sit amet tristique purus.
								</li>
							</ul> */}
							{/* <figure>
								<img src={blog_img} className="blog-img img-fluid" alt="" />
							</figure>
							<p className="blog-description py-3">
								Quisque at odio semper, elementum leo sed, congue tellus. Proin
								nunc mauris, porttitor ut eleifend ut, consectetur ut dolor. In
								hac habitasse platea dictumst. Pellentesque ornare nulla ut quam
								blandit scelerisque. Suspendisse non orci id elit tempor rhoncus
								ac id nunc. Integer scelerisque at turpis sit amet faucibus.
								Etiam non euismod urna.
							</p> */}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default OurResearch;
