import React, { useState, useEffect } from "react";

import currency from "currency-formatter";
import feature2 from "../../Assets/img/sapairpart2.webp";
import { useSelector } from "react-redux";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { useNavigate } from "react-router-dom";

const OrderSummary = () => {
	let navigate = useNavigate();
	const { cart, total } = useSelector((state) => state.cartReducers);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	useEffect(() => {
		TotalAmount();
	}, [cart]);
	let SendPrice = cart
		?.map((item) => {
			return item?.price;
		})
		.reduce((a, b) => a + b, 0);

	const [subTotal, setSubTotal] = useState(0);
	const TotalAmount = () => {
		let i;
		let total = 0;
		for (i = 0; i < cart?.length; i++) {
			total = total + cart[i]?.price * cart[i]?.quantity;
		}
		setSubTotal(total);
	};
	return (
		<>
			<div className="second-top-bar">
				<div className="title pb-4">
					<h3>Order Summary</h3>
				</div>
			</div>
			<div className="cart-product-detail">
				<div className="title">
					<h3>Products Details</h3>
				</div>
				{cart?.map((item, index) => {
					return (
						<>
							<div className="checkout-products" key={index}>
								<div className="products br-right">
									<div className="abt-product">
										<div className="img-box">
											<figure>
												<img src={`${ImageUrls}${item?.image}`} alt="123" />
											</figure>
										</div>
										<div className="pro-dtl">
											<h5 className="name">COUNTRYMAN</h5>
											<ul className="variations">
												<li>Items ({item?.quantity})</li>
											</ul>
										</div>
									</div>
									<div className="product-price">
										<h4 className="price">
											{
												currency
													.format(item?.price, {
														code: "USD",
													})
													.split(".00")[0]
											}
										</h4>
									</div>
								</div>
							</div>
						</>
					);
				})}

				<div className="pricing-details">
					<div className="item-total">
						<h4 className="property">Item Total :</h4>
						<h4 className="value">
							{
								currency
									.format(
										subTotal,

										{
											code: "USD",
										},
									)
									.split(".00")[0]
							}
						</h4>
					</div>
					{/* <div className="item-total">
						<h4 className="property">Delivery charges</h4>
						<h4 className="value">
							{
								currency
									.format(3, {
										code: "USD",
									})
									.split(".00")[0]
							}
						</h4>
					</div> */}

					{/* <div className="item-total">
						<h4 className="property">Promo Code Applied :</h4>
						<h4 className="value">
							{
								currency
									.format(10, {
										code: "USD",
									})
									.split(".00")[0]
							}
						</h4>
					</div> */}
				</div>

				<div className="order-total">
					<h3 className="property">Order Total</h3>
					<h3 className="value">
						{
							currency
								.format(
									subTotal,

									{
										code: "USD",
									},
								)
								.split(".00")[0]
						}
					</h3>
				</div>
			</div>
		</>
	);
};

export default OrderSummary;
