import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useForm } from "./../../hooks/Form";
import {
	useEditMyProductsMutation,
	useGetMySingleProductsQuery,
	useProductCategoriesQuery,
} from "../../store/services/ProductsService";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { toast } from "react-toastify";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import Spinner from "./../../utils/Loader";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { useDispatch, useSelector } from "react-redux";

const EditProduct = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const { data: getUpdatePro, isFetching: ProductLoad } =
		useGetMySingleProductsQuery(id);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const [editProduct, response] = useEditMyProductsMutation();
	const { data, isFetching } = useProductCategoriesQuery();
	let Product_Categories = data?.response?.data;
	let Product_Get = getUpdatePro?.response?.data;
	const dispatch = useDispatch();
	let { success } = useSelector((state) => state.globalReducer);
	let { state, onChange, setState } = useForm({
		category: "",
		title: "",
		detail: "",
		description: "",
		stock_qty: "",
		price: "",
		status: "",
	});

	const fileTypes = ["JPG", "PNG", "GIF"];
	const videdTypes = ["JPG", "PNG", "GIF"];
	let [singleImage, setSingleImage] = useState();
	const [images, setImages] = useState([]);
	const [imagesPreview, setImagesPreview] = useState([]);
	const [imagesPreviewBack, setImagesPreviewBack] = useState([]);
	let ImageUrls = ImageUrl?.uploads;
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};

	const createSingleImageChange = (e) => {
		const files = Array.from(e.target.files);

		setSingleImage();

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					setSingleImage(files[0]);
				}
			};

			reader.readAsDataURL(file);
		});
	};


	useEffect(() => {
		setImagesPreviewBack(Product_Get?.images);
		setState({
			category: Product_Get?.product_category_id,
			title: Product_Get?.title,
			detail: Product_Get?.detail,
			description: Product_Get?.description,
			stock_qty: Product_Get?.stock_qty,
			price: Product_Get?.price,
		});
		setSingleImage(Product_Get?.image);
	}, [getUpdatePro?.response?.data]);

	const createProductImagesChange = (e) => {
		const files = Array.from(e.target.files);
		setImages([]);
		setImagesPreview([]);

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2 && files?.length === 3) {
					setImagesPreview((old) => [...old, reader.result]);
					setImages(files);
				}
			};

			reader.readAsDataURL(file);
		});

	};
	const SubmitHandler = (e) => {
		e.preventDefault();
		const myForm = new FormData();
		myForm.append("category", state?.category);
		myForm.append("title", state?.title);
		myForm.append("detail", state?.detail);
		myForm.append("description", state?.description);
		myForm.append("stock_qty", state?.stock_qty);
		myForm.append("price", state?.price);
		myForm.append("image", singleImage);
		myForm.append("status", state?.status);

		images.forEach((image, index) => {
			myForm.append(`otherimages[${image?.name?.split(" ")[0]}]`, image);
		});
		editProduct({ file: myForm, id });
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					ToastMessage(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
			setTimeout(() => {
				navigate("/myproducts");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			{
				!ProductLoad ? (
					<>
						<Header />
						{/* PostVehicle starts here */}
						<section className="profile_Sec">
							<div className="container">
								<div className="row">
									<div className="col-lg-3">
										<Sidebar />
									</div>
									<div className="col-lg-9">
										<div className="right-side">
											<div className="title">
												<h3>Edit Product</h3>
											</div>
											<div className="addproduct_sec">
												<div className="form-group row">
													{/* <div className="col-lg-6">
										<input
											type="text"
											placeholder="Dealership"
											className="form-control"
										/>
									</div> */}
													{/* category,
		title,
		detail,
		description,
		stock_qty,
		price,
		image,
		status, */}
													<div className="col-lg-6">
														<div className="form-floating mb-3">
															<input
																type="text"
																placeholder="Title"
																className="form-control"
																name="title"
																onChange={onChange}
																value={state?.title}
															/>
															<label htmlFor="floatingInput">Title</label>
														</div>
													</div>

													<div className="col-lg-6">
														<select
															className="form-select"
															id="cars"
															name="category"
															value={state?.category}
															onChange={onChange}
														>
															{Product_Categories?.map((item, index) => {
																return (
																	<option key={index + 1} value={item?.id}>
																		{item?.name}
																	</option>
																);
															})}
														</select>
														{/* <select
												className="form-select"
												aria-label="Default select example"
											>
												<option selected>Select Category</option>
												<option value="1">One</option>
												<option value="2">Two</option>
												<option value="3">Three</option>
                    </select> */}
													</div>
													<div className="col-lg-6">
														<div className="form-floating mb-3">
															<textarea
																className="form-control"
																placeholder="Leave a comment here"
																id="floatingTextarea"
																value={state?.detail}
																name="detail"
																onChange={onChange}
															></textarea>
															<label htmlFor="floatingTextarea">Detail</label>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="form-floating mb-3">
															<textarea
																className="form-control"
																placeholder="Leave a comment here"
																id="floatingTextarea"
																rows="2"
																value={state?.description}
																name="description"
																onChange={onChange}
															></textarea>
															<label htmlFor="floatingTextarea">Description</label>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="form-floating mb-3">
															<input
																type="text"
																placeholder="Stock Quantity"
																className="form-control"
																value={state?.stock_qty}
																name="stock_qty"
																onChange={onChange}
															/>
															<label htmlFor="floatingInput">Stock Quantity</label>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="form-floating mb-3">
															<input
																type="text"
																placeholder="Price"
																className="form-control"
																value={state?.price}
																name="price"
																onChange={onChange}
															/>
															<label htmlFor="floatingInput">Price</label>
														</div>
													</div>
													<div className="col-lg-6">
														<select
															className="form-select"
															aria-label="Default select example"
															name="status"
															value={state?.status}
															onChange={onChange}
														>
															<option selected value="in_stock ">
																In Stock
															</option>
															<option value="out_stock">Out Stock</option>
														</select>
													</div>
													<div className="col-md-6">
														<div className="mb-3">
															<div className="fileUpload  btn--browse">
																<label htmlFor="uploadBtn" className="form-label d-flex">
																	{/* <input
															className="form-control"
															value={name}
															placeholder="Upload Images"
														/> */}
																	<input
																		type="file"
																		id="uploadBtn"
																		className="form-control"
																		accept="image/png , image/jpeg, image/webp"
																		onChange={createSingleImageChange}
																	/>

																	<i className="fa fa-paperclip" aria-hidden="true"></i>
																</label>
															</div>
														</div>
													</div>

													<div className="col-md-12">
														<div className="mb-3">
															<div className="fileUpload  btn--browse">
																<label htmlFor="uploadBtn" className="form-label d-flex">
																	{/* <input
															className="form-control"
															value={name}
															placeholder="Upload Images"
														/> */}

																	<input
																		type="file"
																		name="avatar"
																		multiple
																		id="uploadBtn"
																		className="form-control"
																		accept="image/png , image/jpeg, image/webp"
																		onChange={createProductImagesChange}
																	/>

																	<i className="fa fa-paperclip" aria-hidden="true"></i>
																</label>
															</div>
														</div>
													</div>
													{imagesPreview?.length > 0 ? (
														<div id="createProductFormImage" className="mb-2">
															{imagesPreview?.map((image, index) => (
																<img key={index} src={image} alt="Product Preview" />
															))}
														</div>
													) : (
														<div id="createProductFormImage" className="mb-2">
															{imagesPreviewBack?.map((image, index) => (
																<img
																	key={index}
																	src={`${ImageUrls}${image?.image_name}`}
																	alt="Product Preview"
																/>
															))}
														</div>
													)}
													<div className="col-md-12 ">
														<div className="button-group">
															<button onClick={SubmitHandler} className="btn  ">
																Save Changes
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<Footer />
					</>
				) : <Spinner />
			}

		</>
	);
};

export default EditProduct;
