import React, { useEffect } from "react";
import "./App.css";
import "../src/Assets/Css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllRoutes from "./Pages/AllRoutes/AllRoutes";
import WOW from "wowjs";
import { gapi } from "gapi-script";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";

let CLIENT_ID =
	"465146905825-auqsd5sbgqlseqoq90acja0j97k7mchd.apps.googleusercontent.com";

function App() {
	useEffect(() => {
		function start() {
			gapi.client.init({
				clientId: CLIENT_ID,
				scope: "",
			});
		}

		gapi.load("client:auth2", start);
	}, []);

	new WOW.WOW({
		live: false,
	}).init();


	return (
		<>
			<AllRoutes />
			{/* <GoogleOAuthProvider
				GoogleOAuthProvider
				clientId="465146905825-auqsd5sbgqlseqoq90acja0j97k7mchd.apps.googleusercontent.com"
			>
			</GoogleOAuthProvider>
			; */}
		</>
	);
}

export default App;
