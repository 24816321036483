import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import {
	useEditProfileApiMutation,
	useProfileApiQuery,
} from "../../store/services/Pages/ProfileService";
import { useForm } from "./../../hooks/Form";
import { useSelector, useDispatch } from "react-redux";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { FileUploader } from "react-drag-drop-files";
import Spinner from "../../utils/Loader";

const EditProfile = () => {
	let { data, isLoading } = useProfileApiQuery();
	let LogingUserData = data?.response?.data;
	const [errors, setErrors] = useState([]);
	const { state, onChange, setState } = useForm({
		full_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		address: "",
		avatar: "",
		description: ""
	});
	const videdTypes = ["JPG", "PNG"];
	const handleChangeImages = (file) => {
		setState({ ...state, avatar: file });
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [editData, response] = useEditProfileApiMutation();
	let { success } = useSelector((state) => state.globalReducer);
	useEffect(() => {
		setState(LogingUserData);
	}, [isLoading === true]);

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		// if (state?.first_name.length > 10) {
		// 	return ToastMessage("FirstName Can Not be Greater than 10")
		// }
		// if (state?.last_name.length > 10) {
		// 	return ToastMessage("FirstName Can Not be Greater than 10")
		// }
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				state.email
			)
		) {
			ToastMessage("Invalid Email");
			return;
		}
		FormDatas.append("first_name", state?.first_name);
		FormDatas.append("last_name", state?.last_name);
		FormDatas.append("email", state?.email);
		FormDatas.append("contact_no", state?.contact_no);
		FormDatas.append("address", state?.address);
		FormDatas.append("avatar", state?.avatar);
		FormDatas.append("description", state?.description);
		editData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			// state.first_name = "";
			// state.last_name = "";
			// state.email = "";
			// state.contact_no = "";
			// state.address = "";

			setTimeout(() => {
				navigate("/profile");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<section className="profile_Sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<Sidebar />
								</div>
								<div className="col-lg-9">
									<div className="right-side">
										<div className="title">
											<h3>Edit Profile</h3>
										</div>
										<div className="editprofile_sec">
											<div className="row">
												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputfname" className="form-label">
															First Name
														</label>
														<input
															type="text"
															id="inputfname"
															className={`${errors && errors?.first_name
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="first_name"
															value={state?.first_name}
															onChange={onChange}
															maxLength={20}
														/>
													</div>
													{errors && errors?.first_name && (
														<span className="errorMessage">
															<p>{errors && errors?.first_name}</p>
														</span>
													)}
												</div>
												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputfname" className="form-label">
															Last Name
														</label>
														<input
															type="text"
															id="inputfname"
															className={`${errors && errors?.last_name
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="last_name"
															value={state?.last_name}
															onChange={onChange}
															maxLength={20}
														/>
													</div>
													{errors && errors?.last_name && (
														<span className="errorMessage">
															<p>{errors && errors?.last_name}</p>
														</span>
													)}
												</div>

												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputemail" className="form-label">
															Email
														</label>
														<input
															type="email"
															disabled
															className={`${errors && errors?.email
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="email"
															value={state?.email}
															onChange={onChange}
															id="inputemail"
														/>
													</div>
													{errors && errors?.email && (
														<span className="errorMessage">
															<p>{errors && errors?.email}</p>
														</span>
													)}
												</div>

												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputtel" className="form-label">
															Contact Number
														</label>
														<input
															type="number"
															className={`${errors && errors?.contact_no
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="contact_no"
															value={state?.contact_no}
															onChange={onChange}
															id="inputtel"
															maxLength={10}
														/>
													</div>
													{errors && errors?.contact_no && (
														<span className="errorMessage">
															<p>{errors && errors?.contact_no}</p>
														</span>
													)}
												</div>

												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputaddress" className="form-label">
															Address
														</label>
														<input
															type="text"
															className={`${errors && errors?.address
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="address"
															value={state?.address !== "null" ? state?.address : ''}
															onChange={onChange}
															id="inputaddress"
														/>
													</div>
													{errors && errors?.address && (
														<span className="errorMessage">
															<p>{errors && errors?.address}</p>
														</span>
													)}
												</div>
												<div className="col-md-6">
													<div className="mb-3">
														<label htmlFor="inputaddress" className="form-label">
															Desciption
														</label>
														<input
															type="text"
															className={`${errors && errors?.description
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															name="description"
															value={state?.description}
															onChange={onChange}
															id="inputdescription"
														/>
													</div>
													{errors && errors?.description && (
														<span className="errorMessage">
															<p>{errors && errors?.description}</p>
														</span>
													)}
												</div>
												<div className="col-md-6 mt-3">
													<FileUploader
														handleChange={handleChangeImages}
														accept="image/*"
														name="avatar"
														types={videdTypes}
														label=""
													/>
													<p>
														{state?.avatar ? (
															`File name: ${state?.avatar?.name}`
														) : (
															<label htmlFor="">Main Image</label>
														)}
													</p>
												</div>

												<div className="col-md-12">
													<div className="button-group">
														<button onClick={onSubmit} className="btn ">
															Save Changes
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default EditProfile;
