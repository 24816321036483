import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useForm } from "./../../hooks/Form";
import {
	useProductCategoriesQuery,
	useStoreMyProductsMutation,
} from "../../store/services/ProductsService";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { RiCloseCircleLine } from "react-icons/ri";

const AddProduct = () => {
	const [storeProduct, response] = useStoreMyProductsMutation();
	const { data, isFetching } = useProductCategoriesQuery();
	let Product_Categories = data?.response?.data;
	const { state, onChange } = useForm({
		category: "",
		title: "",
		detail: "",
		description: "",
		stock_qty: "",
		price: "",
		status: "",
	});
	const {
		category,
		title,
		detail,
		description,
		stock_qty,
		price,
		image,
		status,
	} = state;
	const fileTypes = ["JPG", "PNG", "GIF"];
	const videdTypes = ["JPG", "PNG", "GIF"];
	const [singleImage, setSingleImage] = useState();
	const [imagesPreview, setImagesPreview] = useState('');
	const [uploadImages, setUploadImages] = useState([]);
	const [multiImageFiles, setMultiImageFiles] = useState([]);
	// const [images, setImages] = useState([]);
	// const [imagesPreview, setImagesPreview] = useState([]);
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);
	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};

	const createSingleImageChange = (e) => {
		const files = Array.from(e.target.files);

		setSingleImage();

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					setSingleImage(files);
					setImagesPreview(URL.createObjectURL(file))
				}
			};

			reader.readAsDataURL(file);
		});
	};

	// const createProductImagesChange = (e) => {
	// 	const files = Array.from(e.target.files);
	// 	console.log(e.target.files);
	// 	setImages([]);
	// 	setImagesPreview([]);

	// 	files.forEach((file) => {
	// 		const reader = new FileReader();

	// 		reader.onload = () => {
	// 			if (reader.readyState === 2 && files?.length === 3) {
	// 				setImagesPreview((old) => [...old, reader.result]);
	// 				setImages(files);
	// 			}
	// 		};

	// 		reader.readAsDataURL(file);
	// 	});
	// 	// if (files?.length > 3 || files?.length < 3) {
	// 	// 	ToastMessage("Multiple images Only 3 select");
	// 	// }
	// };

	const createProductImagesChange = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file)
			);
			setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file) // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadImages((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderPhotos = (source) => {
		return source.map((photo, index) => {
			return (
				<li>
					{" "}
					<img
						src={photo}
						alt=""
						style={{ width: "100px", height: "100px", objectFit: "cover" }}
					/>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) =>
							RemoveMultiImg(e, photo)
						}
					/>
				</li>
			);
		});
	};


	const RemoveMultiImg = (e, item) => {
		e.preventDefault()
		setMultiImageFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	}


	const SubmitHandler = (e) => {
		e.preventDefault();

		const myForm = new FormData();
		myForm.append("category", category);
		myForm.append("title", title);
		myForm.append("detail", detail);
		myForm.append("description", description);
		myForm.append("stock_qty", stock_qty);
		myForm.append("price", price);
		myForm.append("image", singleImage && singleImage[0]);
		myForm.append("status", status);

		uploadImages.forEach((image, index) => {
			myForm.append(`otherimages[${image?.name?.split(" ")[0]}]`, image);
		});
		storeProduct(myForm);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					ToastMessage(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
			setTimeout(() => {
				navigate("/myproducts");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />
			{/* PostVehicle starts here */}
			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Add Product</h3>
								</div>
								<div className="addproduct_sec">
									<div className="form-group row">
										{/* <div className="col-lg-6">
										<input
											type="text"
											placeholder="Dealership"
											className="form-control"
										/>
									</div> */}
										{/* category,
		title,
		detail,
		description,
		stock_qty,
		price,
		image,
		status, */}
										<div className="col-lg-6">
											<div className="form-floating mb-3">
												<input
													type="text"
													placeholder="Title"
													className="form-control"
													name="title"
													onChange={onChange}
													value={title}
													maxLength={20}
												/>
												<label htmlFor="floatingInput">Title</label>
											</div>
										</div>

										<div className="col-lg-6">
											<select
												className="form-select"
												id="cars"
												name="category"
												value={category}
												onChange={onChange}
											>
												<option selected >Select Category</option>
												{Product_Categories?.map((item, index) => {
													return (
														<option key={item?.id} value={item?.id}>
															{item?.name}
														</option>
													);
												})}
											</select>
											{/* <select
												className="form-select"
												aria-label="Default select example"
											>
												<option selected>Select Category</option>
												<option value="1">One</option>
												<option value="2">Two</option>
												<option value="3">Three</option>
                    </select> */}
										</div>
										<div className="col-lg-6">
											<div className="form-floating mb-3">
												<textarea
													className="form-control"
													placeholder="Leave a comment here"
													id="floatingTextarea"
													value={detail}
													name="detail"
													onChange={onChange}
												></textarea>
												<label htmlFor="floatingTextarea">Detail</label>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-floating mb-3">
												<textarea
													className="form-control"
													placeholder="Leave a comment here"
													id="floatingTextarea"
													rows="2"
													value={description}
													name="description"
													onChange={onChange}
												></textarea>
												<label htmlFor="floatingTextarea">Description</label>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-floating mb-3">
												<input
													type="number"
													placeholder="Stock Quantity"
													className="form-control"
													value={stock_qty}
													name="stock_qty"
													onChange={onChange}
												/>
												<label htmlFor="floatingInput">Stock Quantity</label>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-floating mb-3">
												<input
													type="number"
													placeholder="Price"
													className="form-control"
													value={price}
													name="price"
													onChange={onChange}
												/>
												<label htmlFor="floatingInput">Price</label>
											</div>
										</div>
										<div className="col-lg-6">
											<select
												className="form-select"
												aria-label="Default select example"
												name="status"
												value={status}
												onChange={onChange}
											>
												<option selected value="in_stock ">
													In Stock
												</option>
												<option value="out_stock">Out Stock</option>
											</select>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<div className="fileUpload  btn--browse">
													<label htmlFor="uploadBtn1" className="form-label d-flex">
														{/* <input
															className="form-control"
															value={name}
															placeholder="Upload Images"
														/> */}
														<input
															type="file"
															id="uploadBtn1"
															className="form-control"
															accept="image/*"
															onChange={createSingleImageChange}
														/>

														<i className="fa fa-paperclip" aria-hidden="true"></i>
													</label>
												</div>
												{
													imagesPreview &&
													<img src={imagesPreview} style={{ width: "100px", height: "100px", objectFit: "cover" }} />
												}
											</div>
										</div>

										<div className="col-md-12">
											<div className="mb-3">
												<div className="fileUpload  btn--browse">
													<label htmlFor="uploadBtn" className="form-label d-flex">
														{/* <input
															className="form-control"
															value={name}
															placeholder="Upload Images"
														/> */}

														<input
															type="file"
															name="avatar"
															multiple
															id="uploadBtn"
															className="form-control"
															accept="image/*"
															onChange={createProductImagesChange}
														/>

														<i className="fa fa-paperclip" aria-hidden="true"></i>
													</label>
												</div>
											</div>
										</div>
										<ul id="fileList" className="multi-imglist">
											{renderPhotos(multiImageFiles)}
										</ul>
										<div className="col-md-12 ">
											<div className="button-group">
												<button onClick={SubmitHandler} className="btn  ">
													{response?.isLoading ? "Loading..." : "Save Changes"}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default AddProduct;
