import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../Assets/img/logo.png";
import active from "../Assets/img/active.png";
import login from "../Assets/img/login.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSiteSettingConfigQuery } from "../store/services/AllSiteApisService";
import { useProfileApiQuery } from "../store/services/Pages/ProfileService";
import { Avatar } from "../constant";

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { items } = useSelector((state) => state.cartReducers);
	const { user } = useSelector((state) => state.AuthReducer);
	let { data: profileData } = useProfileApiQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let LogingUserData = profileData?.response?.data;
	const HandleChange = () => {
		navigate('/profile')
	}
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
	return (
		<>
			<div className="Header">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="header-logo">
								<Link to="/">
									<img className="img-fluid" src={logo} />
								</Link>
							</div>
						</div>
						<div className="col-md-7 align-self-center">
							<div className="header-list">
								<ul>
									<li>
										<NavLink
											activeclassname="active"
											to="/"
											className="head_after"
										>
											Home
										</NavLink>
									</li>
									<></>
									{location.pathname == "/" ? (
										<>
											<li>
												<Link
													to="/listing"
													activeclassname="active"
													className="head_after "
												>
													buy
												</Link>
												{/* <span>
													<img src={active} />
												</span> */}
												<ul className="dropdown-inner">
													<li>
														<Link to="#">Cars</Link>
													</li>
													<li>
														<Link to="#">Trucks</Link>
													</li>
													<li>
														<Link to="#">Motocycles</Link>
													</li>
													<li>
														<Link to="#">Other</Link>
													</li>
												</ul>
											</li>
										</>
									) : (
										<li>
											<NavLink
												to="/listing"
												activeclassname="active"
												className="head_after"
											>
												buy
											</NavLink>
											<ul className="dropdown-inner">
												<li>
													<Link to="#">Cars</Link>
												</li>
												<li>
													<Link to="#">Trucks</Link>
												</li>
												<li>
													<Link to="#">Motocycles</Link>
												</li>
												<li>
													<Link to="#">Other</Link>
												</li>
											</ul>
										</li>
									)}
									<li>
										<NavLink
											to="/PostVehicleAds"
											activeclassname="active"
											className="head_after"
										>
											sell
										</NavLink>
										<ul className="dropdown-inner">
											<li>
												<Link to="#">Private Sale</Link>
											</li>
											<li>
												<Link to="/dealer">Dealer Sale</Link>
											</li>
										</ul>
									</li>

									<li>
										<NavLink
											activeclassname="active"
											to="/About"
											className="head_after"
										>
											about
										</NavLink>
									</li>
									{/* <li>
										<NavLink
											activeclassname="active"
											to="/all-dealer"
											className="head_after"
										>
											dealers
										</NavLink>
									</li>
									<li>
										<NavLink
											activeclassname="active"
											to="/booking"
											className="head_after"
										>
											booking
										</NavLink>
									</li> */}
									<li>
										<NavLink
											activeclassname="active"
											to="/aftermarket"
											className="head_after"
										>
											aftermarket
										</NavLink>
									</li>
									{/* <li>
										<NavLink
											activeclassname="active"
											to="/companies"
											className="head_after"
										>
											companies
										</NavLink>
									</li> */}
									<li>
										<Link
											to="#"
											activeclassname="active"
											className="head_after"
										>
											Learn
										</Link>
										<ul className="dropdown-inner">
											<li>
												<Link to="/blog">Blog / News</Link>
											</li>
											<li>
												<Link to="/faqs">FAQ</Link>
											</li>
											{/* <li>
												<Link to="#"></Link>
											</li> */}
											{/* <li>
												<Link to="/Ourresearch">Research</Link>
											</li> */}
										</ul>
									</li>
									<li>
										<Link to="/cart" className="total-cart-icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="22.832"
												height="20.295"
												viewBox="0 0 22.832 20.295"
											>
												<path
													id="Icon_awesome-shopping-cart"
													data-name="Icon awesome-shopping-cart"
													d="M20.934,11.944,22.808,3.7a.951.951,0,0,0-.928-1.162H6.311L5.947.761A.951.951,0,0,0,5.015,0H.951A.951.951,0,0,0,0,.951v.634a.951.951,0,0,0,.951.951h2.77L6.506,16.15a2.22,2.22,0,1,0,2.658.34h8.31a2.219,2.219,0,1,0,2.521-.412l.219-.962a.951.951,0,0,0-.928-1.162H8.646l-.259-1.268h11.62A.951.951,0,0,0,20.934,11.944Z"
													fill="#434343"
												/>
											</svg>

											<span className="total-cart-no">{items}</span>
										</Link>
									</li>
									{user ? (
										<button className="images_set button_setImage" onClick={HandleChange}>
											{/* <Link to="/profile" className="images_set"> */}

											{LogingUserData?.image == null ? (
												<img src={Avatar} alt="" className="img-fluid" />
											) : (
												<img
													src={`${ImageUrl?.uploads}${LogingUserData?.image}`}
													alt=""
													className="img-fluid"
												/>
											)}
											{/* </Link> */}
										</button>
									) : (
										<li>
											<NavLink
												activeclassname="active"
												to="/login"
												className="head_after"
											>
												Login
											</NavLink>
										</li>
									)}
									{/* <li>
										
										<div className="dropdown show">
											<Link
												className="btn btn-secondary dropdown-toggle login-btn"
												to="#"
												role="button"
												id="dropdownMenuLink"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												My Account
											</Link>

											<div
												className="dropdown-menu"
												aria-labelledby="dropdownMenuLink"
											>
												<Link to="/login" className="dropdown-item">
													Dealer
												</Link>
												<Link className="dropdown-item" to="/login">
													Private
												</Link>
											</div>
										</div>
									</li> */}
								</ul>
							</div>

							<div className="login-btn"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="actionLink">
				<div className="button-group">
					{/* <div className="box">
						<div className="contactDv">
							<div className="chatDv">
								<h3>Chat with Us</h3>
								<form>
									<div className="form-group">
										<input className="form-control" type="text" placeholder="Your Name" />
									</div>
									<div className="form-group">
										<input className="form-control" type="email" placeholder="Email" />
									</div>
									<div className="form-group">
										<input className="form-control" type="text" placeholder="Contact" />
									</div>
									<div className="form-group">
										<textarea className="form-control" placeholder="Your Message"></textarea>
									</div>
									<button type="submit">Submit</button>
								</form>
							</div>
						</div>
					</div> */}
					{/* <div className="contactDv newsletter">
						<div className="contact-btn">Subscribe</div>
						<div className="chatDv">
							<h3>Subscribe for Newsletter</h3>
							<form>
								<div className="form-group">
									<input
										className="form-control"
										type="email"
										placeholder="Email"
									/>
								</div>
								<button type="submit">Subscribe</button>
							</form>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default Header;
