import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import TopSlider from "../Home/TopSlider";
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";

// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import thanks from "../../Assets/img/thankyou.gif";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import {
	useAllbookingApiQuery,
	useBookingProductApiMutation,
	useProductsQuery,
} from "../../store/services/ProductsService";
import { cartimg, meter_img, Noimage } from "../../constant/index";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import { useForm } from "./../../hooks/Form";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import Spinner from "../../utils/Loader";

function Booking() {
	let { data, isFetching } = useAllbookingApiQuery();
	let [bookingForm, response] = useBookingProductApiMutation();
	const [ids, setIds] = useState(null);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let Product_Data = data?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	const [errors, setErrors] = useState([]);
	const { state, onChange } = useForm({
		first_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		address: "",
		instructions: "",
	});
	let setDates = new Date();
	let LastDate = setDates.setDate(setDates.getDate() + 1);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(LastDate);
	const dispatch = useDispatch();
	const [show, setShow] = useState("");
	const [block, setBlock] = useState("none");
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);
	let successResponse = response?.data?.response?.data;
	const categories = [
		{
			catImg: Cat1,
			catName: "New",
		},
		{
			catImg: Cat2,
			catName: "Used",
		},
		{
			catImg: Cat3,
			catName: "Electric",
		},
		{
			catImg: Cat4,
			catName: "Hybrid",
		},
		{
			catImg: Cat5,
			catName: "Price",
		},
		{
			catImg: Cat6,
			catName: "Year",
		},
		{
			catImg: Cat7,
			catName: "Range",
		},
		{
			catImg: Cat8,
			catName: "Mileage",
		},
		{
			catImg: Cat9,
			catName: "Battery",
		},
		{
			catImg: Cat10,
			catName: "color",
		},
		{
			catImg: Cat11,
			catName: "Speed",
		},
		{
			catImg: Cat12,
			catName: "Motor",
		},
		{
			catImg: Cat13,
			catName: "Drivetrain",
		},
	];
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

	const HandleIds = (e, id) => {
		e.preventDefault();
		setIds(id);
	};

	const HandlePageChange = (id) => {
		navigate(`/bookingdetails/${id}`);
	};

	const newStartDates = Moment(startDate).format("YYYY/MM/DD");
	const newEndsDates = Moment(endDate).format("YYYY/MM/DD");
	const BookingFormSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("first_name", state.first_name);
		FormDatas.append("last_name", state.last_name);
		FormDatas.append("booking_id", ids);
		FormDatas.append("email", state.email);
		FormDatas.append("contact_no", state.contact_no);
		FormDatas.append("address", state.address);
		FormDatas.append("start_date", newStartDates);
		FormDatas.append("end_date", newEndsDates);
		FormDatas.append("instructions", state.instructions);
		bookingForm(FormDatas);
	};

	const HandleCancel = (e) => {
		window.location.reload();
		setBlock("none");
		setShow("");
		state.first_name = "";
		state.last_name = "";
		state.email = "";
		state.contact_no = "";
		state.address = "";
		state.instructions = "";
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			setShow("show");
			setBlock("block");
		}
	}, [response.isSuccess]);

	return (
		<>
			{
				!isFetching ? (
					<>
						<section className="lisiting">
							<Header />
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										{/* Featured section  */}

										<section className="feature-sec">
											<div className="container">
												<div className="row wow animate__animated animate__slideInLeft">
													{Product_Data?.length > 0 ? Product_Data?.map((item, index) => {
														return (
															<>
																<div className="col-md-3" key={index + 1}>
																	<div className="featured-all">
																		<div
																			className="f-img"
																			onClick={() => HandlePageChange(item?.id)}
																		>
																			<figure>
																				<Slider {...listingImg}>
																					{item?.images?.length > 0 ? (
																						item?.images?.map((items, index) => {
																							return index < 4 ? (
																								<img
																									src={`${ImageUrls}${items?.image_name}`}
																								></img>
																							) : null
																						})
																					) : (
																						<img
																							src={Noimage}
																						></img>
																					)

																					}
																				</Slider>
																			</figure>
																		</div>
																		<div className="feature-head">
																			<h2>
																				{
																					currency
																						.format(item?.price, {
																							code: "USD",
																						})
																						.split(".00")[0]
																				}
																			</h2>
																		</div>

																		<div className="feature-normal-headds">
																			<h3>{item?.meta_description}</h3>
																		</div>

																		<div className="feature-icon-headds">
																			<h6>{item?.description}</h6>
																		</div>

																		<div className="checkup">
																			<div className="row">
																				<div className="col-md-5">
																					<button
																						className="btn btn22"
																						onClick={() => HandlePageChange(item?.id)}
																					>
																						View more
																					</button>
																				</div>
																				<div className="col-md-4">
																					<button
																						data-toggle="modal"
																						data-target="#exampleModalCenter"
																						className="btn btn22 mr-4"
																						onClick={(e) => HandleIds(e, item?.id)}
																					>
																						Book now
																					</button>
																				</div>
																			</div>
																		</div>

																		{/* <div className="new-listing">
																<h5>New listing</h5>
															</div> */}
																	</div>
																</div>
															</>
														);
													}) : (
														<h3 style={{ display: 'flex', justifyContent: "center", alignItem: 'center', marginTop: "100px", marginBottom: "67px", margin: "0 auto" }}>No Booking Found</h3>
													)}
												</div>
											</div>
										</section>
										{/* <div className="pagination">
											<div className="pageCount">
												<button className="btn3">1</button>
												<button className="btn1">2</button>
												<button className="btn1">3</button>
												<button className="btn1">4</button>
												<button className="btn1">5</button>
												<button className="btn1">10</button>
												<button className="btn2">
													Next <i className="fa fa-angle-right" aria-hidden="true"></i>
												</button>
												<i className="bi bi-chevron-right"></i>
											</div>
										</div> */}
										{/* End of Featured section  */}
									</div>
								</div>
							</div>
							<Footer />
						</section>
						<div
							className={`modal fade ${show === "show" && ""}`}
							id="exampleModalCenter"
							tabindex="-1"
							role="dialog"
							aria-labelledby="exampleModalCenterTitle"
							aria-hidden="true"
							style={{
								display: `${block === "block" && "none"}`,
							}}
						>
							<div className="modal-dialog modal-dialog-centered" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLongTitle">
											Booking Form
										</h5>
									</div>
									<div className="modal-body">
										<form>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label>Full Name*</label>
														<input
															type="text"
															placeholder="Enter your FullName"
															className="form-control"
															name="first_name"
															value={state.first_name}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Last Name*</label>
														<input
															type="text"
															placeholder="Enter your LastName"
															className="form-control"
															name="last_name"
															value={state.last_name}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Your Email Address*</label>
														<input
															type="email"
															placeholder="Enter your Email Address"
															className="form-control"
															name="email"
															value={state.email}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Phone Number*</label>
														<input
															type="tel"
															placeholder="Enter your Phone Number"
															className="form-control"
															name="contact_no"
															value={state.contact_no}
															onChange={onChange}
														/>
													</div>
												</div>
												<label>Booking Date*</label>
												<div className="col-md-6">
													<div className="form-group">
														<label>Start Date</label>
														<DatePicker
															selected={startDate}
															selectsStart
															onChange={(date) => setStartDate(date)}
															startDate={startDate}
															endDate={startDate}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>End Date</label>
														<DatePicker
															selected={endDate}
															onChange={(date) => setEndDate(date)}
															selectsEnd
															endDate={endDate}
															minDate={endDate}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Special Instructions*</label>
														<textarea
															className="form_control_text form-control"
															id="exampleFormControlTextarea3"
															rows="7"
															name="instructions"
															value={state.instructions}
															onChange={onChange}
														></textarea>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Address*</label>
														<textarea
															className="form_control_text form-control"
															id="exampleFormControlTextarea3"
															rows="7"
															name="address"
															value={state.address}
															onChange={onChange}
														></textarea>
													</div>
												</div>
											</div>
										</form>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn" data-dismiss="modal">
											Close
										</button>
										<button
											type="button"
											className="btn "
											onClick={BookingFormSubmit}
										>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							className={`modal fade ${show !== "" && show}`}
							id="exampleModalCenter22"
							tabindex="-1"
							role="dialog"
							aria-labelledby="exampleModalCenterTitle22"
							aria-hidden="true"
							style={{
								display: `${block}`,
							}}
						>
							<div
								className="modal-dialog modal-dialog-centered modal_all_container"
								role="document"
							>
								<div className="modal-content">
									<div className="modal_center_success">
										<h5 className="modal-title" id="exampleModalLongTitle22">
											Booking Successfully
										</h5>
										<img src={thanks} alt="" />
									</div>
									<div
										className="position_set_cancel"
										data-dismiss="modal"
										onClick={HandleCancel}
									>
										<ClearIcon />
									</div>

									<div className="form_content_detail">
										<div>
											<p>
												<span>FirstName:</span> {state?.first_name}
											</p>

											<p>
												<span>Email:</span> {state?.email}
											</p>
											<p>
												<span>Phone:</span> {state?.contact_no}
											</p>
											<p>
												<span>Start Date:</span> {newStartDates}
											</p>
											<p>
												<span>End Date:</span> {newEndsDates}
											</p>
										</div>
									</div>
									<div></div>
								</div>
							</div>
						</div>
					</>
				) : <Spinner />
			}
		</>

	);
}

export default Booking;
