import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { cartimg, meter_img, Noimage } from "../../constant/index";
import feature2 from "../../Assets/img/sapairpart2.webp";
import Slider from "react-slick";
import "../../Assets/Css/car-detail.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import thanks from "../../Assets/img/thankyou.gif";
import currency from "currency-formatter";
import { useForm } from "./../../hooks/Form";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { useBookingProductApiMutation, useGetbookingApiByIdQuery } from "../../store/services/ProductsService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import Spinner from "../../utils/Loader";

const BookingDetails = () => {
	const { id } = useParams()
	let [bookingForm, response] = useBookingProductApiMutation();
	const { data, isFetching } = useGetbookingApiByIdQuery(id)
	let { success } = useSelector((state) => state.globalReducer);
	let successResponse = response;
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const { state, onChange } = useForm({
		first_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		address: "",
		instructions: "",
	});
	let setDates = new Date();
	let LastDate = setDates.setDate(setDates.getDate() + 1);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(LastDate);
	const dispatch = useDispatch();
	const [show, setShow] = useState("");
	const [block, setBlock] = useState("none");
	let bookingDetails = data?.response?.data
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	const newStartDates = Moment(startDate).format("YYYY/MM/DD");
	const newEndsDates = Moment(endDate).format("YYYY/MM/DD");
	const BookingFormSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("first_name", state.first_name);
		FormDatas.append("last_name", state.last_name);
		FormDatas.append("booking_id", id);
		FormDatas.append("email", state.email);
		FormDatas.append("contact_no", state.contact_no);
		FormDatas.append("address", state.address);
		FormDatas.append("start_date", newStartDates);
		FormDatas.append("end_date", newEndsDates);
		FormDatas.append("instructions", state.instructions);
		bookingForm(FormDatas);

	};

	const HandleCancel = (e) => {
		window.location.reload();
		setBlock("none");
		setShow("");
		state.first_name = "";
		state.last_name = "";
		state.email = "";
		state.contact_no = "";
		state.address = "";
		state.instructions = "";
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			// state.first_name = "";
			// state.last_name = "";
			// state.email = "";
			// state.contact_no = "";
			// state.address = "";
			// state.instructions = "";
			setShow("show");
			setBlock("block");
		}
	}, [response.isSuccess]);




	let equiplist = [
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
	];

	return (
		<>
			{
				!isFetching ? (
					<>
						<Header />
						{/* car  detal sec starts here */}
						<section className="cardetail-sec">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="img-and-btn-wrapper">
											<div className="slider-wrapper">
												{
													bookingDetails?.images?.length <= 0 ? (<Slider {...settings}>
														<div className="detail-img-wrapper">
															<figure>
																<img src={Noimage} className="img-fluid" style={{ border: '1px solid #0000001c' }} />
																<span className="no">00</span>
															</figure>
														</div>

													</Slider>) : (
														<Slider {...settings}>
															{
																bookingDetails?.images?.map((item, index) => {
																	return (
																		<div className="detail-img-wrapper">
																			<figure>
																				<img src={ImageUrls + item?.image_name} className="img-fluid" />
																				<span className="no">{index + 1 >= 10 ? index + 1 : `0 ${index + 1}`}</span>
																			</figure>
																		</div>
																	)
																})
															}

														</Slider>
													)
												}

											</div>
											<div className="btn-wrapper">
												<a href="/carView">
													<img src={cartimg} />
												</a>
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="detail-content-wrapper">
											<div className="top-btn-wrapper">
												<h4>
													ASKING PRICE: <span className="price">
														{
															currency
																.format(bookingDetails?.price, {
																	code: "USD",
																})
																.split(".00")[0]
														}
													</span>
												</h4>
												{/* <button className="btn">Message Seller</button>
									<button className="btnBlack">Request CAR FAX</button>
									<button
										className="btngrey"
										data-toggle="modal"
										data-target="#battery_health"
									>
										Battery Health Report
									</button> */}
												<button
													data-toggle="modal"
													data-target="#exampleModalCenter"
													className="btn btn22 mr-4"
												>
													Book now
												</button>
												<div
													className="modal fade"
													id="battery_health"
													tabindex="-1"
													role="dialog"
													aria-labelledby="exampleModalCenterTitle"
													aria-hidden="true"
												>
													<div
														className="modal-dialog modal-dialog-centered"
														role="document"
													>
														<div className="modal-content">
															<div className="modal-header">
																<h5
																	className="modal-title"
																	id="exampleModalLongTitle"
																></h5>
																<button
																	type="button"
																	className="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<div className="text-center">
																	<h3>Battery Health Report</h3>
																	<h6>Battery Health 78%</h6>
																</div>
																<div className="row">
																	<div className="col-md-4">&nbsp;</div>
																	<div className="col-md-8"></div>
																</div>
																<div className="row">
																	<div className="col-md-4 ">
																		<div>
																			<p>High Temperatures</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				aria-valuenow="50"
																				aria-valuemin="0"
																				aria-valuemax="100"
																				style={{ width: "50%" }}
																			></div>
																			<span>50%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>Low Temperatures</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				aria-valuenow="70"
																				aria-valuemin="0"
																				aria-valuemax="100"
																				style={{ width: "70%" }}
																			></div>
																			<span>70%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>Fast Charging</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				aria-valuenow="40"
																				aria-valuemin="0"
																				aria-valuemax="100"
																				style={{ width: "40%" }}
																			></div>
																			<span>40%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>Slow Charging</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				aria-valuenow="90"
																				aria-valuemin="0"
																				aria-valuemax="100"
																				style={{ width: "55%" }}
																			></div>
																			<span>55%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>More highway driving</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				style={{ width: "25%" }}
																				aria-valuenow="25"
																				aria-valuemin="0"
																				aria-valuemax="100"
																			></div>
																			<span>25%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>More city driving</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				style={{ width: "50%" }}
																				aria-valuenow="50"
																				aria-valuemin="0"
																				aria-valuemax="100"
																			></div>
																			<span>50%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p> Charging to 100%</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				style={{ width: "75%" }}
																				aria-valuenow="75"
																				aria-valuemin="0"
																				aria-valuemax="100"
																			></div>
																			<span>75%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div>
																			<p>Letting the charge drop to 0%</p>
																		</div>
																	</div>
																	<div className="col-md-8 pl-0 align-self-center">
																		<div className="progress">
																			<div
																				className="progress-bar"
																				role="progressbar"
																				style={{ width: "80%" }}
																				aria-valuenow="100"
																				aria-valuemin="0"
																				aria-valuemax="100"
																			></div>
																			<span>80%</span>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">&nbsp;</div>
																	<div className="col-md-8"></div>
																</div>
																<div className="row">
																	<div className="col-md-4 border-0">
																		<div></div>
																	</div>
																	<div className="col-md-8 ">
																		<div className="progress_values">
																			<p>20%</p>
																			<p>40%</p>
																			<p>60%</p>
																			<p>80%</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="stats-wrapper">
												<h5>
													{bookingDetails?.miles}
													<span className="statsname">Mileage:</span>
												</h5>
												<h5>
													270 Mi
													<span className="statsname">Total Range:</span>
												</h5>
												<h5>
													5<span className="statsname">Seats:</span>
												</h5>
											</div>
											<div className="title-name-wrapper">
												<h2>MINI S E COUNTRYMAN ALL 4 CLASSIC</h2>
												<h5>
													{" "}
													<i className="fa fa-map-marker"></i> {bookingDetails?.location}
												</h5>
											</div>
											<div className="highlights-wrapper">
												<h4>HIGHLIGHTS:</h4>
												<div className="highlights-list-wrapper">
													<ul>
														<li>
															{" "}
															{/* <i className="fa fa-check"></i>  */}
															<img src={meter_img} />
															Park Distance Control
														</li>
														<li>
															{" "}
															{/* <i className="fa fa-check"></i>  */}
															<img src={meter_img} />
															Comfort and Convenience Package
														</li>
														<li>
															{" "}
															<img src={meter_img} />
															{/* <i className="fa fa-check"></i> */}
															Panoramic Sunroof
														</li>
														<li>
															{" "}
															<img src={meter_img} />
															{/* <i className="fa fa-check"></i>  */}
															Back Up Camera
														</li>
														<li>
															<img src={meter_img} />
															{/* <i className="fa fa-check"></i>  */}
															Park Distance Control
														</li>
														<li>
															<img src={meter_img} />
															{/* <i className="fa fa-check"></i>  */}
															AWD
														</li>
													</ul>
												</div>
											</div>
											<div className="seller-snap-wrapper">
												<div className="seller-heading-wrapper">
													<h5>Seller Snapshot :</h5>
												</div>
												<div className="seller-des-wrapper">
													<p>
														Active Driving Assistant, Advanced Real-Time Traffic
														Information, Alarm System, Apple CarPlay Compatibility,
														Brake assist, Comfort Access Keyless Entry, Electronic
														Stability Control, Exterior Parking Camera Rear, Four
														wheel independent suspension, harman/kardon® Logic 7®
														Digital Surround, MINI Driving Modes, MINI Yours Leather
														Steering Wheel, Panoramic Moonroof, Piano Black Interior
														Surface, Power moonroof.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* car  detal sec ends here */}
						{/* car sspecification starts here */}
						<section className="car-specification">
							<div className="container">
								<div className="row">
									<div className="col-lg-4">
										<div className="specs-wrapper">
											<h6>EXTERIOR:</h6>
											<ul>
												<li>
													<span className="property">Body Style</span>
													<span className="value">Hatchback</span>
												</li>
												<li>
													<span className="property">Exterior Color</span>
													<span className="value">Blue</span>
												</li>
												<li>
													<span className="property">Length</span>
													<span className="value">169.8"</span>
												</li>
												<li>
													<span className="property">Wheelbase</span>
													<span className="value">105.1"</span>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="specs-wrapper">
											<h6>INTERIOR:</h6>
											<ul>
												<li>
													<span className="property">Seating</span>
													<span className="value">5</span>
												</li>
												<li>
													<span className="property">Interior Color</span>
													<span className="value">N/A</span>
												</li>
												<li>
													<span className="property">Head Room Front</span>
													<span className="value">39.9"</span>
												</li>
												<li>
													<span className="property">Head Room Rear</span>
													<span className="value">36.3"</span>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="specs-wrapper">
											<h6>PERFORMANCE:</h6>
											<ul>
												<li>
													<span className="property">Total Range</span>
													<span className="value">Hatchback</span>
												</li>
												<li>
													<span className="property">Electric Range</span>
													<span className="value">Blue</span>
												</li>
												<li>
													<span className="property">EPA/Combined MPG</span>
													<span className="value">169.8"</span>
												</li>
												<li>
													<span className="property">0-60</span>
													<span className="value">105.1"</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* car sspecification ends here */}
						{/* equipments sec starts here */}
						<section className="EQUIPMENT-sec">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="equipment-wrapper">
											<div className="equipment-heading-wrapper">
												<h5>EQUIPMENT & OPTIONS:</h5>
											</div>
											<div className="equip-list-wrapper">
												<ul>
													{equiplist.map((data, index) => {
														return (
															<li key={index}>
																<img src={meter_img} /> {data.list_no}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Footer />
						</section>
						{/* equipments sec ends here */}
						<div
							className="modal fade"
							id="exampleModalCenter"
							tabindex="-1"
							role="dialog"
							aria-labelledby="exampleModalCenterTitle"
							aria-hidden="true"
						>
							<div className="modal-dialog modal-dialog-centered" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLongTitle">
											Booking Form
										</h5>
									</div>
									<div className="modal-body">
										<form>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label>Full Name*</label>
														<input
															type="text"
															placeholder="Enter your FullName"
															className="form-control"
															name="first_name"
															value={state.first_name}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Last Name*</label>
														<input
															type="text"
															placeholder="Enter your LastName"
															className="form-control"
															name="last_name"
															value={state.last_name}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Your Email Address*</label>
														<input
															type="email"
															placeholder="Enter your Email Address"
															className="form-control"
															name="email"
															value={state.email}
															onChange={onChange}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Phone Number*</label>
														<input
															type="tel"
															placeholder="Enter your Phone Number"
															className="form-control"
															name="contact_no"
															value={state.contact_no}
															onChange={onChange}
														/>
													</div>
												</div>
												<label>Booking Date*</label>
												<div className="col-md-6">
													<div className="form-group">
														<label>Start Date</label>
														<DatePicker
															selected={startDate}
															selectsStart
															onChange={(date) => setStartDate(date)}
															startDate={startDate}
															endDate={startDate}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>End Date</label>
														<DatePicker
															selected={endDate}
															onChange={(date) => setEndDate(date)}
															selectsEnd
															endDate={endDate}
															minDate={endDate}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Special Instructions*</label>
														<textarea
															className="form_control_text form-control"
															id="exampleFormControlTextarea3"
															rows="7"
															name="instructions"
															value={state.instructions}
															onChange={onChange}
														></textarea>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Address*</label>
														<textarea
															className="form_control_text form-control"
															id="exampleFormControlTextarea3"
															rows="7"
															name="address"
															value={state.address}
															onChange={onChange}
														></textarea>
													</div>
												</div>
											</div>
										</form>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn" data-dismiss="modal">
											Close
										</button>
										<button
											type="button"
											className="btn "
											onClick={BookingFormSubmit}
										>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							className={`modal fade ${show !== "" && show}`}
							id="exampleModalCenter22"
							tabindex="-1"
							role="dialog"
							aria-labelledby="exampleModalCenterTitle22"
							aria-hidden="true"
							style={{
								display: `${block}`,
							}}
						>
							<div
								className="modal-dialog modal-dialog-centered modal_all_container"
								role="document"
							>
								<div className="modal-content">
									<div className="modal_center_success">
										<h5 className="modal-title" id="exampleModalLongTitle22">
											Booking Successfully
										</h5>
										<img src={thanks} alt="" />
									</div>
									<div
										className="position_set_cancel"
										data-dismiss="modal"
										onClick={HandleCancel}
									>
										<ClearIcon />
									</div>

									<div className="form_content_detail">
										<div>
											<p>
												<span>FirstName:</span> {state?.first_name}
											</p>

											<p>
												<span>Email:</span> {state?.email}
											</p>
											<p>
												<span>Phone:</span> {state?.contact_no}
											</p>
											<p>
												<span>Start Date:</span> {newStartDates}
											</p>
											<p>
												<span>End Date:</span> {newEndsDates}
											</p>
										</div>
									</div>
									<div></div>
								</div>
							</div>
						</div>
					</>
				) : <Spinner />
			}
		</>
	);
};

export default BookingDetails;
