import { toast } from "react-toastify";

export const ToastMessage = (success) => {
	let toastMessageShow = 0;
	if (toastMessageShow === 0) {
		toast(success);
		toastMessageShow = 1;
		return;
	}
};
