import React from "react";
import Slider from "react-slick";
import work from "../../Assets/img/work.png";
import work2 from "../../Assets/img/work2.png";
import work3 from "../../Assets/img/work3.png";
import slider from "../../Assets/img/slider.png";
import snap from "../../Assets/img/seller-snapshot.webp";
import coma from "../../Assets/img/coma.png";
import coma2 from "../../Assets/img/coma2.png";
import joh from "../../Assets/img/joh.png";
import banner from "../../Assets/img/banner.png";
import {
    useSiteSettingConfigQuery,
} from "./../../store/services/AllSiteApisService";

import "../../Assets/Css/style.css";
import { useBannersViewsMutation, useGetBannersQuery } from "../../store/services/BannerAdsService";
const GetBanner = () => {
    let { data, isLoading } = useGetBannersQuery();
    let [checkViews, response] = useBannersViewsMutation();
    let get_Banner = data?.response?.data;
    const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
    let ImageUrls = ImageUrl?.uploads;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const HandleCheckView = (e, id) => {
        const FormDatas = new FormData();
        FormDatas.append("banner_id", id);
        checkViews(FormDatas)
    }

    return (
        // <div className="HowItWorks">
        //     <div className="container-fluid for-background">
        //         <div>
        //             <Slider {...settings}>
        //                 {get_Banner?.length >= 1 &&
        //                     get_Banner?.map((item, index) => {
        //                         return (
        //                             <>
        //                                 <div className="slides for1" style={{
        //                                     backgroundImage: `url(${ImageUrls}${item?.banner_image})`,
        //                                     backgroundRepeat: "no-repeat",
        //                                     opacity: "0.5"
        //                                 }} key={index}>
        //                                     <div className="testi">
        //                                         <p className="TestimonialBtn">My Banners</p>
        //                                     </div>
        //                                     <div className="row for-flexing">
        //                                         <div className="col-md-12 p-0">

        //                                             <div className="slider-paras2">
        //                                                 <p>
        //                                                     {item?.description}


        //                                                 </p>
        //                                             </div>
        //                                         </div>

        //                                         {/* <div className="col-md-4 px-0">
        //                                             <div className="sliderx-img">
        //                                                 <figure>
        //                                                     <Slider {...listingImg}>
        //                                                         {item?.images?.map((item) => (
        //                                                             <img src={ImageUrls + item?.image_name}></img>
        //                                                         ))}
        //                                                     </Slider>
        //                                                 </figure>
        //                                             </div>
        //                                         </div> */}
        //                                     </div>
        //                                 </div>
        //                             </>
        //                         );
        //                     })}

        //                 {/* <div className='slides'>
        //   <img src={work2}></img>
        //   </div>
        //   <div className='slides for1'>
        //   <img src={work3}></img>
        //   </div>
        //   <div className='slides for1'>
        //   <img src={work}></img>
        //   </div>
        //   <div className='slides'>
        //   <img src={work2}></img>
        //   </div>
        //   <div className='slides for1'>
        //   <img src={work3}></img>
        //   </div> */}
        //             </Slider>
        //         </div>
        //     </div>
        // </div>
        <>
            {get_Banner?.length >= 1 && (
                <section className="add-banner">
                    <div className="container">
                        <div className="heading-wrapper">
                            <h3>Banner Adds</h3>
                        </div>
                        <div className="banner-slider">
                            <Slider {...settings}>
                                {
                                    get_Banner?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="add-box" key={index}>
                                                    <div className="img-box">
                                                        <figure><img src={`${ImageUrls}${item?.banner_image}`} alt="banner-image" className="img-fluid" /></figure>
                                                        {/* <figure><img src={banner} alt="banner-image" className="img-fluid" /></figure> */}
                                                    </div>
                                                    <div className="content-box">
                                                        <div className="inner-content">
                                                            <p>
                                                                {item?.description}
                                                            </p>
                                                            <div className="button-group">
                                                                <button className="btn" onClick={(e) => { HandleCheckView(e, item?.id) }}>
                                                                    <a href={item?.link} target="_blank" >Learn more</a>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </Slider>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default GetBanner;
{/* <div className="add-box">
                            <div className="img-box">
                                <figure><img src={banner} alt="banner-image" className="img-fluid" /></figure>
                            </div>
                            <div className="content-box">
                                <div className="inner-content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur non quasi doloribus saepe sint magni tempora labore, nulla sunt velit! Expedita modi obcaecati reprehenderit, a quae beatae sed aut dolor?
                                    </p>
                                    <div className="button-group">
                                        <a href="#" className="btn">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}