import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "./../../hooks/Form";
import { useChangePassApiMutation } from "../../store/services/Pages/ProfileService";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { ToastMessage } from "../../utils/ShowToastMessage";

const ChangePassword = () => {
	let [setChangePass, response] = useChangePassApiMutation();
	const [errors, setErrors] = useState([]);
	const { state, onChange, setState } = useForm({
		current_password: "",
		password: "",
		confirm_password: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("current_password", state?.current_password);
		FormDatas.append("password", state?.password);
		FormDatas.append("confirm_password", state?.confirm_password);
		setChangePass(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isError) {
			ToastMessage('Incorrect Old Password')
		}
	}, [response?.error?.data?.errors === "Incorrect Old Password"]);


	console.log(response, ";;;;")

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			state.current_password = "";
			state.password = "";
			state.confirm_password = "";
			setTimeout(() => {
				navigate("/profile");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Change Password</h3>
								</div>
								<div className="editprofile_sec">
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="inputcupassword" className="form-label">
													Current Password
												</label>
												<input
													type="password"
													className={`${errors && errors?.current_password
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="current_password"
													value={state?.current_password}
													onChange={onChange}
													id="inputcupassword"
												/>
											</div>
											{errors && errors?.current_password && (
												<span className="errorMessage">
													<p>{errors && errors?.current_password}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="inputnpassword" className="form-label">
													New Password
												</label>
												<input
													type="password"
													className={`${errors && errors?.password
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="password"
													value={state?.password}
													onChange={onChange}
													id="inputnpassword"
												/>
											</div>
											{errors && errors?.password && (
												<span className="errorMessage">
													<p>{errors && errors?.password}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="inputcpassword" className="form-label">
													Confirm Password
												</label>
												<input
													type="password"
													className={`${errors && errors?.confirm_password
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="confirm_password"
													value={state?.confirm_password}
													onChange={onChange}
													id="inputcpassword"
												/>
											</div>
											{errors && errors?.confirm_password && (
												<span className="errorMessage">
													<p>{errors && errors?.confirm_password}</p>
												</span>
											)}
										</div>
										<div className="col-md-6 align-self-center mt-2">
											<div className="button-group">
												<button onClick={onSubmit} className="btn ">
													Save Changes
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default ChangePassword;
