import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import TopSlider from "../Home/TopSlider";
import Grid from "../../Assets/img/grid.png";
import "../../Assets/Css/listing.css";
import "../../Assets/Css/car-detail.css";
import Slider from "react-slick";

// categories Images
import Cat1 from "../../Assets/img/cat1.png";
import Cat2 from "../../Assets/img/cat2.png";
import Cat3 from "../../Assets/img/cat3.png";
import Cat4 from "../../Assets/img/cat4.png";
import Cat5 from "../../Assets/img/cat5.png";
import Cat6 from "../../Assets/img/cat6.png";
import Cat7 from "../../Assets/img/cat7.png";
import Cat8 from "../../Assets/img/cat9.png";
import Cat9 from "../../Assets/img/cat10.png";
import Cat10 from "../../Assets/img/cat11.png";
import Cat11 from "../../Assets/img/cat12.png";
import Cat12 from "../../Assets/img/cat13.png";
import Cat13 from "../../Assets/img/cat14.png";
import banner from "../../Assets/img/banner.png";
import about1 from "../../Assets/img/about1.png";
import car1 from "../../Assets/img/car1.png";
import car2 from "../../Assets/img/car2.png";
import icon from "../../Assets/img/icon.png";
import service from "../../Assets/img/services1.png";
import services2 from "../../Assets/img/services2.png";
import services3 from "../../Assets/img/services3.png";
import services4 from "../../Assets/img/services4.png";
import feature1 from "../../Assets/img/feature1.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add from "../../Assets/img/add.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import feature5 from "../../Assets/img/feature5.png";
import feature6 from "../../Assets/img/feature6.png";
import { Link, useNavigate } from "react-router-dom";
import {
	useAllVehiclesQuery,
	useExteriorVehicleQuery,
	useFilterVehicleQuery,
	useMakesVehicleQuery,
	useModelsVehicleQuery,
} from "./../../store/services/AllVehiclesService";
import { useForm } from "./../../hooks/Form";
import Spinner from "./../../utils/Loader";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import { useSelector, useDispatch } from "react-redux";
import { clearSearch } from "../../store/reducers/globalReducers";

const Listing = () => {
	const { searchs } = useSelector((state) => state.globalReducer);
	let dispatch = useDispatch();
	let { state, onChange, setState } = useForm({
		radion_type: "all",
		ev_seller: "all",
		model: "",
		makes: "",
		min_price: "",
		max_price: "",
		min_year: "",
		max_year: "",
		min_range: "",
		max_range: "",
		min_mileage: "",
		max_mileage: "",
		min_battery_power: "",
		max_battery_power: "",
		exterior_color: "",
		drive_train: "all",
		search: "",
	});
	const HandleChangers = (e) => {
		let Limit = 4;
		setState({ ...state, min_year: e.target.value?.slice(0, Limit) })
	}
	const HandleChangers2 = (e) => {
		let Limit = 4;
		setState({ ...state, max_year: e.target.value?.slice(0, Limit) })
	}
	const Ev_Type_Handler = (value) => {
		setState({ ...state, radion_type: value });
	};
	const Ev_Seller_Handler = (value) => {
		setState({ ...state, ev_seller: value });
	};
	const setModels = (value) => {
		setState({ ...state, model: value });
	};
	const setMake = (value) => {
		setState({ ...state, makes: value });
	};
	const setColor = (value) => {
		setState({ ...state, exterior_color: value });
	};
	const Drivetrain_Handler = (value) => {
		setState({ ...state, drive_train: value });
	};
	const [pageurl, setPageurl] = useState(null);
	const [keyword, setKey] = useState(null);
	const [checktrue, setCheck] = useState(false);
	let {
		radion_type,
		ev_seller,
		model,
		makes,
		min_price,
		max_price,
		min_year,
		max_year,
		min_range,
		max_range,
		min_mileage,
		max_mileage,
		min_battery_power,
		max_battery_power,
		exterior_color,
		drive_train,
		search,
	} = state;

	let datas = {
		radion_type,
		ev_seller,
		model,
		makes,
		min_price,
		max_price,
		min_year,
		max_year,
		min_range,
		max_range,
		min_mileage,
		max_mileage,
		min_battery_power,
		max_battery_power,
		exterior_color,
		drive_train,
		pageurl,
		search: checktrue ? keyword : searchs,
	};

	const searchData = (e) => {
		e.preventDefault();
		if (search.replace(/\s/g, "")?.length <= 0) {
			return setCheck(false);
		}
		setKey(search);
		setCheck(true);
	};
	const [links, setLink] = useState(null);
	const { data, isLoading } = useAllVehiclesQuery(datas);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const { data: makesFetching, isFetching: makesLoad } = useMakesVehicleQuery();

	const { data: modelsFetching, isFetching: modelsLoad } =
		useModelsVehicleQuery();
	const { data: exteriorFetching, isFetching: exteriorLoad } =
		useExteriorVehicleQuery();
	let categoriesData = data?.response?.data?.data;
	let paginationData = data?.response?.data?.links;
	let ImageUrls = ImageUrl?.uploads;
	let makesData = makesFetching?.response?.data;
	let modelsData = modelsFetching?.response?.data;
	let exteriorData = exteriorFetching?.response?.data;
	const navigate = useNavigate();
	const HandlePageChange = (e, id) => {
		e.preventDefault();
		navigate(`/cardetailpage/${id}`);
	};

	useEffect(() => {
		if (data?.status) {
			setLink(paginationData);
		}
	}, [data?.status]);

	const wordKey = () => {
		if (search.replace(/\s/g, "")?.length <= 0) {
			return setCheck(false);
		}
		setKey(search);
		setCheck(true);
	};

	useEffect(() => {
		wordKey();
	}, [search]);

	useEffect(() => {
		return () => {
			dispatch(clearSearch());
		};
	}, []);

	const PaginationNext = (url) => {
		setPageurl(url.split("?page=")[1]);
	};

	const categories = [
		{
			catImg: Cat1,
			catName: "New",
		},
		{
			catImg: Cat2,
			catName: "Used",
		},
		{
			catImg: Cat3,
			catName: "Electric",
		},
		{
			catImg: Cat4,
			catName: "Hybrid",
		},
		{
			catImg: Cat5,
			catName: "Price",
		},
		{
			catImg: Cat6,
			catName: "Year",
		},
		{
			catImg: Cat7,
			catName: "Range",
		},
		{
			catImg: Cat8,
			catName: "Mileage",
		},
		{
			catImg: Cat9,
			catName: "Battery",
		},
		{
			catImg: Cat10,
			catName: "color",
		},
		{
			catImg: Cat11,
			catName: "Speed",
		},
		{
			catImg: Cat12,
			catName: "Motor",
		},
		{
			catImg: Cat13,
			catName: "Drivetrain",
		},
	];
	const listingImg = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<TopSlider
						searchData={searchData}
						search={search}
						onChange={onChange}
					/>

					{/* Listing Sec Start Here */}
					<section className="lisiting">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<div className="filter">
										<div className="heading">
											<h4>FILTER BY:</h4>
											{/* <p>
		<i className="fa fa-map-marker" aria-hidden="true"></i> Your
		Location
	  </p> */}
										</div>
										<ul className="categories">
											{/* {categories?.map((cat, index) => {
							return (
								<li key={index}>
									<span>{cat?.catName}</span>
									<img src={cat?.catImg} alt="" className="img-fluid" />
								</li>
							);
						})} */}
											{/* <li>
												<div className="dropdown">
													<button
														className="btn btn-secondary dropdown-toggle"
														type="button"
														id="dropdownMenuButton"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Your Location
													</button>
													<div
														className="dropdown-menu"
														aria-labelledby="dropdownMenuButton"
													>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
													</div>
												</div>
												<i className="fa fa-map-marker" aria-hidden="true"></i>
											</li> */}
											<li>
												<div>
													<span>EV Type</span>
													<div
														className="form-check"
														onClick={() => Ev_Type_Handler("all")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="radion_type"
															id="inputall"
															value="all"
															onChange={onChange}
															checked={
																state?.radion_type === "all" && "checked"
															}
														/>
														<label className="form-check-label" htmlFor="inputall">
															All
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Ev_Type_Handler("ev")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="radion_type"
															id="inputev"
															value="ev"
															onChange={onChange}
															checked={state?.radion_type === "ev" && "checked"}
														/>
														<label className="form-check-label" htmlFor="inputev">
															EV
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Ev_Type_Handler("hybrid")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="radion_type"
															id="inputhybrid"
															value="hybrid"
															onChange={onChange}
															checked={
																state?.radion_type === "hybrid" && "checked"
															}
														/>
														<label
															className="form-check-label"
															htmlFor="inputhybrid"
														>
															Hybrid
														</label>
													</div>
												</div>
												<img src={Cat1} alt="" className="img-fluid" />
											</li>
											<li>
												<div>
													<span>EV Seller</span>
													<div
														className="form-check"
														onClick={() => Ev_Seller_Handler("all")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="ev_seller"
															id="inputall2"
															value="all"
															onChange={onChange}
															checked={state?.ev_seller === "all" && "checked"}
														/>
														<label className="form-check-label" for="inputall2">
															All
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Ev_Seller_Handler("dealer")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="ev_seller"
															id="inputdealer"
															value="dealer"
															onChange={onChange}
															checked={
																state?.ev_seller === "dealer" && "checked"
															}
														/>
														<label
															className="form-check-label"
															htmlFor="inputdealer"
														>
															Dealer
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Ev_Seller_Handler("private Seller")}
													>
														<input
															className="form-check-input"
															type="radio"
															name="ev_seller"
															id="inputprisell"
															checked={
																state?.ev_seller === "private Seller" &&
																"checked"
															}
															value="private Seller"
															onChange={onChange}
														/>
														<label
															className="form-check-label"
															htmlFor="inputprisell"
														>
															Private Seller
														</label>
													</div>
												</div>
												<img src={Cat1} alt="" className="img-fluid" />
											</li>
											{/* <li>
												<span>New</span>
												<img src={Cat1} alt="" className="img-fluid" />
											</li>
											<li>
												<span>Used</span>
												<img src={Cat2} alt="" className="img-fluid" />
											</li>
											<li>
												<span>Electric</span>
												<img src={Cat3} alt="" className="img-fluid" />
											</li>
											<li>
												<span>Hybrid</span>
												<img src={Cat4} alt="" className="img-fluid" />
											</li> */}
											<li>
												{/* <span>Make</span> */}
												<div className="dropdown">
													{/* <select
														class="form-control"
														name="carsCate"
														id="cars"
														onChange={onChange}
														value={makesData}
													>
														<option disabled selected>
															Makes
														</option>

														<option value={products?.response?.data?.title}>
															{products?.response?.data?.title}
														</option>
													</select> */}
													<button
														className="btn btn-secondary dropdown-toggle"
														type="button"
														id="dropdownMenuButton"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Make
													</button>
													{/* <div
														className="dropdown-menu"
														aria-labelledby="dropdownMenuButton"
													>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
														<a className="dropdown-item" href="#">
															Lorem
														</a>
													</div> */}
													<div
														className="dropdown-menu"
														aria-labelledby="dropdownMenuButton"
													>
														<ul>
															{makesData?.map((item, index) => {
																return (
																	<li
																		className="dropdown-item"
																		onClick={() => setMake(item)}
																		key={index}
																	>
																		{item}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
												{/* <img src={Cat4} alt="" className="img-fluid" /> */}
											</li>
											<li>
												{/* <span>Make</span> */}
												<div className="dropdown">
													<button
														className="btn btn-secondary dropdown-toggle"
														type="button"
														id="dropdownMenuButton"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Model
													</button>
													<div
														className="dropdown-menu"
														aria-labelledby="dropdownMenuButton"
													>
														<ul>
															{modelsData?.map((item, index) => {
																return (
																	<li
																		className="dropdown-item"
																		onClick={() => setModels(item)}
																		key={index}
																	>
																		{item}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
												{/* <img src={Cat4} alt="" className="img-fluid" /> */}
											</li>
											<li>
												<div>
													<span>Price</span>
													<div className="form-group">
														<input
															type="number"
															className="form-control"
															id="inputprice1"
															value={state?.min_price <= 0
																? ""
																: state?.min_price}
															name="min_price"
															onChange={onChange}
															aria-describedby="emailHelp"
															placeholder="Lowest "
														/>
														<input
															type="number"
															className="form-control"
															id="inputprice1"
															aria-describedby="emailHelp"
															placeholder="Highest "
															name="max_price"
															value={state?.max_price <= 0
																? ""
																: state?.max_price}
															onChange={onChange}
														/>
													</div>
												</div>
												<img src={Cat5} alt="" className="img-fluid" />
											</li>
											<li>
												<div>
													<span>Year</span>
													<div className="form-group">
														<input
															type="number"
															maxLength={4}
															className="form-control"
															id="inputyear1"
															name="min_year"
															value={state?.min_year <= 0
																? ""
																: state?.min_year}
															onChange={HandleChangers}
															aria-describedby="emailHelp"
															placeholder="From"


														/>
														<input
															type="number"
															maxLength={4}
															name="max_year"
															value={state?.max_year <= 0
																? ""
																: state?.max_year}
															onChange={HandleChangers2}
															className="form-control"
															id="inputyear2"
															aria-describedby="emailHelp"
															placeholder="To"

														/>
													</div>
												</div>
												<img src={Cat6} alt="" className="img-fluid" />
											</li>
											<li>
												<div>
													<span>Range</span>
													<div className="form-group">
														<input
															type="number"
															className="form-control"
															id="inputrange1"
															aria-describedby="emailHelp"
															placeholder="Minimum  "
															name="min_range"
															value={state?.min_range <= 0
																? ""
																: state?.min_range}
															onChange={onChange}
														/>
														<input
															type="number"
															className="form-control"
															id="inputrange2"
															aria-describedby="emailHelp"
															placeholder="Maximum  "
															name="max_range"
															value={state?.max_range <= 0
																? ""
																: state?.max_range}
															onChange={onChange}
														/>
													</div>
												</div>
												<img src={Cat7} alt="" className="img-fluid" />
											</li>
											<li>
												<div>
													<span>Mileage</span>
													<div className="form-group">
														<input
															type="number"
															className="form-control"
															id="inputmileage1"
															aria-describedby="emailHelp"
															placeholder="Lowest"
															name="min_mileage"
															value={
																state?.min_mileage <= 0
																	? ""
																	: state?.min_mileage
															}
															onChange={onChange}
														/>
														<input
															type="number"
															className="form-control"
															id="inputmileage2"
															aria-describedby="emailHelp"
															placeholder="Highest"
															name="max_mileage"
															value={
																state?.max_mileage <= 0
																	? ""
																	: state?.max_mileage
															}
															onChange={onChange}
														/>
													</div>
												</div>
												<img src={Cat8} alt="" className="img-fluid" />
											</li>
											<li>
												<div>
													<span>Battery Power</span>
													<div className="form-group">
														<input
															type="number"
															className="form-control"
															id="inputbatpower1"
															aria-describedby="emailHelp"
															placeholder="Min"
															name="min_battery_power"
															value={state?.min_battery_power <= 0
																? ""
																: state?.min_battery_power}
															onChange={onChange}
														/>
														<input
															type="number"
															className="form-control"
															id="inputbatpower2"
															aria-describedby="emailHelp"
															placeholder="Max"
															name="max_battery_power"
															value={state?.max_battery_power <= 0
																? ""
																: state?.max_battery_power}
															onChange={onChange}
														/>
													</div>
												</div>
												<img src={Cat9} alt="" className="img-fluid" />
											</li>
											<li>
												{/* <span>color</span> */}
												<div className="dropdown">
													<button
														className="btn btn-secondary dropdown-toggle"
														type="button"
														id="dropdownMenuButton"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Exterior color
													</button>
													{/* exteriorData */}
													<div
														className="dropdown-menu"
														aria-labelledby="dropdownMenuButton"
													>
														{/* <a className="dropdown-item" href="#">
															Red
														</a>
														<a className="dropdown-item" href="#">
															Black
														</a>
														<a className="dropdown-item" href="#">
															White
														</a> */}
														<ul>
															{exteriorData?.map((item, index) => {
																return (
																	<li
																		className="dropdown-item"
																		onClick={() => setColor(item)}
																		key={index}
																	>
																		{item}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
												<img src={Cat10} alt="" className="img-fluid" />
											</li>
											{/* <li>
												<span>Speed</span>
												<img src={Cat11} alt="" className="img-fluid" />
											</li>
											<li>
												<span>Motor</span>
												<img src={Cat12} alt="" className="img-fluid" />
											</li> */}
											<li>
												<div>
													<span>Drivetrain</span>

													<div
														className="form-check"
														onClick={() => Drivetrain_Handler("all")}
													>
														<input
															className="form-check-input"
															type="radio"
															id="inputall3"
															value="all"
															onChange={onChange}
															name="drive_train"
															checked={
																state?.drive_train === "all" && "checked"
															}
														/>
														<label className="form-check-label" htmlFor="inputall3">
															All
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Drivetrain_Handler("rwd")}
													>
														<input
															className="form-check-input"
															type="radio"
															value="rwd"
															onChange={onChange}
															name="drive_train"
															checked={
																state?.drive_train === "rwd" && "checked"
															}
															id="inputrwd"
														/>
														<label className="form-check-label" htmlFor="inputrwd">
															RWD
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Drivetrain_Handler("fwd")}
													>
														<input
															className="form-check-input"
															type="radio"
															value="fwd"
															onChange={onChange}
															name="drive_train"
															checked={
																state?.drive_train === "fwd" && "checked"
															}
															id="inputfwd"
														/>
														<label className="form-check-label" htmlFor="inputfwd">
															FWD
														</label>
													</div>
													<div
														className="form-check"
														onClick={() => Drivetrain_Handler("fwd")}
													>
														<input
															className="form-check-input"
															type="radio"
															value="awd"
															onChange={onChange}
															name="drive_train"
															checked={
																state?.drive_train === "awd" && "checked"
															}
															id="inputawd"
														/>
														<label className="form-check-label" htmlFor="inputawd">
															AWD
														</label>
													</div>
												</div>
												<img src={Cat13} alt="" className="img-fluid" />
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-9">
									{/* <div className="view">
										<div className="form-group">
											<label>List view</label>
											<img src={Grid} className="img-fluid" />
										</div>
									</div> */}
									{/* Featured section  */}

									<section className="feature-sec mt-4">
										<div className="container">
											<div className="row wow animate__animated animate__slideInLeft">
												{categoriesData?.map((items, index) => {
													return (
														<>
															<div className="col-md-4" key={items?.id}>
																<div className="featured-all">
																	<div className="f-img">
																		<Link to={`/cardetailpage/${items?.id}`}>
																			<figure>
																				<Slider {...listingImg}>
																					{items?.images?.map((item, index) => {
																						return index < 4 ? (
																							<img
																								src={
																									ImageUrls + item?.image_name
																								}
																							></img>
																						) : null;
																					})}
																				</Slider>
																			</figure>
																		</Link>
																	</div>
																	<div className="feature-head">
																		<h2>
																			{items?.price &&
																				currency
																					.format(items?.price, {
																						code: "USD",
																					})
																					.split(".")[0]}
																		</h2>
																	</div>
																	<div className="feature-normal-head card_head">
																		<h3>{items?.title}</h3>
																	</div>

																	<div className="feature-icon-head">
																		<span>
																			<img src={meter1}></img> {items?.mileage}{" "}
																			miles
																		</span>
																		<span>
																			<img src={meter2}></img> {items?.range}{" "}
																			max range
																		</span>
																	</div>
																	<div className="feature-iiimmgg">
																		<span>
																			<img src={meter3}></img> {items?.city},
																			{items?.location}
																		</span>
																	</div>

																	<div className="checkup">
																		<button
																			className="btn"
																			onClick={(e) =>
																				HandlePageChange(e, items?.id)
																			}
																		>
																			View more
																		</button>
																	</div>
																</div>
															</div>
														</>
													);
												})}
											</div>
										</div>
									</section>
									{categoriesData?.length <= 0 && (
										<h2
											style={{
												display: "flex",
												justifyContent: "center",
												margin: "10px",
											}}
										>
											Vehicles Not Found
										</h2>
									)}
									{
										<div className="pagination">
											<div className="pageCount">
												{links?.map((item, index) => {
													if (item?.label === "&laquo; Previous") {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																Previous
															</button>
														);
													} else if (item?.label === "Next &raquo;") {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																Next
															</button>
														);
													} else {
														return (
															<button
																key={index + 100}
																onClick={() => {
																	PaginationNext(item?.url);
																}}
																disabled={item?.url === null ? true : false}
																className={
																	item?.active
																		? "btn btn-light bt-pagi atcvv_btnz"
																		: "btn btn-light bt-pagi"
																}
															>
																{item?.label}
															</button>
														);
													}
												})}
											</div>
										</div>
									}

									{/* End of Featured section  */}
								</div>
							</div>
						</div>
					</section>
					{/* Listing Sec End Here */}

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Listing;
{
	/* <button className="btn3">1</button>
											<button className="btn1">2</button>
											<button className="btn1">3</button>
											<button className="btn1">4</button>
											<button className="btn1">5</button>
											<button className="btn1">10</button>
											<button className="btn2">
												Next{" "}
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</button>
											<i className="bi bi-chevron-right"></i> */
}
