import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import CarBanner from "../../Assets/img/dealer.png";
import listImg from "../../Assets/img/electricity.webp";
import "../../Assets/Css/dealer.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { useForm } from "./../../hooks/Form";
import { ToastMessage } from "../../utils/ShowToastMessage";
import {
	useDealerApiMutation,
	useSiteSettingApiQuery,
} from "../../store/services/AllSiteApisService";
import SmallLoader from "../../Assets/img/small-Spinner.gif"

const DealerForm = () => {
	let [sendDealerData, response] = useDealerApiMutation();
	let { data, isLoading } = useSiteSettingApiQuery();
	let FooterData = data?.response?.data;
	const [errors, setErrors] = useState([]);
	const { state, onChange } = useForm({
		first_name: "",
		last_name: "",
		dealership: "",
		email: "",
		contact_no: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("first_name", state.first_name);
		FormDatas.append("last_name", state.last_name);
		FormDatas.append("dealership", state.dealership);
		FormDatas.append("email", state.email);
		FormDatas.append("contact_no", state.contact_no);
		sendDealerData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			state.first_name = "";
			state.last_name = "";
			state.email = "";
			state.contact_no = "";
			state.dealership = "";
			setTimeout(() => {
				navigate("/");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Inner Banner Sec Start Here */}
			<section className="InnerBanner">
				<div className="container-fluid p-0">
					<figure>
						<img src={CarBanner} className="img-fluid" />
					</figure>
				</div>
			</section>
			{/* Inner Banner Sec Start Here */}

			{/* Dealer Banner Sec Start Here */}
			<section className="dealer">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 set_Images">
							{
								!isLoading ? (
									<p
										className="card-text"
										dangerouslySetInnerHTML={{
											__html: FooterData?.[0]?.become_a_dealer_content,
										}}
									></p>
								) : <img src={SmallLoader} alt="Loader" />
							}

						</div>
						<div className="col-lg-6">
							<div className="dealer-form">
								<form onSubmit={onSubmit}>
									<div className="form-group">
										<input
											className={`${errors && errors?.dealership
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="dealership"
											value={state.dealership}
											onChange={onChange}
											placeholder="Dealership"
											type="text"
										/>
									</div>
									{errors && errors?.dealership && (
										<span className="errorMessage">
											<p>{errors && errors?.dealership}</p>
										</span>
									)}
									<div className="form-group">
										<input
											className={`${errors && errors?.first_name
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="first_name"
											value={state.first_name}
											onChange={onChange}
											placeholder="First name"
											type="text"
										/>
									</div>
									{errors && errors?.first_name && (
										<span className="errorMessage">
											<p>{errors && errors?.first_name}</p>
										</span>
									)}
									<div className="form-group">
										<input
											className={`${errors && errors?.last_name
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="last_name"
											value={state.last_name}
											onChange={onChange}
											placeholder="Last name"
											type="text"
										/>
									</div>
									{errors && errors?.last_name && (
										<span className="errorMessage">
											<p>{errors && errors?.last_name}</p>
										</span>
									)}
									<div className="form-group">
										<input
											className={`${errors && errors?.email
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="email"
											value={state.email}
											onChange={onChange}
											placeholder="Email Address "
											type="email"
										/>
									</div>
									{errors && errors?.email && (
										<span className="errorMessage">
											<p>{errors && errors?.email}</p>
										</span>
									)}
									<div className="form-group">
										<input
											className={`${errors && errors?.contact_no
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="contact_no"
											value={state.contact_no}
											onChange={onChange}
											placeholder="Contact Number"
											type="text"
										/>
									</div>
									{errors && errors?.contact_no && (
										<span className="errorMessage">
											<p>{errors && errors?.contact_no}</p>
										</span>
									)}
									{/* <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Password"
                      type="password"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Confirm Password"
                      type="password"
                    />
                  </div> */}
									<div className="form-group checkk22">
										<input
											className="form-check-input"
											type="checkbox"
											id="user"
										/>
										<label htmlFor="user">
											As a registered user we will email you with relevant
											information. Untick to opt out.
										</label>
									</div>
									<div className="button-group">
										<button className="btn" type="submit">
											{response?.isLoading ? "Loading...." : "Submit"}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Dealer Banner Sec End Here */}

			<Footer />
		</>
	);
};

export default DealerForm;
