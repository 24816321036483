import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import Switch from "react-switch";
import { useForm } from "./../../hooks/Form";
import {
	useAddVehicleMutation,
	useEditVehicleMutation,
	useGetbuyIdAdsQuery,
} from "../../store/services/AdsService";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	useVehicleCateQuery,
	useVehicleTypeQuery,
} from "../../store/services/VechiclesCategoryService";
import { RiCloseCircleLine } from "react-icons/ri";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { ToastMessage } from "../../utils/ShowToastMessage";
import axios from "axios";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
const PostVehicleAds = () => {
	let { id } = useParams();
	let { success } = useSelector((state) => state.globalReducer);
	const { data: loadingDataVehicles, isLoading } = useGetbuyIdAdsQuery(id);
	const [state, setState] = useState(null);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	let editVehicleData = loadingDataVehicles?.response?.data


	useEffect(() => {
		setState(editVehicleData);
	}, [editVehicleData]);

	const onChange = (e) => {
		let { name, value } = e.target;
		setState({ ...state, [name]: value });
	};


	const [multiVideosFiles, setMultiVideosFiles] = useState([]);
	const [singleImage, setSingleImage] = useState()
	const [uploadVideo, setUploadVideo] = useState(null);
	// console.log(multiVideosFiles, "LL:L:L:L");
	let [requiredImage, setRequiredImage] = useState({
		requiredImg: [],
	});
	let [AddinalImage, setAddinalImage] = useState({
		AddinalImg: [],
	});


	const [is_featured, setIsfeatured] = useState(false);
	const [is_new, setIsnew] = useState(false);
	const [is_trending, setIstrending] = useState(false);

	const handleChangeImages = (file) => {
		setState({ ...state, image: file });
		setSingleImage(URL.createObjectURL(file))
	};

	const handleChangeVideo = (file) => {
		setState({ ...state, video: file });
	};

	const handleAddinalFirst = (file) => {
		// console.log(file, "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
		setState({ ...state, passenger_door_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 1);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 1, name: "passenger_door_image" },
			],
		});
	};
	const handleAddinalSecond = (file) => {
		setState({ ...state, front_and_rear_seats_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 2);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{
					imageUrl: file,
					id: 2,
					name: "front_and_rear_seats_image",
				},
			],
		});
	};
	const handleAddinalThird = (file) => {
		setState({ ...state, guages_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 3);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 3, name: "guages_image" },
			],
		});
	};
	const handleAddinalFourth = (file) => {
		setState({ ...state, detail_short_grille_emblem_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 4);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{
					imageUrl: file,
					id: 4,
					name: "detail_short_grille_emblem_image",
				},
			],
		});
	};
	const handleAddinalFive = (file) => {
		setState({ ...state, grille_emblem_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 5);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 5, name: "grille_emblem_image" },
			],
		});
	};
	const handleAddinalSix = (file) => {
		setState({ ...state, head_ligth_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 6);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 6, name: "head_ligth_image" },
			],
		});
	};
	const handleAddinalSeven = (file) => {
		setState({ ...state, shifter_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 7);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 7, name: "shifter_image" },
			],
		});
	};
	const handleAddinalEight = (file) => {
		setState({ ...state, side_view_mirror_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 8);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 8, name: "side_view_mirror_image" },
			],
		});
	};
	const handleAddinalNine = (file) => {
		setState({ ...state, rear_apron_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 9);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 9, name: "rear_apron_image" },
			],
		});
	};
	const handleAddinalTen = (file) => {
		setState({ ...state, wheels_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 10);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 10, name: "wheels_image" },
			],
		});
	};
	const handleAddinalEleven = (file) => {
		setState({ ...state, other_image: URL.createObjectURL(file) });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 11);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 11, name: "other_image" },
			],
		});
	};

	const handleRequriedFirst = (file, arr_num) => {
		// console.log(arr_num, "mmmmmmmmmmmmmmmmmmmm");

		setState({ ...state, front_3_4_left_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 1);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 1, name: "front_3_4_left_image" },
			],
		});
	};

	const handleRequriedSec = (file) => {
		setState({ ...state, front_3_4_right_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 2);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 2, name: "front_3_4_right_image" },
			],
		});
	};

	const handleRequriedThird = (file) => {
		setState({ ...state, rear_3_4_left_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 3);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 3, name: "rear_3_4_left_image" },
			],
		});
	};

	const handleRequriedFourth = (file) => {
		setState({ ...state, head_on_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 4);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 4, name: "head_on_image" },
			],
		});
	};

	const handleRequriedFive = (file) => {
		setState({ ...state, rear_on_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 5);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 5, name: "rear_on_image" },
			],
		});
	};

	const handleRequriedsix = (file) => {
		setState({ ...state, right_side_image: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 6);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 6, name: "right_side_image" },
			],
		});
	};

	const handleVideoChange = (e) => {
		setUploadVideo(e.target.files[0]);
	};




	const RemoveMultiVideos = (e) => {
		e.preventDefault();
		setUploadVideo();
	};



	const renderVideos = () => {
		return (
			<>
				{uploadVideo && (
					<li style={{ listStyle: "none", position: "relative" }}>
						<video width="320" height="240" controls>
							<source src={URL.createObjectURL(uploadVideo)} type="video/mp4" />
						</video>
						<RiCloseCircleLine
							style={{
								position: "absolute",
								cursor: "pointer",
								top: 0,
								right: -170,
							}}
							onClick={RemoveMultiVideos}
						/>
					</li>
				)}
			</>
		);
	};

	const handleChange1 = () => {
		setIsfeatured(!is_featured);
	};

	const handleChange2 = () => {
		setIsnew(!is_new);
	};
	const handleChange3 = () => {
		setIstrending(!is_trending);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [userSendData, response] = useEditVehicleMutation();

	const { data, isFetching } = useVehicleCateQuery();
	const { data: vehicleType, isFetching: load } = useVehicleTypeQuery();
	let v_Categories = data?.response?.data;
	let v_Type = vehicleType?.response?.data;
	const label = { inputProps: { "aria-label": "Switch demo" } };
	const fileTypes = ["JPG", "PNG", "GIF"];
	const videdTypes = ["JPG", "PNG", "GIF"];
	const videoTypes = ["mp4"];
	const [file, setFile] = useState(null);
	const [allImages, setAllImages] = useState([]);
	const handleChange = (file) => {
		setFile(file);
	};



	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		let FormDatas = new FormData();

		FormDatas.append("category", state?.vehicle_category_id);
		FormDatas.append("title", state?.title);
		FormDatas.append("ev_type", state?.vehicle_type_id);
		FormDatas.append("city", state?.city);
		FormDatas.append("state", state?.statess);
		FormDatas.append("vin", state?.vin);
		FormDatas.append("range", state?.range);
		FormDatas.append("location", state?.location);
		FormDatas.append("mileage", state?.mileage);
		FormDatas.append("year", state?.year);
		FormDatas.append("make", state?.make);
		FormDatas.append("model", state?.model);
		FormDatas.append("trim", state?.trim);
		FormDatas.append("battery_power", state?.battery_power);
		FormDatas.append("exterior_color", state?.exterior_color);
		FormDatas.append("drive_train", state?.drive_train);
		FormDatas.append("image", state?.image);
		FormDatas.append("seller_snapshots", state?.seller_snapshots);
		FormDatas.append("price", state?.price);
		FormDatas.append("video", state?.uploadVideo);
		FormDatas.append("is_featured", state?.is_featured === true ? 1 : 0);
		FormDatas.append("is_new", state?.is_new === true ? 1 : 0);
		FormDatas.append("is_trending", state?.is_trending === true ? 1 : 0);
		requiredImage?.requiredImg?.forEach((image) => {
			FormDatas.append(`required_images[${image?.name}]`, image?.imageUrl);
		});
		AddinalImage?.AddinalImg?.forEach((image) => {
			FormDatas.append(`additional_images[${image?.name}]`, image?.imageUrl);
		});
		userSendData({ file: FormDatas, id });
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					ToastMessage(response?.error?.data?.errors[key][0]);
				}
			}
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
		}
	}, [response.isSuccess]);


	return (
		<>
			<Header />
			{/* PostVehicle starts here */}
			<section className="PostVehicle">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h4>Post Vehicle Ads</h4>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="/PostSnap">
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Location"
											className="form-control"
											name="location"
											value={state?.location}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Mileage"
											className="form-control"
											name="mileage"
											value={state?.mileage}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Year"
											className="form-control"
											name="year"
											value={state?.year}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Make"
											className="form-control"
											name="make"
											value={state?.make}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Model"
											className="form-control"
											name="model"
											value={state?.model}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Trim"
											className="form-control"
											name="trim"
											value={state?.trim}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Battery_power"
											className="form-control"
											name="battery_power"
											value={state?.battery_power}
											onChange={onChange}
										/>
									</div>
									<div className="select_box11 col-lg-6">
										<select
											className="form-control first_select"
											id="cars"
											name="category"
											value={state?.vehicle_category_id}
											onChange={onChange}
										>
											{v_Categories?.map((item, index) => {
												return (
													<option key={index + 1} value={item?.id}>
														{item?.name}
													</option>
												);
											})}
										</select>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="title"
											className="form-control"
											name="title"
											value={state?.title}
											onChange={onChange}
										/>
									</div>
									<div className="select_box11 col-lg-6">
										<select
											className="form-control first_select"
											id="cars"
											name="vehicle_type_id"
											value={state?.vehicle_type_id}
											onChange={onChange}
										>
											{v_Type?.map((item, index) => {
												return (
													<option key={index + 1} value={item?.id}>
														{item?.name}
													</option>
												);
											})}
										</select>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="City"
											className="form-control"
											name="city"
											value={state?.city}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="state"
											className="form-control"
											name="statess"
											value={state?.state}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="range"
											className="form-control"
											name="range"
											value={state?.range}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="exterior_color"
											className="form-control"
											name="exterior_color"
											value={state?.exterior_color}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="drive_train"
											className="form-control"
											name="drive_train"
											value={state?.drive_train}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Vin Number"
											className="form-control"
											name="vin"
											value={state?.vin}
											onChange={onChange}
										/>
									</div>
									<div className="row">
										<div className="col-lg-4">
											<Switch
												checked={is_featured}
												name="is_featured"
												onChange={handleChange1}
											/>
											<p>Is featured</p>
										</div>

										<div className="col-lg-4">
											<Switch
												checked={is_new}
												name="is_new"
												onChange={handleChange2}
											/>
											<p>Is new</p>
										</div>
										<div className="col-lg-4">
											<Switch
												checked={is_trending}
												name="is_trending"
												onChange={handleChange3}
											/>
											<p>Is trending</p>
										</div>
										{/* <div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_featured}
															name="is_featured"
															inputProps={{ "aria-label": "controlled" }}
															onChange={handleChange1}
														/>
													}
													label="Is featured"
												/>
											</FormGroup>
										</div>
										<div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_new}
															name="is_new"
															onChange={handleChange2}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label="Is new"
												/>
											</FormGroup>
										</div>
										<div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_trending}
															name="is_trending"
															onChange={handleChange3}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label="Is trending"
												/>
											</FormGroup>
										</div> */}
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-12">
										<div className="form-heading-wrapper">
											<h5>
												Share your best stories and experiences with this EV:
											</h5>
										</div>
									</div>
									<div className="col-lg-12">
										<textarea
											name="seller_snapshots"
											value={state?.seller_snapshots}
											onChange={onChange}
											className="form-control"
											id=""
											cols="30"
											rows="5"
											placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
										></textarea>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Main Image</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!singleImage && state?.image ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[0]?.image_name}`}
												/>
											) : <img src={singleImage} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleChangeImages}
												accept="image/*"
												name="image"
												types={videdTypes}
												label=""
											/>
											<p>

												<label htmlFor="">Main Image</label>
											</p>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Required 6 images:</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.front_3_4_left_image && state?.images?.[0] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[0]?.image_name}`}
												/>
											) : <img src={state?.front_3_4_left_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={(e) => {
													handleRequriedFirst(e, "1");
												}}
												name="front_3_4_left_image"
												types={videdTypes}
												label=""
											/>
											<p>

												<label htmlFor="">Font ¾ left</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.front_3_4_right_image && state?.images?.[1] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[1]?.image_name}`}
												/>
											) : <img src={state?.front_3_4_right_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleRequriedSec}
												name="front_3_4_right_image"
												types={videdTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Font ¾ right </label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.rear_3_4_left_image && state?.images?.[2] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[2]?.image_name}`}
												/>
											) : <img src={state?.rear_3_4_left_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleRequriedThird}
												name="rear_3_4_left_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor=""> Rear ¾ left</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.head_on_image && state?.images?.[3] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[3]?.image_name}`}
												/>
											) : <img src={state?.head_on_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleRequriedFourth}
												name="head_on_image"
												types={fileTypes}
												label=""
											/>
											<p>

												<label htmlFor="">Rear on</label>

											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.rear_on_image && state?.images?.[4] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[4]?.image_name}`}
												/>
											) : <img src={state?.rear_on_image} alt="" srcset="" className="img-fluid" />}

											<FileUploader
												handleChange={handleRequriedFive}
												name="rear_on_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor=""> Through passenger door</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.right_side_image && state?.images?.[5] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[5]?.image_name}`}
												/>
											) : <img src={state?.right_side_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleRequriedsix}
												name="right_side_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Head on</label>
											</p>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<div className="form-heading-wrapper">
										<h5>Required Video:</h5>
									</div>

									<div className="col-lg-6 video-div">
										<div className="file-wrapper">
											<input
												type="file"
												accept="video/*"
												name="video"
												onChange={handleVideoChange}
												className="form-control file-opc"
												id="fileUpload"
											// hidden
											/>
										</div>
										{renderVideos()}
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Additional Images</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.passenger_door_image && state?.images?.[6] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[6]?.image_name}`}
												/>
											) : <img src={state?.passenger_door_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalFirst}
												name="passenger_door_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Through Passenger Door</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.other_image && state?.images?.[7] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[7]?.image_name}`}
												/>
											) : <img src={state?.other_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalEleven}
												name="other_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Head On</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.front_and_rear_seats_image && state?.images?.[8] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[8]?.image_name}`}
												/>
											) : <img src={state?.front_and_rear_seats_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalSecond}
												name="front_and_rear_seats_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Front And Rear Seats</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.guages_image && state?.images?.[8] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[8]?.image_name}`}
												/>
											) : <img src={state?.guages_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalThird}
												name="guages_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor=""> Gauges</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.detail_short_grille_emblem_image && state?.images?.[9] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[9]?.image_name}`}
												/>
											) : <img src={state?.detail_short_grille_emblem_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalFourth}
												name="detail_short_grille_emblem_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">
													Detail Shots Grille And Emblem
												</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.grille_emblem_image && state?.images?.[10] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[10]?.image_name}`}
												/>
											) : <img src={state?.grille_emblem_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalFive}
												name="grille_emblem_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Grille And Emblem</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.head_ligth_image && state?.images?.[11] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[11]?.image_name}`}
												/>
											) : <img src={state?.head_ligth_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalSix}
												name="head_ligth_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Headlight</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.shifter_image && state?.images?.[12] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[12]?.image_name}`}
												/>
											) : <img src={state?.shifter_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalSeven}
												name="shifter_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Shifter</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.side_view_mirror_image && state?.images?.[13] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[13]?.image_name}`}
												/>
											) : <img src={state?.side_view_mirror_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalEight}
												name="side_view_mirror_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Side View Mirror</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.rear_apron_image && state?.images?.[14] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[14]?.image_name}`}
												/>
											) : <img src={state?.rear_apron_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalNine}
												name="rear_apron_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Rear Apron</label>
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											{!state?.wheels_image && state?.images?.[15] ? (
												<img
													className="img-fluid-my-class-set"
													src={`${ImageUrls}${state?.images?.[15]?.image_name}`}
												/>
											) : <img src={state?.wheels_image} alt="" srcset="" className="img-fluid" />}
											<FileUploader
												handleChange={handleAddinalTen}
												name="wheels_image"
												types={fileTypes}
												label=""
											/>
											<p>
												<label htmlFor="">Wheels</label>
											</p>
										</div>
									</div>
								</div>
								<div className="form-group mb-0">
									{/* <div className="form-heading-wrapper">
										<h5>Sale Options</h5>
									</div> */}
									<div className="form-heading-wrapper">
										<h5>Add Price</h5>
									</div>
								</div>
								{/* <div className="form-group  d-flex">
									<div onClick={handlecheckfalse} className="form-check mr-2">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios1"
											value="option1"
										/>
										<label className="form-check-label" for="exampleRadios1">
											For Normal Sell
										</label>
									</div>
									<div onClick={handlecheck} className="form-check ml-2">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios2"
											value="option2"
										/>
										<label className="form-check-label" for="exampleRadios2">
											For Auction
										</label>
									</div>
								</div> */}
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="price"
											className="form-control"
											name="price"
											value={state?.price}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										{radiocheck ? (
											<input
												type="text"
												className="form-control"
												placeholder="Due date for bidding"
											/>
										) : null}
									</div>
								</div>
								<div className="form-group mt-3">
									<button className="btn" onClick={onSubmit}>
										Submit
									</button>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* PostVehicle ends here */}
			<Footer />
		</>
	);
};

export default PostVehicleAds;
