import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HomeService = createApi({
	reducerPath: "HomeService",
	baseQuery: fetchBaseQuery({
		baseUrl:
			"https://allvehicles-backend.developer-ourbase-camp.com/api/pages/",
	}),
	endpoints: (builder) => {
		return {
			homeApi: builder.query({
				query: () => {
					return {
						url: "home",
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useHomeApiQuery } = HomeService;
export default HomeService;
