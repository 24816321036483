import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useUserForgetPassMutation } from "../../store/services/AuthService";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../hooks/Form";

const EnterEmail = () => {
	const [errors, setErrors] = useState([]);
	const { state, onChange } = useForm({
		email: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { success } = useSelector((state) => state.globalReducer);

	const [userForgetData, response] = useUserForgetPassMutation();
	const onSubmit = (e) => {
		e.preventDefault();
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				state.email
			)
		) {
			ToastMessage("Invalid Email");
			return;
		}
		const FormDatas = new FormData();
		FormDatas.append("email", state.email);
		userForgetData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (
			response.isError &&
			response?.error?.data?.message === "Email not found!"
		) {
			ToastMessage(response?.error?.data?.message);
		} else {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			sessionStorage.setItem("email", state.email);
			state.email = "";
			setTimeout(() => {
				navigate("/verification");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Enter Email</h2>
									<p>We will sent you an OTP on your email.</p>
								</div>
								<form onSubmit={onSubmit}>
									<label className="title" for="">
										Please enter email
									</label>
									<div className="form-group">
										<input
											className={`${errors && errors?.email
												? "SetErrorshow form-control"
												: "form-control"
												}`}
											name="email"
											value={state.email}
											onChange={onChange}
											type="email"
											placeholder="Enter Your Email"
										/>
									</div>
									{errors && errors?.email && (
										<span className="errorMessage">
											<p>{errors && errors?.email}</p>
										</span>
									)}
									<div className="button-group">
										<button type="submit">
											{response?.isLoading ? "Loading..." : "Next"}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default EnterEmail;
