import React, { useState } from 'react'
import {
    useFeturedPaymentSubcriptionQuery,
    useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import { tabsImg } from "../../constant";
import { useNavigate } from 'react-router-dom';
import { ToastMessage } from "../../utils/ShowToastMessage";

function FeturedPaymentModal({ showModal, setFetured }) {
    const { data: PaymentUrl, isFetching: PaymentLoad } =
        useFeturedPaymentSubcriptionQuery();
    let feturedPayment = PaymentUrl?.response?.data;
    const navigate = useNavigate();
    const [setVehicle, SetVehicleId] = useState();
    const [setValue, SetValueChange] = useState();
    const [banner_ad_setting_id, Setbanner_ad_setting_id] = useState();

    const OnChangerhandler = (e) => {
        SetValueChange(e.target.value);
    };




    const HandleFetured = () => {
        if (banner_ad_setting_id !== undefined) {
            navigate("/feturedpaymentpage", {
                state: {
                    vehicle_id: setFetured,
                    banner_ad_setting_id: banner_ad_setting_id,
                },
            });
        } else {
            ToastMessage("Please Select Fetured Day Pakage");
        }
    };

    return (
        <div className="post-add-modal">
            <div
                className={`modal  fade ${showModal}`}
                id="postAdd"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <img src={tabsImg} className="img-fluid" alt="" />
                                <span>Go Feature Sale Faster</span>
                                <img src={tabsImg} className="img-fluid" alt="" />
                            </h5>
                        </div>
                        <div className="modal-body">
                            <ul>
                                {feturedPayment?.length > 0 &&
                                    feturedPayment?.map((item, index) => {
                                        return (
                                            <>
                                                <li key={index}>
                                                    <span>
                                                        <label
                                                            className="price_container"
                                                            onClick={() =>
                                                                Setbanner_ad_setting_id(item?.id)
                                                            }
                                                        >
                                                            <input
                                                                type="radio"
                                                                hidden
                                                                name="setValue"
                                                                value={`${item?.id}`}
                                                                onChange={OnChangerhandler}
                                                            />
                                                            <span className="checkmark">
                                                                <span className="dot"></span>
                                                                <ruby>Go Feature {item?.days} Days</ruby>
                                                            </span>
                                                        </label>
                                                    </span>
                                                    <span className="price">
                                                        {item?.price &&
                                                            currency
                                                                .format(item?.price, {
                                                                    code: "USD",
                                                                })
                                                                .split(".")[0]}
                                                    </span>
                                                </li>
                                            </>
                                        );
                                    })}
                            </ul>
                            <div className="button-group">
                                <button
                                    className="btn"
                                    onClick={HandleFetured}
                                    data-dismiss="modal"
                                    data-bs-dismiss="modal"
                                >
                                    Go Featured
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeturedPaymentModal