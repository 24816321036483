import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/Form";
import { useDispatch, useSelector } from "react-redux";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { setSuccess, clearMessage } from "../../store/reducers/globalReducers";
import { useUserRegisterMutation } from "../../store/services/AuthService";

const Register = () => {
	const [errors, setErrors] = useState([]);
	const [term, setTerm] = useState(false);
	const { state, onChange } = useForm({
		first_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		password: "",
		confirm_password: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);

	const [userData, response] = useUserRegisterMutation();


	const onSubmit = (e) => {
		e.preventDefault();

		if (
			!state.first_name ||
			!state.last_name ||
			!state.email ||
			!state.contact_no ||
			!state.password ||
			!state.confirm_password
		) {
			ToastMessage("Please Enter All Field");
			return;
		}

		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				state.email
			)
		) {
			ToastMessage("Invalid Email");
			return;
		}
		if (term === false) {
			return ToastMessage("Please Accept Register Terms")
		}
		const FormDatas = new FormData();
		FormDatas.append("first_name", state.first_name);
		FormDatas.append("last_name", state.last_name);
		FormDatas.append("email", state.email);
		FormDatas.append("contact_no", state.contact_no);
		FormDatas.append("password", state.password);
		FormDatas.append("confirm_password", state.confirm_password);
		userData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			state.first_name = "";
			state.last_name = "";
			state.email = "";
			state.contact_no = "";
			state.password = "";
			state.confirm_password = "";
			setTimeout(() => {
				navigate("/login");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login register">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="login_box">
								<div className="heading registertxt">
									<h2>Register For Free</h2>
									<p>
										Already have an account ?{" "}
										<Link to="/Login">Sign In</Link>
									</p>
								</div>
								<form onSubmit={onSubmit}>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													placeholder="First Name"
													className={`${errors && errors?.first_name
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="first_name"
													maxLength={20}
													value={state.first_name}
													onChange={onChange}
												/>
											</div>
											{errors && errors?.first_name && (
												<span className="errorMessage">
													<p>{errors && errors?.first_name}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													placeholder="Last Name"
													className={`${errors && errors?.last_name
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="last_name"
													value={state.last_name}
													onChange={onChange}
													maxLength={20}
												/>
											</div>
											{errors && errors?.last_name && (
												<span className="errorMessage">
													<p>{errors && errors?.last_name}</p>
												</span>
											)}
										</div>

										{/* <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="User Name"
                        />
                      </div>
                    </div> */}
										{/* <div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Username"
												/>
											</div>
										</div> */}
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="email"
													placeholder="Email Address "
													name="email"
													value={state.email}
													onChange={onChange}
													className={`${errors && errors?.email
														? "SetErrorshow form-control"
														: "form-control"
														}`}
												/>
											</div>
											{errors && errors?.email && (
												<span className="errorMessage">
													<p>{errors && errors?.email}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="number"
													placeholder="Contact Number"
													className={`${errors && errors?.contact_no
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													maxLength={10}
													name="contact_no"
													value={state.contact_no}
													onChange={onChange}

												/>
											</div>
											{errors && errors?.contact_no && (
												<span className="errorMessage">
													<p>{errors && errors?.contact_no}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="password"
													placeholder="Password"
													className={`${errors && errors?.password
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="password"
													value={state.password}
													onChange={onChange}
												/>
											</div>
											{errors && errors?.password && (
												<span className="errorMessage">
													<p>{errors && errors?.password}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="password"
													placeholder="Confirm Password"
													className={`${errors && errors?.confirm_password
														? "SetErrorshow form-control"
														: "form-control"
														}`}
													name="confirm_password"
													value={state.confirm_password}
													onChange={onChange}
												/>
											</div>
											{errors && errors?.confirm_password && (
												<span className="errorMessage">
													<p>{errors && errors?.confirm_password}</p>
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group checkk">
												<input
													className="form-check-input"
													id="prvcy"
													type="checkbox"
													value={term}
													onChange={(e) => setTerm(!term)}
												/>
												<label htmlFor="prvcy">
													As a registered user we will email you with relevant
													information. Untick to opt out.
												</label>
											</div>
										</div>
										<div className="col-md-12 mt-2">
											<div className="form-group">
												<div className="row">
													<div
														className="col-md-6"
														style={{
															margin: "0 auto",
														}}
													>
														<button type="submit">
															{response?.isLoading ? "Loading..." : " Submit"}
														</button>
													</div>
												</div>
											</div>
										</div>

									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default Register;
