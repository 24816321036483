import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "./../../hooks/Form";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { useLoginChangePassMutation } from "../../store/services/AuthService";

const ChangePass = () => {
	let GetEmail = sessionStorage.getItem("email");
	const [errors, setErrors] = useState([]);
	const { state, onChange } = useForm({
		password: "",
		confirm_password: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { success } = useSelector((state) => state.globalReducer);
	const [userChangePasData, response] = useLoginChangePassMutation();
	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("email", GetEmail);
		FormDatas.append("password", state.password);
		FormDatas.append("confirm_password", state.confirm_password);
		userChangePasData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			sessionStorage.setItem("email", GetEmail);
			state.password = "";
			state.confirm_password = "";
			setTimeout(() => {
				navigate("/login");
			}, 1000);
		}
	}, [response.isSuccess]);
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login change-pass verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading pb-5">
									<h2>Change Password</h2>
								</div>
								<form onSubmit={onSubmit}>
									<div className="form-group">
										<input
											name="password"
											value={state?.password}
											onChange={onChange}
											className={`${
												errors && errors?.password
													? "SetErrorshow form-control"
													: "form-control"
											}`}
											type="password"
											placeholder="New Password"
										/>
									</div>
									{errors && errors?.password && (
										<span className="errorMessage">
											<p>{errors && errors?.password}</p>
										</span>
									)}
									<div className="form-group">
										<input
											name="confirm_password"
											value={state?.confirm_password}
											onChange={onChange}
											className={`${
												errors && errors?.confirm_password
													? "SetErrorshow form-control"
													: "form-control"
											}`}
											type="password"
											placeholder="Confirm Password"
										/>
									</div>
									{errors && errors?.confirm_password && (
										<span className="errorMessage">
											<p>{errors && errors?.confirm_password}</p>
										</span>
									)}
									<div className="button-group">
										<button type="submit">
											{response?.isLoading ? "Loading..." : "Confirm"}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default ChangePass;
