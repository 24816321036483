import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Assets/Css/bannerads.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	useBannerAdsSlotQuery,
	useBannersStoreMutation,
} from "../../store/services/BannerAdsService";
import Sidebar from "./Sidebar";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "react-datetime/css/react-datetime.css";
import { useForm } from "./../../hooks/Form";
import { useDispatch } from "react-redux";
import { setBanner } from "../../store/reducers/globalReducers";
import currency from "currency-formatter";
import { useSiteSettingApiQuery } from "../../store/services/AllSiteApisService";
import { ToastMessage } from "./../../utils/ShowToastMessage";

const AdvertiseYourBanner = () => {
	const [startDate, setStartDate] = useState(new Date());
	let [AddBanners, response] = useBannersStoreMutation();
	let { data, isFetching } = useSiteSettingApiQuery();
	let { data: bannerSlot } = useBannerAdsSlotQuery();
	const [banner_image, setBanner_image] = useState("");
	console.log(bannerSlot, "lll")
	const { state, onChange } = useForm({
		banner_ad_slot: "",
		start_date: "",
		end_date: "",
		description: "",
		link: "",
	});

	let Per_days_Charges = data?.response?.data[0]?.banner_charges_per_day;
	let { banner_ad_slot, start_date, end_date, description, link } = state;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const createSingleImageChange = (e) => {
		const files = Array.from(e.target.files);

		setBanner_image();

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					setBanner_image(files);
				}
			};

			reader.readAsDataURL(file);
		});
	};
	const HandeCreateBanner = (e) => {
		e.preventDefault();
		let obj = {
			banner_ad_slot,
			start_date,
			end_date,
			description,
			link,
		};
		if (
			banner_ad_slot !== "" &&
			description !== "" &&
			end_date !== "" &&
			link !== "" &&
			banner_ad_slot !== "" &&
			start_date !== ""
		) {
			dispatch(setBanner(obj));
			sessionStorage.setItem("bannerData", JSON.stringify(obj));
			navigate("/banneradpayment", { state: { image: banner_image } });
		} else {
			ToastMessage("Please Select All fields");
		}
	};
	return (
		<>
			<Header />
			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Banner Ads</h3>
								</div>
								<div className="advertbanner_sec">
									<h6>Advertise Your Banner</h6>
									<div className="perdaycrgssec">
										<span>Per Day Charges Your Brand Promotion : </span>
										<spnan>
											<b>
												{
													currency
														.format(Per_days_Charges, {
															code: "USD",
														})
														.split(".00")[0]
												}
											</b>
										</spnan>
									</div>
									<div className="row">
										<div className="col-md-12">
											<label htmlFor="exampleInputEmail1" className="form-label">
												Select Slot For Your Banner
											</label>
											<select
												className="form-select mb-3"
												aria-label="Default select example"
												name="banner_ad_slot"
												value={state?.banner_ad_slot}
												onChange={onChange}
											>
												<option disabled selected value={""}>
													Select Slot
												</option>
												{bannerSlot?.response?.data?.map((item, index) => {
													console.log(item, "ppp")
													return (
														<option key={index + 1} value={item?.id}>
															{item?.name}
														</option>
													);
												})}
											</select>
										</div>
										<div className="col-md-12 mb-3">
											<label htmlFor="exampleInputEmail1" className="form-label">
												Description
											</label>
											<input
												type="text"
												className="form-control"
												id="linkinput"
												placeholder="Description"
												value={state.description}
												name="description"
												onChange={onChange}
											/>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="exampleInputEmail1" className="form-label">
													Start Date
												</label>
												{/* <input
													type="date"
													className="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="start_date"
													value={start_date}
													onChange={onChange}
												/> */}
												<input
													type="date"
													placeholder="Start Date"
													required
													onChange={onChange}
													value={state.start_date}
													name="start_date"
													min={new Date().toISOString().split("T")[0]}
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="exampleInputEmail1" className="form-label">
													End Date
												</label>
												<input
													type="date"
													placeholder="Start Date"
													required
													onChange={onChange}
													value={state.end_date}
													name="end_date"
													min={new Date().toISOString().split("T")[0]}
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label>Upload Banner Image</label>
												<div className="fileUpload  btn--browse">
													<label htmlFor="uploadBtn" className="form-label">
														<input
															className="form-control"
															value={banner_image && banner_image[0]?.name}
															placeholder="Attach File"
														/>
														<input
															id="uploadBtn"
															type="file"
															className="form-control"
															accept="image/*"
															onChange={createSingleImageChange}
														/>

														<i className="fa fa-paperclip" aria-hidden="true"></i>
													</label>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label htmlFor="linkinput" className="form-label">
													Your Brand Link
												</label>
												<input
													type="text"
													className="form-control"
													id="linkinput"
													placeholder="Paste Here"
													value={state.link}
													name="link"
													onChange={onChange}
												/>
											</div>
										</div>

										{/* <div className="col-md-12">
											<div className="perdaycrgssecs">
												<div className="bannerdur">
													<span>Advertise Banner Duration : </span>
													<spnan>
														<b>10 Days</b>
													</spnan>
												</div>
												<div className="bannerdur">
													<span>Total Ads Price </span>
													<spnan>
														<b>$ 40.00</b>
													</spnan>
												</div>
											</div>
										</div> */}
										<div className="col-md-12">
											<div className="button-group">
												<button className="btn" onClick={HandeCreateBanner}>
													Upload New Banner
												</button>
												{/* to="/banneradpayment" */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default AdvertiseYourBanner;
