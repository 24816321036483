import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ProductsService = createApi({
	reducerPath: "ProductsService",
	tagTypes: "getPro",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			products: builder.query({
				query: (currentPageState) => {
					return {
						url: `products?page=${currentPageState ? currentPageState : null}`,
						method: "GET",
					};
				},
			}),
			productDetails: builder.query({
				query: (id) => {
					return {
						url: `products/${id}`,
						method: "GET",
					};
				},
			}),
			// for feture use of product category
			productCategories: builder.query({
				query: () => {
					return {
						url: "product_categories",
						method: "GET",
					};
				},
			}),
			fetchDataByCategory: builder.query({
				query: () => {
					return {
						url: "filters",
						method: "GET",
					};
				},
			}),
			getMyProducts: builder.query({
				query: (currentPageState) => {
					return {
						url: `user/products/my-products?page=${currentPageState ? currentPageState : null
							}`,
						method: "GET",
					};
				},
				providesTags: ["getPro"],
			}),
			getMySingleProducts: builder.query({
				query: (id) => {
					return {
						url: `user/products/my-products/${id}`,
						method: "GET",
					};
				},
				providesTags: ["getPro"],
			}),

			storeMyProducts: builder.mutation({
				query: (data) => {
					return {
						url: "user/products/store",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["getPro"],
			}),
			editMyProducts: builder.mutation({
				query: (data) => {
					return {
						url: `user/products/update/${data?.id}`,
						method: "POST",
						body: data.file,
					};
				},
				invalidatesTags: ["getPro"],
			}),
			deleteMyProducts: builder.mutation({
				query: (id) => {
					return {
						url: `user/products/delete/${id}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["getPro"],
			}),
			bookingProductApi: builder.mutation({
				query: (data) => {
					return {
						url: "user/booking/store",
						method: "POST",
						body: data,
					};
				},
			}),
			allbookingApi: builder.query({
				query: () => {
					return {
						url: "bookings",
						method: "GET",
					};
				},
			}),
			allbookingApi: builder.query({
				query: () => {
					return {
						url: "bookings",
						method: "GET",
					};
				},
			}),
			getbookingApiById: builder.query({
				query: (id) => {
					return {
						url: `bookings/${id}`,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const {
	useProductsQuery,
	useProductDetailsQuery,
	useProductCategoriesQuery,
	useFetchDataByCategoryQuery,
	useGetMyProductsQuery,
	useGetMySingleProductsQuery,
	useStoreMyProductsMutation,
	useDeleteMyProductsMutation,
	useEditMyProductsMutation,
	useBookingProductApiMutation,
	useAllbookingApiQuery,
	useGetbookingApiByIdQuery,
} = ProductsService;
export default ProductsService;
