import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { useTermsApiQuery } from "../../store/services/Pages/TermsCondition";
import Spinner from "./../../utils/Loader";

const Terms = () => {
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let { data, isFetching } = useTermsApiQuery();
	let ImageUrls = ImageUrl?.uploads;
	let Term_Api = data?.response?.data;

	return (
		<>
			{!isFetching ? (
				<>
					<Header />
					<section
						className="privacy-banner"
						style={{
							backgroundImage: `url(${ImageUrls}${Term_Api?.banner_image})`,
							backgroundRepeat: "no-repeat",
						}}
					>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="content-wrapper">
										<h2>{Term_Api?.page_title}</h2>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="privacy-content-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="content-wrapper">
										<p
											className="card-text"
											dangerouslySetInnerHTML={{
												__html: Term_Api?.content,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Terms;
