import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import Switch from "react-switch";
import { useForm } from "./../../hooks/Form";
import { useAddVehicleMutation } from "../../store/services/AdsService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	useVehicleCateQuery,
	useVehicleTypeQuery,
} from "../../store/services/VechiclesCategoryService";
import { RiCloseCircleLine } from "react-icons/ri";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { ToastMessage } from "../../utils/ShowToastMessage";
import Spinner from "../../utils/Loader";
import {
	useFeturedPaymentSubcriptionQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
import FeturedPaymentModal from "./FeturedPaymentModal";
const PostVehicleAds = () => {



	let [loader, setLoader] = useState(false);
	const [showModal, setShowModal] = useState("");
	const [setFetured, setFeturedData] = useState(null);
	let { success } = useSelector((state) => state.globalReducer);
	const { state, onChange, setState } = useForm({
		category: "",
		title: "",
		ev_type: "",
		city: "",
		statess: "",
		vin: "",
		range: "",
		location: "",
		mileage: "",
		year: "",
		make: "",
		model: "",
		trim: "",
		battery_power: "",
		exterior_color: "",
		drive_train: "",
		price: "",
		seller_snapshots: "",
		image: "",
		front_3_4_left_image: "",
		front_3_4_right_image: "",
		rear_3_4_left_image: "",
		head_on_image: "",
		rear_on_image: "",
		right_side_image: "",
		video: "",
		passenger_door_image: "",
		front_and_rear_seats_image: "",
		guages_image: "",
		detail_short_grille_emblem_image: "",
		grille_emblem_image: "",
		head_ligth_image: "",
		shifter_image: "",
		side_view_mirror_image: "",
		rear_apron_image: "",
		wheels_image: "",
		other_image: "",
	});
	let {
		category,
		title,
		ev_type,
		city,
		statess,
		vin,
		range,
		location,
		mileage,
		year,
		make,
		model,
		trim,
		battery_power,
		exterior_color,
		drive_train,
		price,
		image,
		seller_snapshots,
		front_3_4_left_image,
		front_3_4_right_image,
		rear_3_4_left_image,
		head_on_image,
		rear_on_image,
		right_side_image,
		video,
		passenger_door_image,
		front_and_rear_seats_image,
		guages_image,
		detail_short_grille_emblem_image,
		grille_emblem_image,
		head_ligth_image,
		shifter_image,
		side_view_mirror_image,
		rear_apron_image,
		wheels_image,
		other_image,
	} = state;
	const [multiVideosFiles, setMultiVideosFiles] = useState([]);
	const [uploadVideo, setUploadVideo] = useState(null);
	let [requiredImage, setRequiredImage] = useState({
		requiredImg: [],
	});
	let [AddinalImage, setAddinalImage] = useState({
		AddinalImg: [],
	});


	// console.log(uploadVideo, "police")


	const [is_featured, setIsfeatured] = useState(false);
	const [is_new, setIsnew] = useState(false);
	const [is_trending, setIstrending] = useState(false);

	const handleChangeImages = (file) => {
		setState({ ...state, image: file });
	};

	const handleChangeVideo = (file) => {
		setState({ ...state, video: file });
	};



	const handleAddinalFirst = (file) => {
		setState({ ...state, passenger_door_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 1);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 1, name: "passenger_door_image" },
			],
		});
	};
	const handleAddinalSecond = (file) => {
		setState({ ...state, front_and_rear_seats_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 2);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{
					imageUrl: file,
					id: 2,
					name: "front_and_rear_seats_image",
				},
			],
		});
	};
	const handleAddinalThird = (file) => {
		setState({ ...state, guages_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 3);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 3, name: "guages_image" },
			],
		});
	};
	const handleAddinalFourth = (file) => {
		setState({ ...state, detail_short_grille_emblem_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 4);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{
					imageUrl: file,
					id: 4,
					name: "detail_short_grille_emblem_image",
				},
			],
		});
	};
	const handleAddinalFive = (file) => {
		setState({ ...state, grille_emblem_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 5);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 5, name: "grille_emblem_image" },
			],
		});
	};
	const handleAddinalSix = (file) => {
		setState({ ...state, head_ligth_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 6);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 6, name: "head_ligth_image" },
			],
		});
	};
	const handleAddinalSeven = (file) => {
		setState({ ...state, shifter_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 7);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 7, name: "shifter_image" },
			],
		});
	};
	const handleAddinalEight = (file) => {
		setState({ ...state, side_view_mirror_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 8);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 8, name: "side_view_mirror_image" },
			],
		});
	};
	const handleAddinalNine = (file) => {
		setState({ ...state, rear_apron_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 9);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 9, name: "rear_apron_image" },
			],
		});
	};
	const handleAddinalTen = (file) => {
		setState({ ...state, wheels_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 10);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 10, name: "wheels_image" },
			],
		});
	};
	const handleAddinalEleven = (file) => {
		setState({ ...state, other_image: file });
		const filtered =
			AddinalImage && AddinalImage?.AddinalImg?.filter((clr) => clr.id !== 11);
		setAddinalImage({
			...AddinalImage,
			AddinalImg: [
				...filtered,
				{ imageUrl: file, id: 11, name: "other_image" },
			],
		});
	};

	const handleRequriedFirst = (file) => {
		setState({ ...state, front_3_4_left_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 1);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 1, name: "front_3_4_left_image" },
			],
		});
	};

	const handleRequriedSec = (file) => {
		setState({ ...state, front_3_4_right_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 2);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 2, name: "front_3_4_right_image" },
			],
		});
	};

	const handleRequriedThird = (file) => {
		setState({ ...state, rear_3_4_left_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 3);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 3, name: "rear_3_4_left_image" },
			],
		});
	};

	const handleRequriedFourth = (file) => {
		setState({ ...state, head_on_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 4);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 4, name: "head_on_image" },
			],
		});
	};

	const handleRequriedFive = (file) => {
		setState({ ...state, rear_on_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 5);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 5, name: "rear_on_image" },
			],
		});
	};

	const handleRequriedsix = (file) => {
		setState({ ...state, right_side_image: file });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 6);
		setRequiredImage({
			...requiredImage,
			requiredImg: [
				...filtered,
				{ imageUrl: file, id: 6, name: "right_side_image" },
			],
		});
	};

	const handleVideoChange = (e) => {
		setUploadVideo(e.target.files[0]);
	};


	// const handleVideoChange = (e) => {
	// 	const file = e.target.files[0];
	// 	if (e.target.files) {
	// 		const filesArray = URL.createObjectURL(e.target.files);
	// 		setMultiVideosFiles(filesArray);
	// 		Array.from(e.target.files).map(
	// 			(file) => URL.revokeObjectURL(file), // avoid memory leak
	// 		);
	// 		Array.from(e.target.files).map((file) => {
	// 			setUploadVideo((preVal) => {
	// 				return [file];
	// 			});
	// 		});
	// 	}
	// };

	// const RemoveMultiVideos = (e, item) => {
	// 	e.preventDefault();
	// 	console.log(item);
	// 	setMultiVideosFiles((preVal) => {
	// 		return [...preVal.filter((val) => val !== item)];
	// 	});
	// };

	const RemoveMultiVideos = (e) => {
		e.preventDefault();
		setUploadVideo();
	};

	// const renderVideos = (source) => {
	// 	return source.map((video) => {
	// 		return (
	// 			<li>
	// 				<video width="320" height="240" controls>
	// 					<source src={video} type="video/mp4" />
	// 				</video>
	// 				<RiCloseCircleLine
	// 					style={{ position: "absolute" }}
	// 					onClick={(e) => RemoveMultiVideos(e, video)}
	// 				/>
	// 			</li>
	// 		);
	// 	});
	// };

	const renderVideos = () => {
		return (
			<>
				{uploadVideo && (
					<li style={{ listStyle: "none", position: "relative" }}>
						<video width="320" height="240" controls>
							<source src={URL.createObjectURL(uploadVideo)} type="video/mp4" />
						</video>
						<RiCloseCircleLine
							style={{
								position: "absolute",
								cursor: "pointer",
								top: 0,
								right: 24,
							}}
							onClick={RemoveMultiVideos}
						/>
					</li>
				)}
			</>
		);
	};

	const handleChange1 = () => {
		setIsfeatured(!is_featured);
	};

	const handleChange2 = () => {
		setIsnew(!is_new);
	};
	const handleChange3 = () => {
		setIstrending(!is_trending);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [userSendData, response] = useAddVehicleMutation();
	const { data, isFetching } = useVehicleCateQuery();
	const { data: vehicleType, isFetching: load } = useVehicleTypeQuery();
	let v_Categories = data?.response?.data;
	let v_Type = vehicleType?.response?.data;
	const label = { inputProps: { "aria-label": "Switch demo" } };
	const fileTypes = ["JPG", "PNG", "GIF"];
	const videdTypes = ["JPG", "PNG", "GIF"];
	const videoTypes = ["mp4"];
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};

	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setLoader(true);
		let FormDatas = new FormData();
		FormDatas.append("category", category);
		FormDatas.append("title", title);
		FormDatas.append("ev_type", ev_type);
		FormDatas.append("city", city);
		FormDatas.append("state", statess);
		FormDatas.append("vin", vin);
		FormDatas.append("range", range);
		FormDatas.append("location", location);
		FormDatas.append("mileage", mileage);
		FormDatas.append("year", year);
		FormDatas.append("make", make);
		FormDatas.append("model", model);
		FormDatas.append("trim", trim);
		FormDatas.append("battery_power", battery_power);
		FormDatas.append("exterior_color", exterior_color);
		FormDatas.append("drive_train", drive_train);
		FormDatas.append("image", image);
		FormDatas.append("seller_snapshots", seller_snapshots);
		FormDatas.append("price", price);
		FormDatas.append("video", uploadVideo);
		FormDatas.append("is_featured", is_featured === true ? 0 : 0);
		FormDatas.append("is_new", is_new === true ? 1 : 0);
		FormDatas.append("is_trending", is_trending === true ? 1 : 0);
		requiredImage?.requiredImg?.forEach((image) => {
			FormDatas.append(`required_images[${image?.name}]`, image?.imageUrl);
		});
		AddinalImage?.AddinalImg?.forEach((image) => {
			FormDatas.append(`additional_images[${image?.name}]`, image?.imageUrl);
		});
		userSendData(FormDatas);
	};

	useEffect(() => {
		if (success !== "") {
			setLoader(false);
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);


	useEffect(() => {
		if (response.isError) {
			setLoader(false);
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					ToastMessage(response?.error?.data?.errors[key][0]);
				}
			}
		}
		if (response?.status === 401) {
			setLoader(false);
			ToastMessage("Please Login First")
		}
	}, [response?.error?.data]);


	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
			setState({});
			if (is_featured === true) {
				setShowModal("fade show d-block");
				setFeturedData(response?.data?.response?.data?.id)
			} else {
				setTimeout(() => {
					navigate("/my-add");
				}, 1000);
			}
		}
	}, [response.isSuccess]);



	// useEffect(() => {
	//     if (success !== '') {
	//         ToastMessage(success);
	//     }
	//     return () => {
	//         dispatch(clearMessage());
	//     };
	// }, [success]);

	// useEffect(() => {
	//     if (response.isError) {
	//         setErrors(response?.error?.data?.errors);
	//     }
	// }, [response?.error?.data]);

	// useEffect(() => {
	//     if (response.isSuccess) {
	//         setErrors([]);
	//         dispatch(setSuccess(response?.data?.message));
	//         state.first_name = '';
	//         state.last_name = '';
	//         state.email = '';
	//         state.contact_no = '';
	//         state.password = '';
	//         state.confirm_password = '';
	//         setTimeout(() => {
	//             navigate('/login');
	//         }, 1000);
	//     }
	// }, [response.isSuccess]);

	if (loader === true) {
		<Spinner />;
	}
	return (
		<>
			<FeturedPaymentModal showModal={showModal} setFetured={setFetured} />
			<Header />
			{/* PostVehicle starts here */}
			<section className="PostVehicle">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h4>Post Vehicle Ads</h4>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="/PostSnap">
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Location"
											className="form-control"
											name="location"
											value={location}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Mileage"
											className="form-control"
											name="mileage"
											value={mileage}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Year"
											className="form-control"
											name="year"
											value={year}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Make"
											className="form-control"
											name="make"
											value={make}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Model"
											className="form-control"
											name="model"
											value={model}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Trim"
											className="form-control"
											name="trim"
											value={trim}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Battery_power"
											className="form-control"
											name="battery_power"
											value={battery_power}
											onChange={onChange}
										/>
									</div>
									<div className="select_box11 col-lg-6">
										<select
											className="form-control first_select"
											id="cars"
											name="category"
											value={category}
											onChange={onChange}
										>
											{v_Categories?.map((item, index) => {
												return (
													<option key={index + 1} value={item?.id}>
														{item?.name}
													</option>
												);
											})}
										</select>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="title"
											className="form-control"
											name="title"
											value={title}
											onChange={onChange}
										/>
									</div>
									<div className="select_box11 col-lg-6">
										<select
											className="form-control first_select"
											id="cars"
											name="ev_type"
											value={ev_type}
											onChange={onChange}
										>
											{v_Type?.map((item, index) => {
												return (
													<option key={index + 1} value={item?.id}>
														{item?.name}
													</option>
												);
											})}
										</select>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="City"
											className="form-control"
											name="city"
											value={city}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="state"
											className="form-control"
											name="statess"
											value={statess}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="range"
											className="form-control"
											name="range"
											value={range}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="exterior_color"
											className="form-control"
											name="exterior_color"
											value={exterior_color}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="drive_train"
											className="form-control"
											name="drive_train"
											value={drive_train}
											onChange={onChange}
										/>
									</div>

									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Vin Number"
											className="form-control"
											name="vin"
											value={vin}
											onChange={onChange}
										/>
									</div>
									<div className="row">
										<div className="col-lg-4">
											<Switch
												checked={is_featured}
												name="is_featured"
												onChange={handleChange1}
											/>
											<p>Is featured</p>
										</div>

										<div className="col-lg-4">
											<Switch
												checked={is_new}
												name="is_new"
												onChange={handleChange2}
											/>
											<p>Is new</p>
										</div>
										<div className="col-lg-4">
											<Switch
												checked={is_trending}
												name="is_trending"
												onChange={handleChange3}
											/>
											<p>Is trending</p>
										</div>
										{/* <div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_featured}
															name="is_featured"
															inputProps={{ "aria-label": "controlled" }}
															onChange={handleChange1}
														/>
													}
													label="Is featured"
												/>
											</FormGroup>
										</div>
										<div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_new}
															name="is_new"
															onChange={handleChange2}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label="Is new"
												/>
											</FormGroup>
										</div>
										<div className="col-lg-4">
											<FormGroup>
												<FormControlLabel
													control={
														<Switch
															value={is_trending}
															name="is_trending"
															onChange={handleChange3}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label="Is trending"
												/>
											</FormGroup>
										</div> */}
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-12">
										<div className="form-heading-wrapper">
											<h5>
												Share your best stories and experiences with this EV:
											</h5>
										</div>
									</div>
									<div className="col-lg-12">
										<textarea
											name="seller_snapshots"
											value={seller_snapshots}
											onChange={onChange}
											className="form-control"
											id=""
											cols="30"
											rows="5"
											placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
										></textarea>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Main Image</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChangeImages}
												accept="image/*"
												name="image"
												types={videdTypes}
												label=""
											/>
											<p>
												{image ? (
													`File name: ${image?.name}`
												) : (
													<label htmlFor="">Main Image</label>
												)}
											</p>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Required 6 images:</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedFirst}
												name="front_3_4_left_image"
												types={videdTypes}
												label=""
											/>
											<p>
												{front_3_4_left_image ? (
													`File name: ${front_3_4_left_image?.name}`
												) : (
													<label htmlFor="">Font ¾ left</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedSec}
												name="front_3_4_right_image"
												types={videdTypes}
												label=""
											/>
											<p>
												{front_3_4_right_image ? (
													`File name: ${front_3_4_right_image?.name}`
												) : (
													<label htmlFor="">Font ¾ right </label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedThird}
												name="rear_3_4_left_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{rear_3_4_left_image ? (
													`File name: ${rear_3_4_left_image?.name}`
												) : (
													<label htmlFor=""> Rear ¾ left</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedFourth}
												name="head_on_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{head_on_image ? (
													`File name: ${head_on_image?.name}`
												) : (
													<label htmlFor="">Rear on</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedFive}
												name="rear_on_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{rear_on_image ? (
													`File name: ${rear_on_image?.name}`
												) : (
													<label htmlFor=""> Through passenger door</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedsix}
												name="right_side_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{right_side_image ? (
													`File name: ${right_side_image?.name}`
												) : (
													<label htmlFor="">Head on</label>
												)}
											</p>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<div className="form-heading-wrapper">
										<h5>Required Video:</h5>
									</div>

									<div className="col-lg-6 video-div">
										<div className="file-wrapper">
											<input
												type="file"
												accept="video/*"
												name="video"
												onChange={handleVideoChange}
												className="form-control file-opc"
												id="fileUpload"
											// hidden
											/>
										</div>
										{renderVideos(uploadVideo)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Additional Images</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalFirst}
												name="passenger_door_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{passenger_door_image ? (
													`File name: ${passenger_door_image?.name}`
												) : (
													<label htmlFor="">Through Passenger Door</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalEleven}
												name="other_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{other_image ? (
													`File name: ${other_image?.name}`
												) : (
													<label htmlFor="">Head On</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalSecond}
												name="front_and_rear_seats_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{front_and_rear_seats_image ? (
													`File name: ${front_and_rear_seats_image?.name}`
												) : (
													<label htmlFor="">Front And Rear Seats</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalThird}
												name="guages_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{guages_image ? (
													`File name: ${guages_image?.name}`
												) : (
													<label htmlFor=""> Gauges</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalFourth}
												name="detail_short_grille_emblem_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{detail_short_grille_emblem_image ? (
													`File name: ${detail_short_grille_emblem_image?.name}`
												) : (
													<label htmlFor="">
														Detail Shots Grille And Emblem
													</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalFive}
												name="grille_emblem_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{grille_emblem_image ? (
													`File name: ${grille_emblem_image?.name}`
												) : (
													<label htmlFor="">Grille And Emblem</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalSix}
												name="head_ligth_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{head_ligth_image ? (
													`File name: ${head_ligth_image?.name}`
												) : (
													<label htmlFor="">Headlight</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalSeven}
												name="shifter_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{shifter_image ? (
													`File name: ${shifter_image?.name}`
												) : (
													<label htmlFor="">Shifter</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalEight}
												name="side_view_mirror_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{side_view_mirror_image ? (
													`File name: ${side_view_mirror_image?.name}`
												) : (
													<label htmlFor="">Side View Mirror</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalNine}
												name="rear_apron_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{rear_apron_image ? (
													`File name: ${rear_apron_image?.name}`
												) : (
													<label htmlFor="">Rear Apron</label>
												)}
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleAddinalTen}
												name="wheels_image"
												types={fileTypes}
												label=""
											/>
											<p>
												{wheels_image ? (
													`File name: ${wheels_image?.name}`
												) : (
													<label htmlFor="">Wheels</label>
												)}
											</p>
										</div>
									</div>
								</div>
								<div className="form-group mb-0">
									{/* <div className="form-heading-wrapper">
										<h5>Sale Options</h5>
									</div> */}
									<div className="form-heading-wrapper">
										<h5>Add Price</h5>
									</div>
								</div>
								{/* <div className="form-group  d-flex">
									<div onClick={handlecheckfalse} className="form-check mr-2">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios1"
											value="option1"
										/>
										<label className="form-check-label" for="exampleRadios1">
											For Normal Sell
										</label>
									</div>
									<div onClick={handlecheck} className="form-check ml-2">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios2"
											value="option2"
										/>
										<label className="form-check-label" for="exampleRadios2">
											For Auction
										</label>
									</div>
								</div> */}
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="price"
											className="form-control"
											name="price"
											value={price}
											onChange={onChange}
										/>
									</div>
									<div className="col-lg-6">
										{radiocheck ? (
											<input
												type="text"
												className="form-control"
												placeholder="Due date for bidding"
											/>
										) : null}
									</div>
								</div>
								<div className="form-group mt-3">
									<button className="btn" onClick={onSubmit}>
										{response?.isLoading ? "Loading...." : "Submit"}
									</button>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* PostVehicle ends here */}
			<Footer />


		</>
	);
};

export default PostVehicleAds;
