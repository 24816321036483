// import React from "react";
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
// import searchBtn from "../../Assets/img/search-btn.png";
// import "../../Assets/Css/faqs.css";
// const Faqs = () => {
// 	return (
// 		<>
// 			<Header />

// 			{/* Inner Banner Sec Start Here */}
// 			<section className="bannerSec">
// 				<div className="container">
// 					<div className="content_wrapper">
// 						<h2>
// 							Learn more about
// 							<br /> allEVehicles
// 						</h2>
// 						{/* <div className="searchBox">
// 							<input
// 								className="form-control"
// 								type="text"
// 								placeholder="Search terms"
// 							/>
// 							<button className="search-icon2 btn" type="submit">
// 								<img src={searchBtn} className="img-fluid" />
// 							</button>
// 						</div> */}
// 					</div>
// 				</div>
// 			</section>
// 			{/* Inner Banner Sec Start Here */}

// 			{/* Faqs Sec Start Here */}
// 			<section className="faqs">
// 				<div className="container">
// 					<div className="accordion" id="accordionExample">
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingOne">
// 								<button
// 									className="accordion-button"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseOne"
// 									aria-expanded="true"
// 									aria-controls="collapseOne"
// 								>
// 									Where can I subscribe to your newsletter?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseOne"
// 								className="accordion-collapse collapse show"
// 								aria-labelledby="headingOne"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingTwo">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseTwo"
// 									aria-expanded="false"
// 									aria-controls="collapseTwo"
// 								>
// 									Where can in edit my address?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseTwo"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingTwo"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingThree">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseThree"
// 									aria-expanded="false"
// 									aria-controls="collapseThree"
// 								>
// 									Do you accept orders via Phone or E-mail?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseThree"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingThree"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingFour">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseFour"
// 									aria-expanded="false"
// 									aria-controls="collapseFour"
// 								>
// 									Do I need to create an account to buy Membership?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseFour"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingFour"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingFive">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseFive"
// 									aria-expanded="false"
// 									aria-controls="collapseFive"
// 								>
// 									What are your opening hours?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseFive"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingFive"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingSix">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseSix"
// 									aria-expanded="false"
// 									aria-controls="collapseSix"
// 								>
// 									Are you on Twitter, Facebook and other social media platforms?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseSix"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingSix"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingSeven">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseSeven"
// 									aria-expanded="false"
// 									aria-controls="collapseSeven"
// 								>
// 									Why do the prices sometimes change?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseSeven"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingSeven"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingEight">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseEight"
// 									aria-expanded="false"
// 									aria-controls="collapseEight"
// 								>
// 									Is there any subscription ?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseEight"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingEight"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 						<div className="accordion-item">
// 							<h2 className="accordion-header" id="headingNine">
// 								<button
// 									className="accordion-button collapsed"
// 									type="button"
// 									data-bs-toggle="collapse"
// 									data-bs-target="#collapseNine"
// 									aria-expanded="false"
// 									aria-controls="collapseNine"
// 								>
// 									How can Iogin if I forgot my password?
// 								</button>
// 							</h2>
// 							<div
// 								id="collapseNine"
// 								className="accordion-collapse collapse"
// 								aria-labelledby="headingNine"
// 								data-bs-parent="#accordionExample"
// 							>
// 								<div className="accordion-body">
// 									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
// 									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
// 									Ut enim ad
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</section>
// 			{/* Faqs Sec End Here */}

// 			<Footer />
// 		</>
// 	);
// };

// export default Faqs;

import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import searchBtn from "../../Assets/img/search-btn.png";
import "../../Assets/Css/faqs.css";
import { useFaqsQuery, useSiteSettingApiQuery, useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import Spinner from "./../../utils/Loader";
const Faqs = () => {
	let { data: siteSettingData } = useSiteSettingApiQuery();
	let FooterData = siteSettingData?.response?.data?.[0];
	let { data, isFetching } = useFaqsQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	return (
		<>
			<Header />

			{/* Inner Banner Sec Start Here */}
			<section className="bannerSec" style={{
				background: `linear-gradient(#acabab5e, #acabab5e),url(${ImageUrls}${FooterData?.faqs_image})`,
				backgroundRepeat: "no-repeat",
			}}>
				<div className="container">
					<div className="content_wrapper">
						<h2>
							Learn more about
							<br /> allEVehicles
						</h2>
						{/* <div className="searchBox">
							<input
								className="form-control"
								type="text"
								placeholder="Search terms"
							/>
							<button className="search-icon2 btn" type="submit">
								<img src={searchBtn} className="img-fluid" />
							</button>
						</div> */}
					</div>
				</div>
			</section>
			{/* Inner Banner Sec Start Here */}

			{/* Faqs Sec Start Here */}
			<section className="faqs">
				<div className="container">
					<div className="accordion" id="accordionExample">
						{!isFetching ? (
							<>
								{data?.response?.data?.map((item, index) => {
									return (
										<>
											<div className="accordion-item" key={index}>
												<h2 className="accordion-header" id={`headingOne${index}`}>
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target={`#collapseOne${index}`}
														aria-expanded="false"
														aria-controls={`collapseOne${index}`}
													>
														{item?.question}
													</button>
												</h2>
												<div
													id={`collapseOne${index}`}
													className={`${index === 0
														? "accordion-collapse collapse show"
														: "accordion-collapse collapse"
														}`}
													aria-labelledby={`headingOne${index}`}
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">{item?.answer}</div>
												</div>
											</div>
										</>
									);
								})}
							</>
						) : (
							<Spinner />
						)}
					</div>
				</div>
			</section>
			{/* Faqs Sec End Here */}

			<Footer />
		</>
	);
};

export default Faqs;
