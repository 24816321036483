import React, { useState } from "react";
import Slider from "react-slick";
import listingbanner from "../../Assets/img/listingbanner.png";
import searchIcon from "../../Assets/img/search-btn.png";
import { useAllVehiclesQuery } from "../../store/services/AllVehiclesService";
import { useForm } from "./../../hooks/Form";
import { useDispatch } from "react-redux";
import { setSearch } from "../../store/reducers/globalReducers";
import { useNavigate } from "react-router-dom";

function SampleNextArrow(props) {
	const { style, onClick } = props;
	return (
		<div
			// className='Slider_prod_custom_arrows-next'
			style={{
				...style,
				display: "block",
				background: "red",
				height: "180px",
				width: "180px",
			}}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { style, onClick } = props;
	return (
		<div
			// className="Slider_top_custom_arrows"
			style={{
				...style,
				display: "block",
				background: "green",
				height: "180px",
				width: "180px",
			}}
			onClick={onClick}
		/>
	);
}

const settings = {
	arrows: true,
	dots: true,
	infinite: true,
	fade: true,
	// speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
};
const TopSlider = ({ searchData, search, onChange }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let {
		state,
		onChange: onChangeLoad,
		setState,
	} = useForm({
		search: "",
	});
	let RedirectShop = () => {
		dispatch(setSearch(state.search));
		navigate("/listing");
	};
	return (
		<>
			<Slider>
				<div className="slider">
					<img src={listingbanner}></img>

					<div className="slider-all">
						<div className="row px-5">
							<div className="col-md-3"></div>
							{window?.location?.href.split("/")[3] === "listing" ? (
								<div className="col-md-6">
									<div className="searchBox wow animate__animated animate__bounce">
										<div className="slider-input slider-input22 ">
											<div>
												{/* <h4>2564 EVs for sale near</h4> */}
												<h4>Plug in your search!</h4>
											</div>
											<input
												type="text"
												placeholder="make, model, year…"
												name="search"
												onChange={onChange}
												value={search}
											></input>
										</div>
										<div className="search-icon" onClick={searchData}>
											{/* <i className="fa fa-search" aria-hidden="true"></i> */}
											<img src={searchIcon} alt="searchIcon" />
										</div>
									</div>
								</div>
							) : (
								<div className="col-md-6">
									<div className="searchBox wow animate__animated animate__bounce">
										<div className="slider-input slider-input22 ">
											<div>
												{/* <h4>2564 EVs for sale near</h4> */}
												<h4>Plug in your search!</h4>
											</div>
											<input
												type="text"
												placeholder="make, model, year…"
												name="search"
												onChange={onChangeLoad}
												value={state?.search}
											></input>
										</div>
										<div className="search-icon" onClick={RedirectShop}>
											{/* <i className="fa fa-search" aria-hidden="true"></i> */}
											<img src={searchIcon} alt="searchIcon" />
										</div>
									</div>
								</div>
							)}
							<div className="col-md-3"></div>
						</div>

						{/* <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div> */}
					</div>
				</div>

				{/* <div className="slider">
            <img src={banner}></img>
            <div className="slider-all">
                     
                <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div>

               </div>
            </div> */}
				{/* <div className="slider">
            <img src={banner}></img>

            <div className="slider-all">
                     
                <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div>

               </div>
            </div> */}
			</Slider>
		</>
	);
};

export default TopSlider;
