import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AuthService = createApi({
	reducerPath: "auth",
	tagTypes: "authdta",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			authLogin: builder.mutation({
				query: (loginData) => {
					return {
						url: "auth/login",
						method: "POST",
						body: loginData,
					};
				},
			}),

			// profileUpdate: builder.mutation({
			//     query: (profileData) => {
			//         return {
			//             url: 'user/account/update',
			//             method: 'POST',
			//             body: profileData,
			//         };
			//     },
			//     invalidatesTags: ['authdta'],
			// }),
			// userChangeAvatar: builder.mutation({
			//     query: (data) => {
			//         return {
			//             url: 'user/account/updateProfilePic',
			//             method: 'POST',
			//             body: data,
			//         };
			//     },
			//     invalidatesTags: ['authdta'],
			// }),
			// userChangePassword: builder.mutation({
			//     query: (data) => {
			//         return {
			//             url: 'user/account/changePassword',
			//             method: 'POST',
			//             body: data,
			//         };
			//     },
			// }),
			// getProfile: builder.query({
			//     query: () => {
			//         return {
			//             url: 'user/account',
			//             method: 'GET',
			//         };
			//     },
			//     providesTags: ['authdta'],
			// }),
			userRegister: builder.mutation({
				query: (data) => {
					return {
						url: "auth/register",
						method: "POST",
						body: data,
					};
				},
			}),
			userForgetPass: builder.mutation({
				query: (data) => {
					return {
						url: "auth/forgot-password",
						method: "POST",
						body: data,
					};
				},
			}),
			userChangePass: builder.mutation({
				query: (data) => {
					return {
						url: "auth/reset-password",
						method: "POST",
						body: data,
					};
				},
			}),
			verifyOtp: builder.mutation({
				query: (data) => {
					return {
						url: "auth/verify-otp",
						method: "POST",
						body: data,
					};
				},
			}),
			loginChangePass: builder.mutation({
				query: (data) => {
					return {
						url: "auth/reset-password",
						method: "POST",
						body: data,
					};
				},
			}),
		};
	},
});
export const {
	useAuthLoginMutation,
	useUserRegisterMutation,
	useUserForgetPassMutation,
	useUserChangePassMutation,
	useVerifyOtpMutation,
	useLoginChangePassMutation,
	// useProfileUpdateMutation,
	// useUserChangePasswordMutation,
	// useUserChangeAvatarMutation,
	// useGetProfileQuery,
} = AuthService;
export default AuthService;
