import React from 'react';
// import "../../assets/css/thankyou.css";
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import '../../Assets/Css/Thankyou.css';
import currency from 'currency-formatter';
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { emptyCart } from '../../store/reducers/cartReducers';

const Thankyou = () => {
    const location = useLocation();
    const PaymentData = location.state.Paydata;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!PaymentData) {
            navigate('/checkout');
        }
    }, [PaymentData]);
    const handleClick = () => {
        localStorage.removeItem('cart');
        dispatch(emptyCart());
        navigate('/', { replace: true });
    };

    return (
        <>
            <Header />
            <section className="thankyou">
                <div className="container">
                    <div className="happyShopping">
                        <h3 className="font-30 fw-600 pb-4">
                            Thank you for Shopping
                        </h3>

                        <div>
                            <p>Order Id: {PaymentData?.transaction_ref}</p>
                            <p>
                                Amount:{' '}
                                {
                                    currency
                                        .format(
                                            PaymentData?.order_amount,

                                            {
                                                code: 'USD',
                                            }
                                        )
                                        .split('.00')[0]
                                }{' '}
                            </p>

                            <p>Payment Method: Credit</p>
                            <p>Status: {PaymentData?.payment_status}</p>
                        </div>

                        <div className="button-group">
                            <button
                                onClick={handleClick}
                                className="btn setbtnj">
                                Continue Shopping
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Thankyou;
