import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";
import { usePostSnapApiMutation } from "../../store/services/Pages/ProfileService";
import { useForm } from "./../../hooks/Form";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setSuccess, clearMessage } from "../../store/reducers/globalReducers";
import { useNavigate } from "react-router-dom";

const PostSnap = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const fileTypes = ["JPG", "PNG", "GIF"];
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	const [errors, setErrors] = useState([]);
	const { state, onChange, setState } = useForm({
		feedback: "",
		image1: "",
		image2: "",
		image3: "",
	});

	const [checkTerms, setCheckTerms] = useState(false);
	let { success } = useSelector((state) => state.globalReducer);
	let { image1, image2, image3, feedback } = state;
	let [requiredImage, setRequiredImage] = useState({
		requiredImg: [],
	});
	const handleRequriedFirst = (file) => {
		setState({ ...state, image1: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 1);
		setRequiredImage({
			...requiredImage,
			requiredImg: [...filtered, { imageUrl: file, id: 1, name: "image1" }],
		});
	};


	const handleRequriedSec = (file) => {
		setState({ ...state, image2: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 2);
		setRequiredImage({
			...requiredImage,
			requiredImg: [...filtered, { imageUrl: file, id: 2, name: "image2" }],
		});
	};

	const handleRequriedThird = (file) => {
		setState({ ...state, image3: URL.createObjectURL(file) });
		const filtered =
			requiredImage &&
			requiredImage?.requiredImg?.filter((clr) => clr.id !== 3);
		setRequiredImage({
			...requiredImage,
			requiredImg: [...filtered, { imageUrl: file, id: 3, name: "image3" }],
		});
	};
	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};
	const [sendSnap, response] = usePostSnapApiMutation();

	const onCancel = () => {
		navigate("/my-add");
	}

	const onSubmit = (e) => {
		e.preventDefault();
		if (image1 !== "" || image2 !== "" || image3 !== "" || feedback !== "") {
			if (checkTerms) {
				const FormDatas = new FormData();
				FormDatas.append("feedback", feedback);
				requiredImage?.requiredImg?.forEach((image) => {
					FormDatas.append(`images[${image?.name}]`, image?.imageUrl);
				});
				sendSnap(FormDatas);
			} else {
				ToastMessage("Please Agree to our terms and condition");
			}
		} else {
			ToastMessage("Please fill all fields");
		}
	};

	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError) {
			ToastMessage(response?.error?.data?.message);
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			dispatch(setSuccess(response?.data?.message));
			setTimeout(() => {
				navigate("/my-add");
			}, 1000);
		}
	}, [response.isSuccess]);

	return (
		<>
			<Header />
			{/* PostVehicle starts here */}
			<section
				className="PostVehicle postSnap"
				style={{ backgroundImage: "none" }}
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<div className="content-wrapper">
								<h4>Post Snapshots</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore <br /> et dolore magna
									aliqua.
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="">
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Snapshots</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3 set_Images">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedFirst}
												name="image1"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image-1</label>
										</div>
										{
											image1 && <img src={image1} alt="Image-1" className="img-fluid" />
										}

									</div>
									<div className="col-lg-3 set_Images">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedSec}
												name="image2"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image-2</label>
										</div>
										{
											image2 && <img src={image2} alt="Image-1" className="img-fluid" />
										}
									</div>
									<div className="col-lg-3 set_Images">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleRequriedThird}
												name="image3"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Image-3</label>
										</div>
										{
											image3 && <img src={image3} alt="Image-1" className="img-fluid" />
										}
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-12">
										<div className="form-heading-wrapper">
											<h5>
												Share your best stories and experiences with this EV:
											</h5>
										</div>
									</div>
									<div className="col-lg-12">
										<textarea
											name="feedback"
											value={feedback}
											onChange={onChange}
											className="form-control"
											id=""
											cols="30"
											rows="5"
											placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
										></textarea>
									</div>
								</div>
								<div className="form-group row mt-3">
									<div className="col-lg-7">
										<h6>Charges : $20.00</h6>
										<div className="form-check">
											<input
												className="form-check-input"
												checked={checkTerms}
												onChange={(e) => {
													setCheckTerms(e.target.checked);
												}}
												type="checkbox"
												id="defaultCheck1"
											/>
											<label className="form-check-label" htmlFor="defaultCheck1">
												Agree with the privacy policy.
											</label>
										</div>
									</div>
									<div className="col-lg-2 text-right">
										<button className="btn" onClick={onCancel}>
											Cancel
										</button>
									</div>
									<div className="col-lg-3 text-right">
										<button className="btn" onClick={onSubmit}>
											{response?.isLoading ? "Loading..." : "Submit"}
										</button>
									</div>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* PostVehicle ends here */}
			<Footer />
		</>
	);
};

export default PostSnap;
