import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const TermsCondition = createApi({
	reducerPath: "TermsCondition",
	baseQuery: fetchBaseQuery({
		baseUrl:
			"https://allvehicles-backend.developer-ourbase-camp.com/api/pages/",
	}),
	endpoints: (builder) => {
		return {
			termsApi: builder.query({
				query: () => {
					return {
						url: "terms",
						method: "GET",
					};
				},
			}),
			privacyPolicyApi: builder.query({
				query: () => {
					return {
						url: "privacy-policy",
						method: "GET",
					};
				},
			}),
			copyrightPolicyApi: builder.query({
				query: () => {
					return {
						url: "copyright-policy",
						method: "GET",
					};
				},
			}),
			researchApi: builder.query({
				query: () => {
					return {
						url: "research",
						method: "GET",
					};
				},
			}),
		};
	},
});
export const {
	useTermsApiQuery,
	usePrivacyPolicyApiQuery,
	useCopyrightPolicyApiQuery,
	useResearchApiQuery,
} = TermsCondition;
export default TermsCondition;
