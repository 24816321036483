import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/privacy.css";
import { usePrivacyPolicyApiQuery } from "../../store/services/Pages/TermsCondition";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import Spinner from "../../utils/Loader";

const Privacy_policies = () => {
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let { data, isFetching } = usePrivacyPolicyApiQuery();
	let ImageUrls = ImageUrl?.uploads;
	let Privacy_Api = data?.response?.data;
	return (
		<>
			{
				!isFetching ? (
					<>
						<Header />
						<section
							className="privacy-banner"
							style={{
								backgroundImage: `url(${ImageUrls}${Privacy_Api?.banner_image})`,
								backgroundRepeat: "no-repeat",
							}}
						>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="content-wrapper">
											<h2>{Privacy_Api?.page_title}</h2>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="privacy-content-wrapper">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<p
											className="card-text"
											dangerouslySetInnerHTML={{
												__html: Privacy_Api?.content,
											}}
										></p>
									</div>
								</div>
							</div>
						</section>
						<Footer />
					</>
				) : <Spinner />
			}
		</>

	);
};

export default Privacy_policies;
