import React from "react";
import Header from "../../Components/Header";
import Icons from "../../Assets/img/Iconsearch.png";
import "../../Assets/Css/Blogs.css";
import Footer from "../../Components/Footer";
import CardImage1 from "../../Assets/img/CardImage1.png";
import CardImage2 from "../../Assets/img/CardImage2.png";
import CardImage3 from "../../Assets/img/CardImage3.png";
import Slider from "react-slick";
import searchIcon from "../../Assets/img/search-btn.png";
import { useBlogsQuery } from "../../store/services/BlogService";
import {
	useSiteSettingApiQuery,
	useSiteSettingConfigQuery,
} from "../../store/services/AllSiteApisService";
import { useNewsQuery } from "../../store/services/NewsService";
import Moment from "moment";
import Spinner from "../../utils/Loader";

function Blogs() {
	const { data: newsApi, isLoading: newsLoad } = useNewsQuery();
	let { data: BlogBg } = useSiteSettingApiQuery();
	const { data, isLoading } = useBlogsQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let BlogImage = BlogBg?.response?.data;
	let BlogsData = data?.response?.data;
	let NewsData = newsApi?.response?.data;
	let ImageUrls = ImageUrl?.uploads;
	// console.log(BlogImage, "data")
	const BlogCarousel = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
	};
	let Images = [
		CardImage1,
		CardImage2,
		CardImage3,
		CardImage1,
		CardImage2,
		CardImage3,
		CardImage1,
		CardImage2,
		CardImage3,
	];
	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					<section
						className="blog-banner blog-inner"
						style={{
							background: `linear-gradient(#acabab5e, #acabab5e),url(${ImageUrls}${BlogImage?.[0]?.blog_and_news_image})`,
							backgroundRepeat: "no-repeat",
						}}
					>
						<div className="container">
							<div className="row">
								<div className="col-sm-12">
									<div className="blog_banner_title">
										<h1>Blog And News</h1>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="pt-5 blog_sec">
						<div className="container">
							<div className="firstSection">
								<h2 className="headingH4">Our Blog</h2>
								{/* <div className="search">
							<input type="text" placeholder="Search by date" />
							<button className="searchIcon">
								<img src={searchIcon} alt="icons" />
							</button>
						</div> */}
							</div>

							<div className="row">
								<Slider {...BlogCarousel}>
									{BlogsData?.map((item, index) => {
										const formatDate = Moment(item?.updated_at).format(
											"MMM Do",
										);
										return (
											<>
												<div className="card blog_carousel" key={index}>
													<div className="img_box">
														<img
															src={`${ImageUrls}${item?.banner_image}`}
															className="card-img-top"
															alt="..."
														/>
														<div className="date">
															<p>{formatDate}</p>
														</div>
													</div>
													<div className="card-body">
														<h5 className="card-title">{item?.title}</h5>
														{/* <p className="card-text">
													Some quick example text to build on the card title and
													make up the bulk of the card's content.
												</p> */}
														<p
															className="card-text"
															dangerouslySetInnerHTML={{
																__html: item?.sub_title,
															}}
														></p>
														<a
															href={`/Blog-details/${item?.id}`}
															className="btn btn-primary"
														>
															Read More
														</a>
													</div>
												</div>
												{/* <div className="col-md-4">

										</div> */}
											</>
										);
									})}
								</Slider>
							</div>

							<div className="firstSection">
								<h2 className="headingH4">News</h2>
							</div>
							<div className="row">
								<Slider {...BlogCarousel}>
									{NewsData?.map((item, index) => {
										const formatDate = Moment(item?.updated_at).format(
											"MMM Do",
										);
										return (
											<>
												<div className="card blog_carousel" key={index}>
													<div className="img_box">
														<img
															src={`${ImageUrls}${item?.banner_image}`}
															className="card-img-top"
															alt="..."
														/>
														<div className="date">
															<p>{formatDate}</p>
														</div>
													</div>
													<div className="card-body">
														<h5 className="card-title">{item?.title}</h5>
														<p className="card-text">{item?.sub_title}</p>
														<a
															href={`/newsdetail/${item?.id}`}
															className="btn btn-primary"
														>
															Read More
														</a>
													</div>
												</div>
												{/* <div className="col-md-4">

										</div> */}
											</>
										);
									})}
								</Slider>
							</div>
						</div>
						<Footer />
					</section>
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}

export default Blogs;
