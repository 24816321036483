import React, { useState } from "react";
import "../../Assets/Css/Cart.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import currency from "currency-formatter";
import feature2 from "../../Assets/img/sapairpart2.webp";
import { useSelector, useDispatch } from "react-redux";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import {
	decQuantity,
	incQuantity,
	removeItem,
} from "../../store/reducers/cartReducers";
const Cart = () => {
	let navigate = useNavigate();
	const { cart, total } = useSelector((state) => state.cartReducers);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	const dispatch = useDispatch();
	const inc = (id) => {
		dispatch(incQuantity(id));
	};
	const dec = (id) => {
		dispatch(decQuantity(id));
	};
	const HandleClick = (id) => {
		dispatch(removeItem(id));
	};

	const handleCheckout = () => {
		navigate("/checkout");
	};

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Cart Sec */}
			<section className="cart">
				<div className="container">
					<div className="row pb-5">
						<div className="col-lg-12 heading_size">
							{cart?.length > 0 ? (
								<div className="cart-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th scope="col"></th>
													<th scope="col">Product</th>
													<th scope="col">Product Name</th>
													<th scope="col">Unit Price</th>
													<th scope="col">Quantity</th>
													<th scope="col">Sub Total</th>
												</tr>
											</thead>
											<tbody>
												{cart?.map((item, index) => {
													return (
														<>
															<tr>
																<td className="close-row" key={index}>
																	<button
																		className="removeBtn"
																		onClick={() => HandleClick(item?.id)}
																	>
																		<i className="fa fa-close"></i>
																	</button>
																</td>
																<td className="pro-img">
																	<div className="img-wrapper">
																		<img
																			className="img-fluid"
																			src={`${ImageUrls}${item?.image}`}
																			alt=""
																		/>
																	</div>
																</td>
																<td className="pro-name">{item?.title}</td>
																<td className="total-price">
																	{
																		currency
																			.format(item?.price, {
																				code: "USD",
																			})
																			.split(".00")[0]
																	}
																</td>
																<td>
																	<div className="number">
																		<button
																			className="plus quantity"
																			type="button"
																			onClick={() => inc(item?.id)}
																		>
																			+
																		</button>
																		<input type="text" value={item?.quantity} />
																		<button
																			className="minus quantity"
																			type="button"
																			onClick={() => dec(item?.id)}
																		>
																			-
																		</button>
																	</div>
																</td>

																<td className="sub-total">
																	{
																		currency
																			.format(
																				item?.price * item?.quantity,

																				{
																					code: "USD",
																				},
																			)
																			.split(".00")[0]
																	}
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							) : (
								<h5>Cart Empty</h5>
							)}
						</div>
					</div>
					{cart?.length > 0 && (
						<div className="row">
							<div className="col-lg-6"></div>
							<div className="col-lg-6">
								<div className="cart-t-tal-wrapper">
									<div className="cart-header">
										<h5 className="text-center cart-total">Cart Total</h5>
									</div>

									<div className="cart-footer-wrapper">
										<ul>
											<li>
												<span className="property">Total Amount :</span>
												<span className="value">
													{
														currency
															.format(total, {
																code: "USD",
															})
															.split(".00")[0]
													}
												</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="button-group-total d-flex flex-end pt-4 LoginCartBtn">
									<button onClick={handleCheckout} className="btn pro-shop">
										Proceed To checkout
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			{/* Cart Sec */}

			{/* Footer Sec */}
			<Footer />
			{/* Footer Sec */}
		</>
	);
};

export default Cart;
