import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Avatar, DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link } from "react-router-dom";
import { useProfileApiQuery } from "../../store/services/Pages/ProfileService";
import Spinner from "./../../utils/Loader";
import { ToastMessage } from "./../../utils/ShowToastMessage";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import { useSelector } from "react-redux";

const BasicInfo = () => {
	let { data, isLoading } = useProfileApiQuery();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	const { user } = useSelector((state) => state.AuthReducer);
	let LogingUserData = data?.response?.data;

	// const [fileupload, setFileupload] = useState(LogingUserData?.image);

	// const [uploadLoading, setUploadLoading] = useState(false);

	// const handleImageUpload = (e) => {
	// 	e.preventDefault();
	// 	if (e.target.files[0].size / 1000000 <= 5) {
	// 		const reader = new FileReader();
	// 		const file = e.target.files[0];
	// 		reader.onloadend = () => {
	// 			setNewFile(file);
	// 			setFileupload(reader.result);
	// 			let FormDatas = new FormData();
	// 			FormDatas.append("avatar", file);
	// 			Avatar(FormDatas);
	// 		};
	// 		reader.readAsDataURL(file);
	// 		setUploadLoading(true);
	// 	} else {
	// 		ToastMessage("file size is greater than 5 mb");
	// 	}
	// };

	return (
		<>
			{!isLoading ? (
				<>
					<Header />

					<section className="profile_Sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-3">
									<Sidebar />
								</div>
								<div className="col-lg-9">
									<div className="right-side">
										<div className="title">
											<h3>Profile</h3>
										</div>
										<div className="profile-detail">
											<div className="head">
												<div className="name-image">
													{LogingUserData?.image !== null ? (
														<figure>
															<img
																src={`${ImageUrl?.uploads}${LogingUserData?.image}`}
																className="img-fluid"
																alt=""
															/>
														</figure>
													) : (
														<figure>
															<img src={Avatar} className="img-fluid" />
														</figure>
													)}
													{/* <div className="change_profile f-mon-reg">
															<label for="fileUpload">
																<div className="bg-img-upload-camera"></div>
															</label>
															<input
																hidden
																id="fileUpload"
																type="file"
																accept="image/*"
																onChange={handleImageUpload}
																multiple="false"
															/>
														</div> */}
													<div className="name">
														<h4>{LogingUserData?.full_name}</h4>
														{/* <p>
															Praesent dapibus, neque id cursus faucibus, tortor
															neque egestas augue, eu vulputate magna eros eu
															erat. Aliquam erat volutpat. Nam dui mi, tincidunt
															quis, accumsan porttitor facilisis luctus metus.
														</p> */}
														<p>
															{LogingUserData?.description}
														</p>
													</div>
												</div>
												<div className="edit-btn">
													<Link to="/editprofile">Edit Profile</Link>
												</div>
											</div>
										</div>
										<div className="user-detail">
											<h4>User details</h4>
											<div className="table-responsive">
												<table className="table">
													<tr>
														<th>Name</th>
														<td className="Profile_controller">
															<input
																className="form-control"
																value={LogingUserData?.full_name}
																readonly
																type="text"
																disabled="true"
															/>
														</td>
														{/* <td>
										<Link to="#">Edit</Link>
									</td> */}
													</tr>
													<tr>
														<th>Contact Number</th>
														<td className="Profile_controller">
															<input
																className="form-control"
																value={LogingUserData?.contact_no}
																readonly
																type="tel"
																disabled="true"
															/>
														</td>
														{/* <td>
										<Link to="#">Edit</Link>
									</td> */}
													</tr>
													<tr>
														<th>Password</th>
														<td className="Profile_controller">
															<input
																className="form-control"
																value="123456789"
																readonly
																type="password"
																disabled="true"
															/>
														</td>
														<td>
															<Link to="/changepassword">Change Password</Link>
														</td>
													</tr>
													<tr>
														<th>E-mail</th>
														<td className="Profile_controller">
															<input
																className="form-control"
																value={LogingUserData?.email}
																readonly
																type="email"
																disabled="true"
															/>
														</td>
														{/* <td>
										<Link to="#">Edit</Link>
									</td> */}
													</tr>
													<tr>
														<th>Address</th>
														<td className="Profile_controller">
															<input
																className="form-control"
																value={LogingUserData?.address}
																readonly
																type="text"
																disabled="true"
															/>
														</td>
														{/* <td>
										<Link to="#">Edit</Link>
									</td> */}
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default BasicInfo;
