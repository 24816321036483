import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	cartimg,
	detail_img,
	meter_img,
	meter1,
	meter2,
	meter3,
	feature1,
	feature3,
	feature4,
	feature5,
	feature6,
	meter,
	buyer1,
	buyer2,
	buyer3,
} from "../../constant/index";
import feature2 from "../../Assets/img/sapairpart2.webp";
import Slider from "react-slick";
import "../../Assets/Css/car-detail.css";
import { useProductDetailsQuery } from "../../store/services/ProductsService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import Spinner from "./../../utils/Loader";
const Aftermarketdetail = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	let { data, isLoading } = useProductDetailsQuery(id);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	let Product_detail = data?.response?.data;
	let equiplist = [
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
	];

	// const Navigate = useNavigate();
	// const HandleClick=(id)=>{
	// 	navigate(`/buyerdetail/${id}`)
	// }
	return (
		<>
			{!isLoading ? (
				<>
					<Header />
					{/* car  detal sec starts here */}
					<section className="cardetail-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="img-and-btn-wrapper">
										<div className="slider-wrapper">
											<Slider {...settings}>
												{Product_detail?.images?.map((item, index) => {
													return (
														<>
															<div className="detail-img-wrapper" key={index}>
																<figure>
																	<img
																		src={`${ImageUrls}${item?.image_name}`}
																		className="img-fluid"
																	/>
																	<span className="no">
																		{index + 1 > 10 ? "" : 0} {index + 1}
																	</span>
																</figure>
															</div>
														</>
													);
												})}
											</Slider>
										</div>
										<div className="btn-wrapper">
											<a href="/carView">
												<img src={cartimg} />
											</a>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="detail-content-wrapper">
										<div className="top-btn-wrapper">
											<h4>
												ASKING PRICE:{" "}
												<span className="price">
													{
														currency
															.format(Product_detail?.price, {
																code: "USD",
															})
															.split(".00")[0]
													}
												</span>
											</h4>
											<button className="btn" onClick={() => navigate("/cart")}>
												Add to cart
											</button>
											{/* <button className="btn">Message Seller</button>
									<button className="btnBlack">Request CAR FAX</button>
									<button
										className="btngrey"
										data-toggle="modal"
										data-target="#battery_health"
									>
										Battery Health Report
									</button> */}
											<div
												className="modal fade"
												id="battery_health"
												tabindex="-1"
												role="dialog"
												aria-labelledby="exampleModalCenterTitle"
												aria-hidden="true"
											>
												<div
													className="modal-dialog modal-dialog-centered"
													role="document"
												>
													<div className="modal-content">
														<div className="modal-header">
															<h5
																className="modal-title"
																id="exampleModalLongTitle"
															></h5>
															<button
																type="button"
																className="close"
																data-dismiss="modal"
																aria-label="Close"
															>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<div className="text-center">
																<h3>Battery Health Report</h3>
																<h6>Battery Health 78%</h6>
															</div>
															<div className="row">
																<div className="col-md-4">&nbsp;</div>
																<div className="col-md-8"></div>
															</div>
															<div className="row">
																<div className="col-md-4 ">
																	<div>
																		<p>High Temperatures</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="50"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{ width: "50%" }}
																		></div>
																		<span>50%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Low Temperatures</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="70"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{ width: "70%" }}
																		></div>
																		<span>70%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Fast Charging</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="40"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{ width: "40%" }}
																		></div>
																		<span>40%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Slow Charging</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			aria-valuenow="90"
																			aria-valuemin="0"
																			aria-valuemax="100"
																			style={{ width: "55%" }}
																		></div>
																		<span>55%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>More highway driving</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{ width: "25%" }}
																			aria-valuenow="25"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>25%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>More city driving</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{ width: "50%" }}
																			aria-valuenow="50"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>50%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p> Charging to 100%</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{ width: "75%" }}
																			aria-valuenow="75"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>75%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">
																	<div>
																		<p>Letting the charge drop to 0%</p>
																	</div>
																</div>
																<div className="col-md-8 pl-0 align-self-center">
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{ width: "80%" }}
																			aria-valuenow="100"
																			aria-valuemin="0"
																			aria-valuemax="100"
																		></div>
																		<span>80%</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-4">&nbsp;</div>
																<div className="col-md-8"></div>
															</div>
															<div className="row">
																<div className="col-md-4 border-0">
																	<div></div>
																</div>
																<div className="col-md-8 ">
																	<div className="progress_values">
																		<p>20%</p>
																		<p>40%</p>
																		<p>60%</p>
																		<p>80%</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="stats-wrapper">
											<h5>
												39,194
												<span className="statsname">Mileage:</span>
											</h5>
											<h5>
												270 Mi
												<span className="statsname">Total Range:</span>
											</h5>
											<h5>
												5<span className="statsname">Seats:</span>
											</h5>
										</div>
										<div className="title-name-wrapper">
											<h2>MINI S E COUNTRYMAN ALL 4 CLASSIC</h2>
											<h5>
												{" "}
												<i className="fa fa-map-marker"></i> Miami, FL
											</h5>
										</div>
										<div className="highlights-wrapper">
											<h4>HIGHLIGHTS:</h4>
											<div className="highlights-list-wrapper">
												<ul>
													<li>
														{" "}
														{/* <i className="fa fa-check"></i>  */}
														<img src={meter_img} />
														Park Distance Control
													</li>
													<li>
														{" "}
														{/* <i className="fa fa-check"></i>  */}
														<img src={meter_img} />
														Comfort and Convenience Package
													</li>
													<li>
														{" "}
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i> */}
														Panoramic Sunroof
													</li>
													<li>
														{" "}
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														Back Up Camera
													</li>
													<li>
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														Park Distance Control
													</li>
													<li>
														<img src={meter_img} />
														{/* <i className="fa fa-check"></i>  */}
														AWD
													</li>
												</ul>
											</div>
										</div>
										<div className="seller-snap-wrapper">
											<div className="seller-heading-wrapper">
												<h5>Seller Snapshot :</h5>
											</div>
											<div className="seller-des-wrapper">
												<p>
													Active Driving Assistant, Advanced Real-Time Traffic
													Information, Alarm System, Apple CarPlay
													Compatibility, Brake assist, Comfort Access Keyless
													Entry, Electronic Stability Control, Exterior Parking
													Camera Rear, Four wheel independent suspension,
													harman/kardon® Logic 7® Digital Surround, MINI Driving
													Modes, MINI Yours Leather Steering Wheel, Panoramic
													Moonroof, Piano Black Interior Surface, Power
													moonroof.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* car  detal sec ends here */}
					{/* car sspecification starts here */}
					<section className="car-specification">
						<div className="container">
							<div className="row">
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>EXTERIOR:</h6>
										<ul>
											<li>
												<span className="property">Body Style</span>
												<span className="value">Hatchback</span>
											</li>
											<li>
												<span className="property">Exterior Color</span>
												<span className="value">Blue</span>
											</li>
											<li>
												<span className="property">Length</span>
												<span className="value">169.8"</span>
											</li>
											<li>
												<span className="property">Wheelbase</span>
												<span className="value">105.1"</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>INTERIOR:</h6>
										<ul>
											<li>
												<span className="property">Seating</span>
												<span className="value">5</span>
											</li>
											<li>
												<span className="property">Interior Color</span>
												<span className="value">N/A</span>
											</li>
											<li>
												<span className="property">Head Room Front</span>
												<span className="value">39.9"</span>
											</li>
											<li>
												<span className="property">Head Room Rear</span>
												<span className="value">36.3"</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="specs-wrapper">
										<h6>PERFORMANCE:</h6>
										<ul>
											<li>
												<span className="property">Total Range</span>
												<span className="value">Hatchback</span>
											</li>
											<li>
												<span className="property">Electric Range</span>
												<span className="value">Blue</span>
											</li>
											<li>
												<span className="property">EPA/Combined MPG</span>
												<span className="value">169.8"</span>
											</li>
											<li>
												<span className="property">0-60</span>
												<span className="value">105.1"</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* car sspecification ends here */}
					{/* equipments sec starts here */}
					<section className="EQUIPMENT-sec">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="equipment-wrapper">
										<div className="equipment-heading-wrapper">
											<h5>EQUIPMENT & OPTIONS:</h5>
										</div>
										<div className="equip-list-wrapper">
											<ul>
												{equiplist.map((data, index) => {
													return (
														<li key={index}>
															<img src={meter_img} /> {data.list_no}
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* equipments sec ends here */}

					<Footer />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Aftermarketdetail;
