import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AddressService = createApi({
	reducerPath: "AddressService",
	tagTypes: "getAddress",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/user/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			getAddress: builder.query({
				query: () => {
					return {
						url: "shipping_addresses",
						method: "GET",
					};
				},
				providesTags: ["getAddress"],
			}),
			addAddress: builder.mutation({
				query: (data) => {
					return {
						url: `shipping_addresses/store`,
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["getAddress"],
			}),

			deleteAddress: builder.mutation({
				query: (id) => {
					return {
						url: `shipping_addresses/delete/${id}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["getAddress"],
			}),
			editAddress: builder.mutation({
				query: (data) => {
					return {
						url: `shipping_addresses/update/${data.addressId}`,
						method: "POST",
						body: data.file,
					};
				},
				invalidatesTags: ["getAddress"],
			}),
			feturedPayement: builder.mutation({
				query: (data) => {
					return {
						url: `featured_ads/set_featured`,
						method: "POST",
						body: data,
					};
				},
			}),
		};
	},
});
export const {
	useGetAddressQuery,
	useAddAddressMutation,
	useDeleteAddressMutation,
	useEditAddressMutation,
	useFeturedPayementMutation,
} = AddressService;
export default AddressService;
