import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import ChangePass from "../Auth/ChangePass";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Verification from "../Auth/Verification";
import AllDealer from "../Dealer/AllDealer";
import DealerForm from "../Dealer/DealerForm";
import Faqs from "../Faqs/Faqs";
import Listing from "../Listing/Listing";
import About from "../About/About";
import Blog_detail from "../Blog/Blog_detail";
import DealerPage from "../Blog/DealerPage";
import Privacy_policies from "../Policies_pages/Privacy_policies";
import Terms from "../Policies_pages/Terms";
import Sell from "../Sell/Sell";
import Auction from "../Auction/Auction";
import Blogs from "../Blogs/blogs";
import ShowingList from "../ShowingList/ShowingList";
import BasicInfo from "../Profile/BasicInfo";
import Chat from "../Profile/Chat";
import MyAdd from "../Profile/MyAdd";
import Bidding from "../Profile/Bidding";
import SellerBidding from "../Profile/SellerBiding";
import Cardetailpage from "../cardetailpage/cardetailpage";
import CarPreview from "../cardetailpage/CarPreview";
import PostVehicleAds from "../PostVehicleAds/PostVehicleAds";
import PostSnap from "../PostVehicleAds/PostSnap";
import ContactUs from "../../Components/Contactus/ContactUs";
import CopyPolicy from "../Policies_pages/CopyPolicy";
import EnterEmail from "../Auth/EnterEmail";
import OurResearch from "../ourresearch/OurResearch";
import BuyersDetail from "../buyertoolsdetail/BuyersDetail";
import NewsDetail from "../Blog/NewsDetail";
import Aftermarket from "../Aftermarket/Aftermarket";
import Booking from "../Booking/Booking";
import Companies from "../Companies/Companies";
import Aftermarketdetail from "../Aftermarket/Aftermarketdetail";
import Cart from "../Cart/Cart";
import Checkout from "../Checkout/Checkout";
import Thankyou from "../Checkout/Thankyou";
import BookingDetails from "../Booking/BookingDetails";
import BannerAd from "../Profile/BannerAd";
import AdvertiseYourBanner from "../Profile/AdvertiseYourBanner";
import BannerAdPayment from "../Profile/BannerAdPayment";
import MyProducts from "../Profile/MyProducts";
import EditProfile from "../Profile/EditProfile";
import ChangePassword from "../Profile/ChangePassword";
import AddProduct from "../Profile/AddProduct";
import EditProduct from "../Profile/EditProduct";
import EditPostVehicleAds from "../PostVehicleAds/EditPostVehicleAds";
import FeturedPaymentPage from "../Profile/FeturedPaymentPage";
import MyOrders from "../Profile/MyOrders";
import Private from "./Private";
import Public from "./Public";

const AllRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				{/* <Route path="/sell" element={<Sell />} /> */}
				<Route path="/faqs" element={<Faqs />} />
				<Route path="/dealer" element={<DealerForm />} />
				<Route path="/all-dealer" element={<AllDealer />} />
				<Route path="/listing" element={<Listing />} />
				<Route path="/About" element={<About />} />
				<Route path="/privacy" element={<Privacy_policies />} />
				<Route path="/copy-policy" element={<CopyPolicy />} />
				<Route path="/Terms" element={<Terms />} />
				<Route path="/blog" element={<Blogs />} />
				{/* <Route path="/auction" element={<Auction />} /> */}
				<Route path="/Blog-details/:id" element={<Blog_detail />} />
				<Route path="/DealerPage" element={<DealerPage />} />
				<Route path="/showimg-list/:id" element={<ShowingList />} />
				<Route path="/contact" element={<ContactUs />} />
				<Route path="/Ourresearch" element={<OurResearch />} />
				<Route path="/buyerdetail/:id" element={<BuyersDetail />} />
				<Route path="/newsdetail/:id" element={<NewsDetail />} />
				<Route path="/aftermarket" element={<Aftermarket />} />
				<Route path="/aftermarketdetail/:id" element={<Aftermarketdetail />} />
				<Route path="/booking" element={<Booking />} />
				<Route path="/bookingdetails/:id" element={<BookingDetails />} />
				<Route path="/companies" element={<Companies />} />
				<Route path="*" element={<Home />} />
				<Route
					path="/cart"
					element={
						<Private>
							<Cart />
						</Private>
					}
				/>
				<Route
					path="/my-orders"
					element={
						<Private>
							<MyOrders />
						</Private>
					}
				/>
				<Route
					path="/checkout"
					element={
						<Private>
							<Checkout />
						</Private>
					}
				/>
				<Route
					path="/thankyou"
					element={
						<Private>
							<Thankyou />
						</Private>
					}
				/>
				<Route path="/cardetailpage/:id" element={<Cardetailpage />} />

				{/* Profile */}
				<Route
					path="/profile"
					element={
						<Private>
							<BasicInfo />
						</Private>
					}
				/>
				<Route
					path="/profile-message"
					element={
						<Private>
							<Chat />
						</Private>
					}
				/>
				<Route
					path="/my-add"
					element={
						<Private>
							<MyAdd />
						</Private>
					}
				/>
				{/* <Route path="/bidding" element={<Bidding />} /> */}
				{/* <Route path="/seller-bidding" element={<SellerBidding />} /> */}
				<Route
					path="/bannerads"
					element={
						<Private>
							<BannerAd />
						</Private>
					}
				/>
				<Route
					path="/advertiseyourbanner"
					element={
						<Private>
							<AdvertiseYourBanner />
						</Private>
					}
				/>
				<Route
					path="/banneradpayment"
					element={
						<Private>
							<BannerAdPayment />
						</Private>
					}
				/>
				<Route
					path="/myproducts"
					element={
						<Private>
							<MyProducts />
						</Private>
					}
				/>
				<Route
					path="/myproducts/edit/:id"
					element={
						<Private>
							<EditProduct />
						</Private>
					}
				/>
				<Route
					path="/editprofile"
					element={
						<Private>
							<EditProfile />
						</Private>
					}
				/>
				<Route
					path="/changepassword"
					element={
						<Private>
							<ChangePassword />
						</Private>
					}
				/>
				<Route
					path="/addproduct"
					element={
						<Private>
							<AddProduct />
						</Private>
					}
				/>

				{/* Auth */}
				<Route
					path="/login"
					element={
						<Public>
							<Login />
						</Public>
					}
				/>
				<Route
					path="/register"
					element={
						<Public>
							<Register />
						</Public>
					}
				/>
				<Route
					path="/enter-email"
					element={
						<Public>
							<EnterEmail />
						</Public>
					}
				/>
				<Route
					path="/verification"
					element={
						<Public>
							<Verification />
						</Public>
					}
				/>
				<Route
					path="/change-pass"
					element={
						<Public>
							<ChangePass />
						</Public>
					}
				/>
				{/* <Route path="/carView" element={<CarPreview />} /> */}
				<Route
					path="/PostVehicleAds"
					element={
						<Private>
							<PostVehicleAds />
						</Private>
					}
				/>
				<Route
					path="/PostVehicleAds/:id"
					element={
						<Private>
							<EditPostVehicleAds />
						</Private>
					}
				/>
				<Route
					path="/PostSnap"
					element={
						<Private>
							<PostSnap />
						</Private>
					}
				/>
				<Route
					path="/feturedpaymentpage"
					element={
						<Private>
							<FeturedPaymentPage />
						</Private>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default AllRoutes;
