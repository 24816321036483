import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5, tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.svg";
import meter2 from "../../Assets/img/meter2.svg";
import meter3 from "../../Assets/img/meter3.svg";
import add2 from "../../Assets/img/feature6.png";
import add3 from "../../Assets/img/feature2.png";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import {
	useDeleteMyProductsMutation,
	useGetMyProductsQuery,
} from "../../store/services/ProductsService";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import currency from "currency-formatter";
import swal from "sweetalert";
import { useState } from "react";
import Spinner from "./../../utils/Loader";

const MyProducts = () => {
	const navigate = useNavigate();
	let [sendId, response] = useDeleteMyProductsMutation();
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	const [links, setLink] = useState(null);
	const [currentPageState, setcurrentPageState] = useState(1);
	const [pageurl, setPageurl] = useState(null);
	let { data, isLoading } = useGetMyProductsQuery(pageurl);
	let myProducts = data?.response?.data;
	let paginationData = data?.response?.data?.links;
	// const handlePageClick = (data) => {
	// 	let currentPage = data.selected + 1;
	// 	setcurrentPageState(currentPage);
	// };
	const PaginationNext = (url) => {
		setPageurl(url.split("?page=")[1]);
	};
	const pages = Math.ceil(myProducts?.total / myProducts?.per_page);

	const deleteProduct = (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this product!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				swal("Poof! Your Product has been deleted!", {
					icon: "success",
				});
				sendId(id);
			} else {
				swal("Your Product is safe!");
			}
		});
	};

	useEffect(() => {
		if (data?.status) {
			setLink(paginationData);
		}
	}, [data?.status]);
	return (
		<>

			{
				!isLoading ? (
					<>
						<Header />

						<section className="profile_Sec">
							<div className="container">
								<div className="row">
									<div className="col-lg-3">
										<Sidebar />
									</div>
									<div className="col-lg-9">
										<div className="right-side">
											<div className="title">
												<h3>My Products</h3>
												{/* <div className="snap-chat">
		
		<Link to="/PostSnap">Post Snapshots</Link>
	  </div> */}
											</div>
											<div className="profile-detail"></div>
										</div>
										<div className="row">
											<div className="col-lg-4 mb-3">
												<button
													className="postAdd"
													type="button"
													onClick={() => navigate("/addproduct")}
												>
													<div className="post-add">
														<div className="inner-text">
															<h4>
																<i className="fa fa-plus" aria-hidden="true"></i> Add
																Product
															</h4>
															{/* <p>Lorem Ipsum Dolor Sit Amet</p> */}
														</div>
													</div>
												</button>
											</div>

											{myProducts?.data?.map((item, index) => {
												return (
													<>
														<div className="col-lg-4 mb-3" key={index}>
															<div className="featured-all">
																<div className="f-img">
																	<img src={`${ImageUrls}${item?.image}`}></img>
																</div>
																<div className="feature-head">
																	<h2>
																		{
																			currency
																				.format(item?.price, {
																					code: "USD",
																				})
																				.split(".00")[0]
																		}
																	</h2>
																</div>

																<div className="feature-normal-head">
																	<h3>{item?.title}</h3>
																</div>

																<div className="dropdownon">
																	<div className="dropdown">
																		<button
																			className="btn btn-secondary dropdown-toggle"
																			type="button"
																			id="dropdownMenuButton"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																		>
																			<i
																				className="fa fa-ellipsis-v"
																				aria-hidden="true"
																			></i>
																		</button>
																		<div
																			className="dropdown-menu"
																			aria-labelledby="dropdownMenuButton"
																		>
																			<button
																				className="dropdown-item"
																				onClick={() =>
																					navigate(`/myproducts/edit/${item?.id}`)
																				}
																			>
																				Edit
																			</button>
																			<button
																				className="dropdown-item"
																				onClick={() => deleteProduct(item?.id)}
																			>
																				Delete
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}

											{/* <div className="pagination">
												<div className="pageCount">
													<ReactPaginate
														breakLabel="..."
														nextLabel=">"
														previousLabel="<"
														onPageChange={handlePageClick}
														marginPagesDisplayed={0}
														pageRangeDisplayed={4}
														pageCount={pages}
													/>
												</div>
											</div> */}
											{myProducts?.length > 0 &&
												<div className="pagination">
													<div className="pageCount">
														{links?.map((item, index) => {
															if (item?.label === "&laquo; Previous") {
																return (
																	<button
																		key={index + 100}
																		onClick={() => {
																			PaginationNext(item?.url);
																		}}
																		disabled={item?.url === null ? true : false}
																		className={
																			item?.active
																				? "btn btn-light bt-pagi atcvv_btnz"
																				: "btn btn-light bt-pagi"
																		}
																	>
																		Previous
																	</button>
																);
															} else if (item?.label === "Next &raquo;") {
																return (
																	<button
																		key={index + 100}
																		onClick={() => {
																			PaginationNext(item?.url);
																		}}
																		disabled={item?.url === null ? true : false}
																		className={
																			item?.active
																				? "btn btn-light bt-pagi atcvv_btnz"
																				: "btn btn-light bt-pagi"
																		}
																	>
																		Next
																	</button>
																);
															} else {
																return (
																	<button
																		key={index + 100}
																		onClick={() => {
																			PaginationNext(item?.url);
																		}}
																		disabled={item?.url === null ? true : false}
																		className={
																			item?.active
																				? "btn btn-light bt-pagi atcvv_btnz"
																				: "btn btn-light bt-pagi"
																		}
																	>
																		{item?.label}
																	</button>
																);
															}
														})}
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</section>
						<div className="post-add-modal">
							<div
								className="modal fade"
								id="postAdd"
								tabindex="-1"
								aria-labelledby="exampleModalLabel"
								aria-hidden="true"
							>
								<div className="modal-dialog modal-dialog-centered">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="exampleModalLabel">
												<img src={tabsImg} className="img-fluid" alt="" />
												<span>Go Feature Sale Faster</span>
												<img src={tabsImg} className="img-fluid" alt="" />
											</h5>
										</div>
										<div className="modal-body">
											<ul>
												<li>
													<span>
														<label className="price_container">
															<input
																type="radio"
																hidden
																checked="checked"
																name="Addradio"
															/>
															<span className="checkmark">
																<span className="dot"></span>
																<ruby>Go Feature 5 Days</ruby>
															</span>
														</label>
													</span>
													<span className="price">$20.00</span>
												</li>
												<li>
													<span>
														<label className="price_container">
															<input type="radio" hidden name="Addradio" />
															<span className="checkmark">
																<span className="dot"></span>
																<ruby>Go Feature 10 Days</ruby>
															</span>
														</label>
													</span>
													<span className="price">$30.00</span>
												</li>
												<li>
													<span>
														<label className="price_container">
															<input type="radio" hidden name="Addradio" />
															<span className="checkmark">
																<span className="dot"></span>
																<ruby>Go Feature 15 Days</ruby>
															</span>
														</label>
													</span>
													<span className="price">$40.00</span>
												</li>
											</ul>
											<div className="button-group">
												<button className="btn">Go Featured</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Footer />
					</>
				) : <Spinner />
			}

		</>
	);
};

export default MyProducts;
