import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const AllVehiclesService = createApi({
	reducerPath: "AllVehiclesService",
	tagTypes: "vehiclesdta",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			allVehicles: builder.query({
				query: (data) => {
					return {
						url: `vehicles?ev_type=${data?.radion_type ? data?.radion_type : ""
							}&make=${data?.makes ? data?.makes : ""}&model=${data?.model ? data?.model : ""}&min_price=${data?.min_price ? data?.min_price : ""
							}&max_price=${data?.max_price ? data?.max_price : ""}&drivetrain=${data?.drive_train ? data?.drive_train : ""
							}&ev_seller=${data?.ev_seller ? data?.ev_seller : ""
							}&exterior_color=${data?.exterior_color ? data?.exterior_color : ""
							}&min_year=${data?.min_year ? data?.min_year : ""}&max_year=${data?.max_year ? data?.max_year : ""
							}&min_range=${data?.min_range ? data?.min_range : ""}&max_range=${data?.max_range ? data?.max_range : ""
							}&min_battery_power=${data?.min_battery_power ? data?.min_battery_power : ""
							}&max_battery_power=${data?.max_battery_power ? data?.max_battery_power : ""
							}&min_mileage=${data?.min_mileage ? data?.min_mileage : ""
							}&max_mileage=${data?.max_mileage ? data?.max_mileage : ""
							}&search=${data?.search ? data?.search : ""}&page=${data?.pageurl ? data?.pageurl : null
							}`,
						method: "GET",
					};
				},
				providesTags: ["vehiclesdta"],
			}),
			vehicleDetails: builder.query({
				query: (id) => {
					return {
						url: `vehicles/${id}`,
						method: "GET",
					};
				},
			}),

			makesVehicle: builder.query({
				query: () => {
					return {
						url: "vehicles/makes",
						method: "GET",
					};
				},
				providesTags: ["vehiclesdta"],
			}),
			modelsVehicle: builder.query({
				query: () => {
					return {
						url: "vehicles/models",
						method: "GET",
					};
				},
				providesTags: ["vehiclesdta"],
			}),
			exteriorVehicle: builder.query({
				query: () => {
					return {
						url: "vehicles/ext-colors",
						method: "GET",
					};
				},
				providesTags: ["vehiclesdta"],
			}),
			trendingVehicle: builder.query({
				query: () => {
					return {
						url: "vehicles/trending",
						method: "GET",
					};
				},
			}),
			featuredVehicle: builder.query({
				query: () => {
					return {
						url: "vehicles/featured",
						method: "GET",
					};
				},
			}),
		};
	},
});
export const {
	useAllVehiclesQuery,
	useVehicleDetailsQuery,
	useMakesVehicleQuery,
	useModelsVehicleQuery,
	useExteriorVehicleQuery,
	useTrendingVehicleQuery,
	useFeaturedVehicleQuery,
} = AllVehiclesService;
export default AllVehiclesService;
