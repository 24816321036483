import React, { useState, useEffect } from 'react';
import StripeForm from './StripForm';
import '../../Assets/Css/Checkout.css';
import { useNavigate } from 'react-router-dom';
import { clearAddress } from '../../store/reducers/globalReducers';
import { useForm } from './../../hooks/Form';
import CheckoutPayment from './CheckoutPayment';
import { ToastMessage } from './../../utils/ShowToastMessage';
import {
    useAddAddressMutation,
    useEditAddressMutation,
} from '../../store/services/AddressService';
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { useDispatch, useSelector } from "react-redux";


const Addetails = ({ GetMyAddress }) => {
    const [errors, setErrors] = useState([]);
    const {
        state: allState,
        onChange,
        setState,
    } = useForm({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
    });
    let {
        first_name,
        last_name,
        email,
        phone,
        address,
        city,
        country,
        state,
        zip_code,
    } = allState;
    const { addressId, addressTrue, success } = useSelector((state) => state.globalReducer);
    const navigate = useNavigate();
    let [addAddress, setAddAddress] = useState(true);
    const [saveData, response] = useAddAddressMutation();
    const [editData, responseData] = useEditAddressMutation();
    let findData = GetMyAddress?.find((item) => item?.id === addressId);
    const { cart, total } = useSelector((state) => state.cartReducers);
    const dispatch = useDispatch();
    useEffect(() => {
        if (GetMyAddress?.length > 0) {
            setAddAddress(false);
        }
    }, [GetMyAddress]);

    useEffect(() => {
        if (addressId !== '' && addAddress === false) {
            setAddAddress(false);
            setState(findData);
        } else {
            setState({});
        }
    }, [addressId, addressTrue]);

    const Addhiden = () => {
        dispatch(clearAddress());
    };

    const SaveAddress = (e) => {
        e.preventDefault();
        let FormDatas = new FormData();
        FormDatas.append('first_name', first_name);
        FormDatas.append('last_name', last_name);
        FormDatas.append('email', email);
        FormDatas.append('phone', phone);
        FormDatas.append('address', address);
        FormDatas.append('city', city);
        FormDatas.append('country', country);
        FormDatas.append('state', state);
        FormDatas.append('zip_code', zip_code);
        saveData(FormDatas);
        // setState({});
    };
    const EditAddress = (e) => {
        e.preventDefault();
        let FormDatas = new FormData();
        FormDatas.append('first_name', first_name);
        FormDatas.append('last_name', last_name);
        FormDatas.append('email', email);
        FormDatas.append('phone', phone);
        FormDatas.append('address', address);
        FormDatas.append('city', city);
        FormDatas.append('country', country);
        FormDatas.append('state', state);
        FormDatas.append('zip_code', zip_code);
        editData({ file: FormDatas, addressId });

    };

    useEffect(() => {
        if (
            responseData?.data?.message ===
            'Shipping Address updated succesfully'
        ) {
            ToastMessage(responseData?.data?.message);
            setTimeout(() => {
                dispatch(clearAddress());
            }, 3000);
        }
    }, [responseData?.data?.message]);



    useEffect(() => {
        if (success !== "") {
            ToastMessage(success);
        }
        return () => {
            dispatch(clearMessage());
        };
    }, [success]);

    useEffect(() => {
        if (response.isError) {
            for (let key in response?.error?.data?.errors) {
                if (response?.error?.data?.errors.hasOwnProperty(key)) {
                    ToastMessage(response?.error?.data?.errors[key][0]);
                }
            }
        }
    }, [response?.error?.data]);

    useEffect(() => {
        if (responseData.isError) {
            for (let key in responseData?.error?.data?.errors) {
                if (responseData?.error?.data?.errors.hasOwnProperty(key)) {
                    ToastMessage(responseData?.error?.data?.errors[key][0]);
                }
            }
        }
    }, [responseData?.error?.data]);

    useEffect(() => {
        if (response.isSuccess) {
            dispatch(setSuccess(response?.data?.message));
        }
    }, [response.isSuccess]);

    console.log(GetMyAddress?.length !== 0 && "ads", "ll")

    return (
        <>
            <div className="add-new-address">
                {
                    GetMyAddress?.length > 0 && (
                        <div className="form-check py-3">
                            <input
                                type="checkbox"
                                className={`${addAddress == true ? `form-check-input ana checkTrue` : `form-check-input ana`}`}
                                id="exampleCheckana"
                                name="addAddress"
                                value={addAddress}
                                onChange={(e) => setAddAddress(!addAddress)}
                                onClick={Addhiden}

                            />
                            <label
                                className="form-check-label label_ana"
                                htmlFor="exampleCheckana">
                                Add New Address
                            </label>
                        </div>
                    )
                }


                {addAddress !== false && (
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="fname">First Name</label>
                                    <input
                                        type="text"
                                        id="fname"
                                        maxLength={10}
                                        className="form-control"
                                        value={first_name}
                                        name="first_name"
                                        onChange={onChange}
                                        style={{
                                            backgroundImage:
                                                'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundAttachment: 'scroll',
                                            backgroundSize: '16px 18px',
                                            backgroundPosition: '98% 50%',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="lname">Last Name</label>
                                    <input
                                        type="text"
                                        id="lname"
                                        maxLength={10}
                                        value={last_name}
                                        name="last_name"
                                        onChange={onChange}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        type="number"
                                        id="phone"
                                        className="form-control"
                                        value={phone}
                                        name="phone"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        name="email"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input
                                        type="text"
                                        id="address"
                                        className="form-control"
                                        value={address}
                                        name="address"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        className="form-control"
                                        value={city}
                                        name="city"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="region">
                                        Country/Region
                                    </label>
                                    <input
                                        type="text"
                                        id="region"
                                        className="form-control"
                                        value={country}
                                        name="country"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        className="form-control"
                                        value={state}
                                        name="state"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="zip-code">Zip Code</label>
                                    <input
                                        type="text"
                                        id="zip-code"
                                        className="form-control"
                                        value={zip_code}
                                        name="zip_code"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <button
                                    type="submit"
                                    style={{
                                        marginTop: '20px',
                                        width: '100%',
                                        padding: '12px 80px',
                                        cursor: 'pointer',
                                        background: '#43f22c',
                                        color: '#fff',
                                        fontSize: '18px',
                                        textTransform: 'uppercase',
                                        fontWeight: '600',
                                        border: '0',
                                        transition: '0.6s',
                                        border: '2px solid #fff',
                                    }}
                                    className="btn"
                                    onClick={SaveAddress}>
                                    {response.isLoading ? "Loading..." : "Save Address"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {
                <div className="add-new-address">
                    {addressId !== '' && (
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fname">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            id="fname"
                                            className="form-control"
                                            value={first_name}
                                            name="first_name"
                                            onChange={onChange}
                                            maxLength={10}
                                            style={{
                                                backgroundImage:
                                                    'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundAttachment: 'scroll',
                                                backgroundSize: '16px 18px',
                                                backgroundPosition: '98% 50%',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lname">Last Name</label>
                                        <input
                                            type="text"
                                            id="lname"
                                            maxLength={10}
                                            value={last_name}
                                            name="last_name"
                                            onChange={onChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            type="number"
                                            id="phone"
                                            className="form-control"
                                            value={phone}
                                            name="phone"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control"
                                            value={email}
                                            name="email"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="address">Address</label>
                                        <input
                                            type="text"
                                            id="address"
                                            className="form-control"
                                            value={address}
                                            name="address"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            id="city"
                                            className="form-control"
                                            value={city}
                                            name="city"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="region">
                                            Country/Region
                                        </label>
                                        <input
                                            type="text"
                                            id="region"
                                            className="form-control"
                                            value={country}
                                            name="country"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            id="state"
                                            className="form-control"
                                            value={state}
                                            name="state"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="zip-code">
                                            Zip Code
                                        </label>
                                        <input
                                            type="text"
                                            id="zip-code"
                                            className="form-control"
                                            value={zip_code}
                                            name="zip_code"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheckssd"
                                        />
                                        <label
                                            className="form-check-label label_ssd"
                                            htmlFor="exampleCheckssd">
                                            Save Shipping Details
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        type="submit"
                                        style={{
                                            marginTop: '20px',
                                            width: '100%',
                                            padding: '12px 80px',
                                            cursor: 'pointer',
                                            background: '#43f22c',
                                            color: '#fff',
                                            fontSize: '18px',
                                            textTransform: 'uppercase',
                                            fontWeight: '600',
                                            border: '0',
                                            transition: '0.6s',
                                            border: '2px solid #fff',
                                        }}
                                        className="btn"
                                        onClick={EditAddress}>
                                        {responseData?.isLoading ? "Loading..." : "Save Address"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            <div className="payment-details">
                <div className="heading">
                    <h3>Payment Details</h3>
                </div>
                <div className="saved-payment-method">
                    <h5>Use Saved Payments</h5>

                    {<CheckoutPayment />}
                </div>
            </div>
        </>
    );
};

export default Addetails;
