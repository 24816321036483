import React, { useState } from "react";
import "../../Assets/Css/Companies.css";
import feature1 from "../../Assets/img/feature1.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import feature3 from "../../Assets/img/feature3.png";
import { useGetCompaniesQuery } from "../../store/services/CompaniesService";
import Spinner from "./../../utils/Loader";
import { useSiteSettingConfigQuery } from "../../store/services/AllSiteApisService";
import ReactPaginate from "react-paginate";

function Companies() {
	const [currentPageState, setcurrentPageState] = useState(1);
	let { data, isFetching } = useGetCompaniesQuery(currentPageState);
	const { data: ImageUrl, isFetching: imageLoad } = useSiteSettingConfigQuery();
	let ImageUrls = ImageUrl?.uploads;
	let CompaniesData = data?.response?.data;
	// console.log(CompaniesData, "companies")
	const handlePageClick = (data) => {
		let currentPage = data.selected + 1;
		setcurrentPageState(currentPage);
	};
	const pages = Math.ceil(CompaniesData?.total / CompaniesData?.per_page);
	return (
		<>
			{!isFetching ? (
				<section className="featured-sec">
					<Header />
					<div className="container mt-4 mb-4">
						<div className="row">
							{CompaniesData?.data?.map((item, index) => {
								return (
									<div className="col-md-3" key={index}>
										<a href={`${item?.website}`} target="_blank">
											<div className="featured-box">
												<div className="f-img">
													<img
														className="img-fluid"
														src={`${ImageUrls}${item?.image}`}
													></img>
												</div>

												<div className="all-text-things">
													<div className="featured-text">
														<h5>{item?.name}</h5>
														<p>
															{item?.description}
															<a
																href={`${item?.website}`}
																target="_blank"
																rel="noopener noreferrer"
															>
																Learn more
															</a>
														</p>
													</div>
												</div>

												<div className="inner-img firstImages-feture">
													<img
														className="img-fluid"
														src={`${ImageUrls}${item?.icon}`}
													></img>
												</div>
											</div>
										</a>
									</div>
								);
							})}
						</div>
						<div className="pagination">
							<div className="pageCount">
								<ReactPaginate
									breakLabel="..."
									nextLabel=">"
									previousLabel="<"
									onPageChange={handlePageClick}
									marginPagesDisplayed={0}
									pageRangeDisplayed={4}
									pageCount={pages}
								/>
							</div>
						</div>
					</div>
					<Footer />
				</section>
			) : (
				<Spinner />
			)}
		</>
	);
}

export default Companies;
