import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const CompaniesService = createApi({
	reducerPath: "CompaniesService",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://allvehicles-backend.developer-ourbase-camp.com/api/",
	}),
	endpoints: (builder) => {
		return {
			getCompanies: builder.query({
				query: (currentPageState) => {
					return {
						url: `companies?page=${currentPageState ? currentPageState : null
							}`,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useGetCompaniesQuery } = CompaniesService;
export default CompaniesService;
