import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BannerAdsService = createApi({
	reducerPath: "bannerAdsService",
	tagTypes: "getBranner",
	baseQuery: fetchBaseQuery({
		baseUrl: "http://allvehicles-backend.developer-ourbase-camp.com/api/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.userToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			getBanners: builder.query({
				query: () => {
					return {
						url: "user/banner_ads",
						method: "GET",
					};
				},
				providesTags: ["getBranner"],
			}),
			bannersStore: builder.mutation({
				query: (data) => {
					return {
						url: "user/banner_ads/store",
						method: "POST",
						body: data,
					};
				},
				invalidatesTags: ["getBranner"],
			}),
			bannerAdsSlot: builder.query({
				query: () => {
					return {
						url: "banner_ad_slots",
						method: "GET",
					};
				},
			}),
			bannersPaymentStore: builder.mutation({
				query: (data) => {
					return {
						url: "user/featured_ads/set_featured",
						method: "POST",
						body: data,
					};
				},
			}),
			bannersViews: builder.mutation({
				query: (data) => {
					return {
						url: `banner_ads/count-views`,
						method: "POST",
						body: data,
					};
				},
			}),
		};
	},
});
export const {
	useGetBannersQuery,
	useBannersStoreMutation,
	useBannersViewsMutation,
	useBannerAdsSlotQuery,
	useBannersPaymentStoreMutation,
} = BannerAdsService;
export default BannerAdsService;
