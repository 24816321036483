import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BlogService = createApi({
    reducerPath: 'BlogService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://allvehicles-backend.developer-ourbase-camp.com/api/',
    }),
    endpoints: (builder) => {
        return {
            blogs: builder.query({
                query: () => {
                    return {
                        url: 'blogs',
                        method: 'GET',
                    };
                },
            }),
            blogDetails: builder.query({
                query: (id) => {
                    return {
                        url: `blogs/${id}`,
                        method: 'GET',
                    };
                },
            }),
        };
    },
});
export const { useBlogsQuery, useBlogDetailsQuery } = BlogService;
export default BlogService;
