import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";
import "../../Assets/Css/bannerads.css";
import { useLocation } from "react-router-dom";
import currency from "currency-formatter";
import {
	checkdouble,
	payment1,
	payment2,
	payment3,
	payment4,
} from "../../constant";
import { Link } from "react-router-dom";
import StripeForm from "../Checkout/StripForm";
import Moment from "moment";
import { useSiteSettingApiQuery } from "../../store/services/AllSiteApisService";
import { useBannersStoreMutation } from "../../store/services/BannerAdsService";

const BannerAdPayment = (state) => {
	let { data, isFetching } = useSiteSettingApiQuery();
	let Per_days_Charges = data?.response?.data[0]?.banner_charges_per_day;
	const location = useLocation();
	const ImageFiles = location.state.image && location.state.image[0];
	const Banner_Data = JSON.parse(sessionStorage.getItem("bannerData"));
	let Start_Date = Moment(Banner_Data?.start_date).format("DD");
	let End_Date = Moment(Banner_Data?.end_date).format("DD");
	let Total_Days = End_Date - Start_Date;
	return (
		<>
			<Header />
			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Banner Ad Payment</h3>
								</div>
								<div className="banneradpayment_sec">
									<div className="row">
										<div className="col-md-12">
											<div className="adbannermain">
												<div className="adbannerdetail">
													<div className="button-group">
														<div
															className="modal fade"
															id="exampleModalToggle"
															aria-hidden="true"
															aria-labelledby="editbannerid"
															tabindex="-1"
														>
															<div className="modal-dialog modal-dialog-centered">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5
																			className="modal-title"
																			id="editbannerid"
																		></h5>
																		<button
																			type="button"
																			className="btn-close"
																			data-bs-dismiss="modal"
																			aria-label="Close"
																		></button>
																	</div>
																	<div className="modal-body">
																		<img
																			src={checkdouble}
																			className="img-fluid"
																		></img>
																		<h4>
																			your Ads Banner Payment Was Successful
																		</h4>
																		<p>
																			Your Banner Successful to Upload For This
																			Website.
																		</p>
																		<Link to="/" className="btn">
																			Go To Home Page
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="adbannerdetail">
													<span>Advertise Banner Duration : </span>
													<span>{Total_Days} Days </span>
												</div>
												<div className="adbannerdetail">
													<span>Total Ads Price</span>
													<span>
														{
															currency
																.format(Per_days_Charges * Total_Days, {
																	code: "USD",
																})
																.split(".00")[0]
														}{" "}
													</span>
												</div>
											</div>
										</div>
										<div className="col-md-12 mt-4">
											<StripeForm
												Banner_Data={Banner_Data}
												ImageFiles={ImageFiles}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default BannerAdPayment;
