import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";
import "../../Assets/Css/bannerads.css";
import { useLocation } from "react-router-dom";
import currency from "currency-formatter";
import { checkdouble } from "../../constant";
import { Link } from "react-router-dom";
import StripeFormPayment from "../Checkout/StripeFormPayment.js";
import { useFeturedPaymentSubcriptionQuery } from "../../store/services/AllSiteApisService";

const FeturedPaymentPage = () => {
	const { data } = useFeturedPaymentSubcriptionQuery();
	let feturedPayment = data?.response?.data;
	const location = useLocation();
	const vehicle_Id = location?.state?.vehicle_id;
	const banner_ad_setting_id = location?.state?.banner_ad_setting_id;
	let TotalPayments = feturedPayment
		?.map((item) => item)
		?.filter((items) => items.id === banner_ad_setting_id);

	return (
		<>
			<Header />
			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Add Fetured Payment Done</h3>
								</div>
								<div className="banneradpayment_sec">
									<div className="row">
										<div className="col-md-12">
											<div className="adbannermain">
												<div className="adbannerdetail">
													<div className="button-group">
														<div
															className="modal fade"
															id="exampleModalToggle"
															aria-hidden="true"
															aria-labelledby="editbannerid"
															tabindex="-1"
														>
															<div className="modal-dialog modal-dialog-centered">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5
																			className="modal-title"
																			id="editbannerid"
																		></h5>
																		<button
																			type="button"
																			className="btn-close"
																			data-bs-dismiss="modal"
																			aria-label="Close"
																		></button>
																	</div>
																	<div className="modal-body">
																		<img
																			src={checkdouble}
																			className="img-fluid"
																		></img>
																		<h4>your fetured Payment Was Successful</h4>
																		<p>
																			Your fetured car Successful to Upload For
																			This Website.
																		</p>
																		<Link to="/" className="btn">
																			Go To Home Page
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="adbannerdetail">
													<span>Fetured Days Duration : </span>
													<span>
														{TotalPayments && TotalPayments[0]?.days} Days{" "}
													</span>
												</div>
												<div className="adbannerdetail">
													<span>Total Fetured Price</span>
													<span>
														{
															currency
																.format(
																	TotalPayments && TotalPayments[0]?.price,
																	{
																		code: "USD",
																	},
																)
																.split(".00")[0]
														}{" "}
													</span>
												</div>
											</div>
										</div>
										<div className="col-md-12 mt-4">
											<StripeFormPayment
												vehicle_Id={vehicle_Id}
												banner_ad_setting_id={banner_ad_setting_id}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default FeturedPaymentPage;
