import React, { useEffect, useState } from "react";
import Recapture from "./Recapture";
import Icons from "../../Assets/img/arrow-down.png";
import { useContactApiMutation } from "./../../store/services/AllSiteApisService";
import { useForm } from "./../../hooks/Form";
import { clearMessage, setSuccess } from "../../store/reducers/globalReducers";
import { ToastMessage } from "../../utils/ShowToastMessage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ContactUs() {
	const [contactData, response] = useContactApiMutation();
	const [errors, setErrors] = useState([]);
	let { state, onChange } = useForm({
		query_type: "",
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		query: "",
	});
	let { first_name, last_name, email, phone, query, query_type } = state;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { success } = useSelector((state) => state.globalReducer);
	const SubmitForm = (e) => {
		e.preventDefault();
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				state.email
			)
		) {
			ToastMessage("Invalid Email");
			return;
		} else {

			let FormDatas = new FormData();
			FormDatas.append("first_name", first_name);
			FormDatas.append("last_name", last_name);
			FormDatas.append("email", email);
			FormDatas.append("phone", phone);
			FormDatas.append("query", query);
			FormDatas.append("query_type", query_type);
			contactData(FormDatas);
		}
	};
	useEffect(() => {
		if (success !== "") {
			ToastMessage(success);
		}
		return () => {
			dispatch(clearMessage());
		};
	}, [success]);

	useEffect(() => {
		if (response.isError && response?.error?.data?.errors) {
			for (let key in response?.error?.data?.errors) {
				if (response?.error?.data?.errors.hasOwnProperty(key)) {
					ToastMessage(response?.error?.data?.errors[key][0]);
				}
			}
			// setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	// useEffect(() => {
	// 	if (response.isError) {
	// 	}
	// }, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			setErrors([]);
			dispatch(setSuccess(response?.data?.message));
			state.first_name = "";
			state.last_name = "";
			state.email = "";
			state.phone = "";
			state.query = "";
			state.query_type = "";
			state.password = "";
			state.confirm_password = "";
		}
	}, [response.isSuccess]);
	return (
		<>
			<section className="contact">
				<div className="container">
					<div className="row">
						{/* <div className="col-md-1"></div> */}
						<div className="col-md-12">
							<div className="Contact_us_head">
								<h4>CONTACT US</h4>
							</div>
							{/* contact us form */}
							<form onSubmit={SubmitForm}>
								<div className="form-group">
									{/* I want to buy */}
									<div className="select_box11">
										<select
											className="form-control first_select"
											id="exampleFormControlSelect1"
											name="query_type"
											value={query_type}
											onChange={onChange}
										>
											<option value="I want To Buy" className="first_select">
												I want To Buy
											</option>
											<option value="Option 1" className="first_select">
												Option 1
											</option>
											<option value="Option 2" className="first_select">
												Option 2
											</option>
											<option value="Option 3" className="first_select">
												Option 3
											</option>
											<option value="Option 4" className="first_select">
												Option 4
											</option>
										</select>
									</div>

									<div className="first_contant_contact">
										<div className="w-50">
											<input
												type="text"
												className={`${errors && errors?.first_name
													? "SetErrorshow form-control  first_select"
													: "form-control  first_select"
													}`}
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="First Name"
												name="first_name"
												value={first_name}
												onChange={onChange}
											/>
											{errors && errors?.first_name && (
												<span className="errorMessage">
													<p>{errors && errors?.first_name}</p>
												</span>
											)}
										</div>
										<div className="w-50">
											<input
												type="text"
												className={`${errors && errors?.last_name
													? "SetErrorshow form-control  first_select"
													: "form-control  first_select"
													}`}
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Last Name"
												name="last_name"
												value={last_name}
												onChange={onChange}
											/>
											{errors && errors?.last_name && (
												<span className="errorMessage">
													<p>{errors && errors?.last_name}</p>
												</span>
											)}
										</div>
									</div>
									<div className="second_contant_contact">
										<div className="w-50">
											<input
												type="email"
												className={`${errors && errors?.email
													? "SetErrorshow form-control  first_select"
													: "form-control  first_select"
													}`}
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Email"
												name="email"
												value={email}
												onChange={onChange}
											/>
											{errors && errors?.email && (
												<span className="errorMessage">
													<p>{errors && errors?.email}</p>
												</span>
											)}
										</div>
										<div className="w-50">
											<input
												type="number"
												className={`${errors && errors?.phone
													? "SetErrorshow form-control  first_select"
													: "form-control  first_select"
													}`}
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Phone"
												name="phone"
												value={phone}
												onChange={onChange}
											/>
											{errors && errors?.phone && (
												<span className="errorMessage">
													<p>{errors && errors?.phone}</p>
												</span>
											)}
										</div>
									</div>

									{/* How We Can We Help You */}
									{/* textArea */}
									<div className="textArea_contact">
										<textarea
											className={`${errors && errors?.query
												? "SetErrorshow form-control  first_select"
												: "form-control  first_select"
												}`}
											id="exampleFormControlTextarea1"
											rows="3"
											placeholder="How We Can We Help You"
											name="query"
											value={query}
											onChange={onChange}
										></textarea>
									</div>
									{errors && errors?.query && (
										<span className="errorMessage">
											<p>{errors && errors?.query}</p>
										</span>
									)}

									{/* I'm Not A Robot */}
									<div className="not_robot_contact">
										<Recapture />
									</div>
									<button className="w-100 btn mt-3" type="submit">
										{response?.isLoading ? "Loading..." : "Submit"}
									</button>
								</div>
							</form>
						</div>
						{/* <div className="col-md-1"></div> */}
					</div>
				</div>
			</section>
		</>
	);
}

export default ContactUs;
