import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pusher from "pusher-js";


Pusher.logToConsole = false;
export const pusher = new Pusher("d2bfedbdb84a3757e7fc", {
	app_id: "1536982",
	secret: "131868e9d94ffa48ae1a",
	cluster: "ap2",
	// encrypted: true,
	forceTLS: true,
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={Store}>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="light"
			id="Toast"
		/>
		<App />
	</Provider>,
);
